<template>
  <div class="card" :class="{ mobile: $screen.xs }">
    <div v-if="$screen.xs" class="d-flex justify-content-between align-items-center px-3 pt-3 mobile-title-wrapper">
      <H1>
        {{ $tExistsOrDefault('calculatePremium.premiumOverview.titleMobile') }}
      </H1>
      <CloseButtonVue @click="$emit('onClose')" />
    </div>
    <InsuranceSummary />
    <RevisitEmailLink class="p-3" v-if="basicInsuranceSelected" :class="{ 'fixed-email-bottom': $screen.xs }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InsuranceSummary from '@/components/shared/floatingPremium/insuranceOverview/InsuranceSummary.vue';
import RevisitEmailLink from '@/components/shared/modals/revisitEmail/RevisitEmailLink.vue';
import ConfigurationHelper from '@/helpers/configuration';
import H1 from '@/components/shared/typography/H1.vue';
import CloseButtonVue from '@/components/shared/button/CloseButton.vue';

export default {
  emits: ['onClose'],
  name: 'InsuranceOverview',
  components: {
    InsuranceSummary,
    RevisitEmailLink,
    H1,
    CloseButtonVue,
  },
  computed: {
    ...mapGetters(['agreement']),
    basicInsuranceSelected() {
      return ConfigurationHelper.validateInsurance(this.agreement.insuredPersons, 'MB');
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed-email-bottom {
  position: fixed;
  width: 100%;
  bottom: 65px;
}
.card {
  width: 500px;
  box-shadow: var(--box-shadow);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  &.mobile {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
  }
}
</style>
