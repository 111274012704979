<template>
  <Button layout-type="primary" :disabled="disabled" :type="type" @click="$emit('click')">
    <slot>
      {{ $tExistsOrDefault('calculatePremium.general.next') }}
    </slot>
    <Chevron id="chevron" direction="right" :size="10" />
  </Button>
</template>
<script>
import Button from '@/components/shared/button/Button.vue';
import Chevron from '@/components/shared/icons/Chevron.vue';

export default {
  emits: ['click'],
  name: 'ButtonNext',
  components: { Button, Chevron },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: function (value) {
        return ['button', 'submit', 'reset'].includes(value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
Button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  #chevron {
    transition: all 0.2s ease-in-out;
  }
  &:hover:not([disabled]) #chevron {
    transform: translate(5px, 0) rotate(-90deg);
  }
}
</style>
