import { format, differenceInYears } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import { i18n } from '@/i18n';

export default {
  /**
   * Check if the passed date is on the first day of it's month.
   * @param {Date} date The date to check.
   * @returns {Boolean}
   */
  isFirstOfMonth(date) {
    return date.getDate() === 1;
  },

  /**
   * Check if the passed date is in January.
   * @param {Date} date The date to check.
   * @returns {Boolean}
   */
  isJanuary(date) {
    return date.getMonth() === 0;
  },

  /**
   * Format the passed date using 'dd-MM-yyyy'. Returns an empty string if the date is null or undefined.
   * @param {?Date} date The date to format.
   * @param {?String} formatType The format to format.
   * @param {?String} language The language to format.
   * @returns {String} The date formatted as 'dd-MM-yyyy'.
   */
  formatDate(date, formatType = 'dd-MM-yyyy') {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (i18n?.global?.locale === 'en') {
      return format(date, formatType, { locale: enUS });
    } else {
      return format(date, formatType, { locale: nl });
    }
  },

  isOldEnough(birthDate, referenceDate, compareAge) {
    let age = differenceInYears(referenceDate, birthDate);
    return age >= compareAge;
  },

  /**
   * @param {string} dateString
   * @return {Date|null}
   */
  dateStringToDate(dateString) {
    if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return null;
    }
    var [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day);
  },
  /**
   * @param {Date} d1
   * @param {Date} d2
   * @return {number}
   **/
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    months += 1;
    return months <= 0 ? 0 : months;
  },
};
