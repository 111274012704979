<template>
  <div>
    <H3 class="startdate-title">
      {{ $tExistsOrDefault('insured.startDate') }}
    </H3>
    <Field name="startdate" rules="required" v-slot="{ errors, field }" :model-value="selectedStartDate">
      <DropdownWithLabel
        v-bind="field"
        :model-value="selectedStartDate"
        class="startdate"
        :class="{ mobile: $screen.xs }"
        :options="options"
        @update:model-value="updateStartDate"
      />
      <span v-if="errors[0]" class="error">{{ errors[0] }}</span>
    </Field>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { addMonths, addYears, startOfMonth, startOfYear, endOfYear } from 'date-fns';
import Dates from '@/helpers/dates';
import { Field } from 'vee-validate';
import DropdownWithLabel from '@/components/shared/input/DropdownWithLabel.vue';
import BusinessRule from '@/constants/business-rule';
import H3 from '@/components/shared/typography/H3.vue';

export default {
  name: 'ContractStartDate',
  components: {
    Field,
    DropdownWithLabel,
    H3,
  },
  methods: {
    updateStartDate(value) {
      this.$store.commit(BusinessRule.Mutations.SET_START_DATE, value ?? null);
    },
  },
  computed: {
    ...mapGetters(['startDate']),
    selectedStartDate() {
      return this.startDate
        ? this.startDate
        : (this.options.find((o) => Dates.isJanuary(o.value))?.value ?? this.options[0].value);
    },
    options() {
      const { start, end } = this.availableDateRange;
      const amountMonths = Dates.monthDiff(start, end);

      return Array.from(Array(amountMonths)).map((_, i) => {
        const date = startOfMonth(addMonths(start, i));
        return {
          label: Dates.formatDate(date),
          value: date,
        };
      });
    },
    availableDateRange() {
      const lastPolicyYearAvailable = this.$store.getters.lastPolicyYearAvailable;
      const isEjw = this.$store.getters.isEjw;
      const currentDate = new Date();
      if (isEjw) {
        if (!lastPolicyYearAvailable) {
          return {
            start: addYears(startOfYear(currentDate), 1),
            end: addYears(endOfYear(currentDate), 1),
          };
        } else {
          return {
            start:
              currentDate.getMonth() === 0
                ? startOfMonth(currentDate)
                : new Date(new Date().setMonth(currentDate.getMonth() + 1)),
            end: addYears(endOfYear(currentDate), 1),
          };
        }
      }
      return {
        start: currentDate.getMonth() === 0 ? startOfMonth(currentDate) : addMonths(startOfMonth(currentDate), 1),
        end: endOfYear(currentDate),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.startdate-title {
  margin-bottom: 14px;
}
</style>
