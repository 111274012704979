<template>
  <div class="block-wrapper">
    <CardHeader class="mb-3"> {{ getTitle() }} ( {{ formatDate() }} ) </CardHeader>
    <div>
      <div class="main-form">
        <div class="row">
          <div class="form-group col-12 col-md-8">
            <label :for="`firstNameInput-person-${id}`">{{ $tExistsOrDefault('personal.form.firstname.label') }}</label>
            <FormFieldValidation
              :rules="{ required: 'personal.form.firstname.required' }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`first-name-${id}`"
              :initial-value="form.firstName"
              :reserve-validation-space="true"
            >
              <input
                :id="`firstNameInput-person-${id}`"
                type="text"
                class="form-control"
                v-focus="id === 0 && !$screen.xs"
                v-maska
                data-maska="@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
                maxlength="30"
                v-model="form.firstName"
                @blur="handleBlur"
                @change="
                  trimTrailingSpaces();
                  changeForm();
                  handleChange($event);
                "
              />
            </FormFieldValidation>
          </div>
          <div class="form-group col-12 col-md-4">
            <label :for="`initialsInput-person-${id}`">{{ $tExistsOrDefault('personal.form.initials.label') }}</label>
            <FormFieldValidation
              :rules="{ required: 'personal.form.initials.required' }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`initials-${id}`"
              :initial-value="form.initials"
              :reserve-validation-space="true"
            >
              <input
                :id="`initialsInput-person-${id}`"
                type="text"
                class="form-control text-uppercase"
                v-maska
                data-maska="@.@.@.@.@.@."
                v-model="form.initials"
                @blur="handleBlur"
                @change="
                  endWithDot();
                  changeForm();
                  handleChange($event);
                "
              />
            </FormFieldValidation>
          </div>
          <div class="form-group col-12 col-md-4">
            <label :for="`lastNamePrefixInput-person-${id}`">{{
              $tExistsOrDefault('personal.form.lastNamePrefix.label')
            }}</label>
            <FormFieldValidation
              :rules="{ namePrefix: true }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`name-prefix-${id}`"
              :initial-value="form.lastNamePrefix"
              :reserve-validation-space="true"
            >
              <input
                :id="`lastNamePrefixInput-person-${id}`"
                type="text"
                class="form-control"
                v-maska
                data-maska="@@@@@@@@@@@@@@@@"
                maxlength="15"
                v-model="form.lastNamePrefix"
                @blur="handleBlur"
                @change="
                  trimTrailingSpaces();
                  changeForm();
                  handleChange($event);
                "
              />
            </FormFieldValidation>
          </div>
          <div class="form-group col-12 col-md-8">
            <label :for="`lastNameInput-person-${id}`">{{ $tExistsOrDefault('personal.form.lastname.label') }}</label>
            <FormFieldValidation
              :rules="{ required: 'personal.form.lastname.required', name: true }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`last-name-${id}`"
              :initial-value="form.lastName"
              :reserve-validation-space="true"
            >
              <input
                :id="`lastNameInput-person-${id}`"
                type="text"
                class="form-control"
                v-maska
                data-maska="@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
                maxlength="60"
                v-model="form.lastName"
                @blur="handleBlur"
                @change="
                  trimTrailingSpaces();
                  changeForm();
                  handleChange($event);
                "
              />
            </FormFieldValidation>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label>{{ $tExistsOrDefault('personal.form.gender.label') }}</label>
            <FormFieldValidation
              :rules="{ required: 'personal.form.gender.required' }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`gender-${id}`"
              :initial-value="form.gender"
              :reserve-validation-space="true"
            >
              <div class="radio-button-group">
                <RadioButton
                  v-for="language in [
                    { label: 'male', value: 'M' },
                    { label: 'female', value: 'V' },
                  ]"
                  :name="`gender-person-${id}`"
                  v-model="form.gender"
                  @update:model-value="
                    (x) => {
                      setForm(x);
                      handleChange(x);
                    }
                  "
                  @blur="handleBlur"
                  :radio-value="language.value"
                  :key="language.value"
                >
                  {{ $tExistsOrDefault(`personal.form.gender.${language.label}`) }}
                </RadioButton>
              </div>
            </FormFieldValidation>
          </div>
          <div v-if="oldEnough(16)" class="form-group col-12">
            <label :for="`emailInput-person-${id}`">{{ $tExistsOrDefault('personal.form.email.label') }}</label>
            <FormFieldValidation
              :rules="{ required: 'personal.form.email.required', email: true }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`email-${id}`"
              :initial-value="form.email"
              :reserve-validation-space="true"
            >
              <input
                :id="`emailInput-person-${id}`"
                type="email"
                class="form-control"
                v-model="form.email"
                @change="
                  {
                    changeForm();
                    handleChange($event);
                  }
                "
                @blur="handleBlur"
              />
            </FormFieldValidation>
          </div>
          <div class="form-group col-12">
            <label :for="`socialSecurityNumberInput-person-${id}`">
              {{ $tExistsOrDefault('personal.form.socialSecurityNumber.label') }}
            </label>
            <InformationIcon class="ml-2 info-icon" @click="clickInfoButton" />
            <FormFieldValidation
              :rules="{ required: 'personal.form.socialSecurityNumber.required', validBSN: allBsnsToShow }"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`bsn-${id}`"
              :initial-value="form.socialSecurityNumber"
              :reserve-validation-space="true"
            >
              <input
                type="text"
                class="form-control"
                v-maska
                data-maska="#########"
                v-model="form.socialSecurityNumber"
                @blur="handleBlur"
                @change="
                  addLeadingZeroWhenNeeded();
                  changeForm();
                  handleChange($event);
                "
                :id="`socialSecurityNumberInput-person-${id}`"
              />
            </FormFieldValidation>
          </div>
          <div class="form-group col-12">
            <label>{{ $tExistsOrDefault('personal.form.nationality.label') }}</label>
            <FormFieldValidation
              rules="required"
              v-slot="{ handleBlur, handleChange }"
              :field-name="`nationality-${id}`"
              :initial-value="form.nationality"
              :reserve-validation-space="true"
            >
              <DropdownWithLabel
                v-model="form.nationality"
                :options="nationalities.map((n) => ({ label: n.value, value: n.key }))"
                :use-translation-keys="false"
                @blur="handleBlur"
                @change="
                  {
                    changeForm();
                    handleChange($event);
                  }
                "
              />
            </FormFieldValidation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { differenceInYears } from 'date-fns';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';
import ModalConst from '@/constants/modal';

import Dates from '@/helpers/dates';
import NationalityService from '@/helpers/nationality-service';
import RadioButton from '@/components/shared/input/RadioButton.vue';
import DropdownWithLabel from '@/components/shared/input/DropdownWithLabel.vue';
import CardHeader from '@/components/shared/typography/CardHeader.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';
import InformationIcon from '@/components/shared/icons/InformationIcon.vue';

export default {
  name: 'PersonalDataInsured',
  //// TODO: is this emit necessary?
  emits: ['formChange'],
  components: {
    RadioButton,
    DropdownWithLabel,
    CardHeader,
    FormFieldValidation,
    InformationIcon,
  },
  props: {
    id: Number,
    initialForm: Object,
    allBsns: Array,
  },
  data() {
    return {
      form: { ...this.initialForm },
    };
  },
  computed: {
    ...mapGetters(['startDate', 'getDateOfBirth']),
    insured() {
      return this.$store.getters.agreement.insuredPersons.find((i) => i.id === this.id);
    },
    allBsnsToShow: {
      get() {
        return this.allBsns;
      },
    },
    nationalities() {
      return NationalityService.getNationalities();
    },
  },
  methods: {
    clickInfoButton() {
      this.$store.dispatch(ModalConst.Actions.OPEN, {
        modalData: ModalDataFactory.createBSNInfo(),
      });
    },
    formatDate() {
      return Dates.formatDate(this.getDateOfBirth(this.insured.id), 'dd MMMM yyyy');
    },
    oldEnough(age) {
      return differenceInYears(this.startDate, this.getDateOfBirth(this.insured.id)) >= age;
    },
    getTitle() {
      if (this.insured != null) {
        switch (this.insured.typeOfInsured) {
          case 'H':
            return this.$tExistsOrDefault('personal.yourselfTitle');
          case 'P':
            return this.$tExistsOrDefault('personal.partnerTitle');
          case 'M':
            return this.$tExistsOrDefault('personal.childTitle');
        }
      }
    },
    endWithDot() {
      if (this.form.initials.slice(-1) !== '.' && this.form.initials.length > 0) {
        this.form.initials = this.form.initials + '.';
      }
    },
    //// TODO: delete or keep? Several times used in this component, emit to parent component on the form
    changeForm() {
      this.$emit('formChange', this.form);
    },
    setForm(value) {
      this.form.gender = value;
      this.$emit('formChange', this.form);
    },
    addLeadingZeroWhenNeeded() {
      if (this.form.socialSecurityNumber.length === 8) {
        this.form.socialSecurityNumber = '0' + this.form.socialSecurityNumber;
      }
    },
    trimTrailingSpaces() {
      if (this.form.firstName) {
        this.form.firstName = this.form.firstName.replace(/[^-,A-Z,a-z\u00C0-\u024F']/g, ' ');
        this.form.firstName = this.form.firstName.replace(/^./, this.form.firstName[0].toUpperCase()).trim();
      }

      this.form.lastNamePrefix = this.form.lastNamePrefix.replace(/[^A-Z,a-z ]/g, ' ').trim();

      if (this.form.lastName) {
        this.form.lastName = this.form.lastName.replace(/[^-,A-Z,a-z\u00C0-\u024F']/g, ' ');
        this.form.lastName = this.form.lastName.replace(/^./, this.form.lastName[0]?.toUpperCase()).trim();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
select.form-control {
  font-size: 16px;
}

.info-icon {
  cursor: pointer;
}
.form-group {
  margin-bottom: 0.5em;
}
</style>
