<template>
  <p>
    <slot />
  </p>
</template>
<script>
export default {
  name: 'CardHeader',
};
</script>
<style lang="scss" scoped>
p {
  font-size: 20px;
  font-family: var(--text-font-semibold);
  color: var(--color-grey-900);
  margin-bottom: 0;
}
</style>
