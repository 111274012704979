import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      siteimproveInjected: false,
    };
  },
  mounted() {
    if (!this.siteimproveInjected && this.labelSettings.label !== 'aev') {
      const script = document.createElement('script');
      script.src = '/js/siteimproveScript.js';
      script.type = 'text/plain';
      script.setAttribute('data-usercentrics', 'Siteimprove');

      document.head.appendChild(script);

      this.siteimproveInjected = true;
    }
  },
  computed: {
    ...mapGetters(['labelSettings']),
  },
};
