<template>
  <ul
    id="search-list"
    v-if="showDropdown"
    @mouseover="isMouseOnDropdownList = true"
    @mouseleave="isMouseOnDropdownList = false"
  >
    <li
      v-for="(option, index) in optionsList"
      :class="{ 'selected-value': index === currentSelectedIndex }"
      @mouseover="currentSelectedIndex = index"
      :value="option.value"
      :key="option.value"
      @click="setValue(option)"
    >
      <PlainText :type="currentSelectedIndex === index ? 'white' : 'primary'" class="font-bold" v-if="option.category">
        {{ option.category }}
      </PlainText>
      <PlainText :type="currentSelectedIndex === index ? 'white' : 'primary'">
        {{ useTranslationKeys ? $tExistsOrDefault(option.text) : option.text }}
      </PlainText>
    </li>
  </ul>
</template>

<script>
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'DropdownList',
  emits: ['update:modelValue'],
  components: {
    PlainText,
  },
  props: {
    useTranslationKeys: {
      type: Boolean,
      default: true,
    },
    modelValue: null,
    options: {
      type: Array,
      default: () => [],
    },
    minimumLengthShowDropdown: {
      type: Number,
      default: 0,
    },
    openDropdown: {
      type: Boolean,
      required: true,
    },
    shouldFilterByValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOnDropdownList: false,
      currentSelectedIndex: -1,
    };
  },
  computed: {
    optionsList() {
      let result = this.options;

      if (this.shouldFilterByValue) {
        result = this.options.filter((x) => x.value.toLowerCase().startsWith(this.modelValue?.toLowerCase()));
      }

      return result;
    },
    showDropdown() {
      const result =
        (this.openDropdown || this.isMouseOnDropdownList) &&
        this.optionsList.length > 0 &&
        (!(typeof this.modelValue === 'string') || this.minimumLengthShowDropdown <= this.modelValue.length);
      return result;
    },
  },
  methods: {
    setValue(selectedOption) {
      this.$emit('update:modelValue', selectedOption.value);
      this.isMouseOnDropdownList = false;
    },
  },
  watch: {
    openDropdown(isOpen) {
      if (isOpen) {
        this.currentSelectedIndex = this.optionsList.indexOf(this.optionsList.find((x) => x.value === this.modelValue));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#search-list {
  position: absolute;
  max-height: 175px;
  overflow-y: scroll;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: solid 1px var(--color-grey-400);
  border-radius: 4px;
  background-color: var(--color-white);
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#search-list::-webkit-scrollbar {
  display: none;
}

#search-list li {
  padding: 5px 0px 5px 15px;
  display: flex;
  gap: 10px;

  > * {
    flex: 1;
  }
}

.font-bold {
  font-weight: bold;
}

#search-list li.selected-value {
  background-color: var(--color-primary-800);
}
</style>
