import { createStore } from 'vuex';
import Modal from '@/stores/modal';
import Contracts from '@/stores/contracts';
import Logo from '@/stores/logo';
import Search from '@/stores/search';
import BusinessRule from '@/stores/business-rule';
import Validation from '@/stores/validation';
import CollectivityLabel from '@/stores/collectivityLabel';
import Revisit from '@/stores/revisit';
import Settings from '@/stores/settings';
import TooltipDisabledPackages from '@/stores/tooltipDisabledPackages';
import Address from '@/stores/address';
import Zorgzoeker from '@/stores/zorgzoeker';

const store = createStore({
  modules: {
    Contracts,
    Logo,
    BusinessRule,
    Modal,
    Search,
    Validation,
    CollectivityLabel,
    Revisit,
    Settings,
    TooltipDisabledPackages,
    Address,
    Zorgzoeker,
  },
  mutations: {},
  plugins: [],
  strict: process.env.NODE_ENV !== 'production',
});

export { store };
