<template>
  <FullscreenOverlay
    :model-value="modelValue"
    @update:model-value="onClose"
    sized-window
    sized-window-flex-position="end"
    style="flex-wrap: wrap"
    :class="{ overlay: $screen.gt.xs }"
  >
    <div class="overlay-wrapper">
      <CloseButtonStickyHeaderSlot @close="onClose">
        <slot />
        <div v-if="$screen.xs" class="mobile-space" />
      </CloseButtonStickyHeaderSlot>
    </div>
  </FullscreenOverlay>
</template>

<script>
import FullscreenOverlay from '@/components/shared/overlay/FullscreenOverlay.vue';
import CloseButtonStickyHeaderSlot from '@/components/shared/button/CloseButtonStickyHeaderSlot.vue';

export default {
  name: 'PartialOverlayWithCloseButton',
  emits: ['update:modelValue'],
  components: {
    FullscreenOverlay,
    CloseButtonStickyHeaderSlot,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    isMobile() {
      return this.$screen.xs;
    },
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.overlay-wrapper {
  height: 100vh;
  user-select: none;
}
.mobile-space {
  height: 7dvh;
}
</style>
