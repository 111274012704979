import Validation from '@/constants/validation';

const state = {
  pageValidationState: {
    insured: false,
    configuration: false,
    personal_data: false,
    overview: false,
  },
  pagePosted: {
    configuration: false,
  },
};

const getters = {
  pageValidationState: (state) => state.pageValidationState,
  pagePosted: (state) => state.pagePosted,
};

const actions = {
  [Validation.Actions.SET_INSURED]({ commit }, validationState) {
    commit(Validation.Mutations.SET_INSURED, validationState);
  },
  [Validation.Actions.SET_CONFIGURATION]({ commit }, validationState) {
    commit(Validation.Mutations.SET_CONFIGURATION, validationState);
  },
  [Validation.Actions.SET_PERSONAL_DATA]({ commit }, validationState) {
    commit(Validation.Mutations.SET_PERSONAL_DATA, validationState);
  },
  [Validation.Actions.SET_PERSONAL_EXTRA_DATA]({ commit }, validationState) {
    commit(Validation.Mutations.SET_PERSONAL_EXTRA_DATA, validationState);
  },
  [Validation.Actions.SET_OVERVIEW]({ commit }, validationState) {
    commit(Validation.Mutations.SET_OVERVIEW, validationState);
  },
  [Validation.Actions.SET_ALL]({ commit }, validationState) {
    commit(Validation.Mutations.SET_ALL, validationState);
  },
  [Validation.Actions.POSTED_CONFIGURATION]({ commit }, validationState) {
    commit(Validation.Mutations.POSTED_CONFIGURATION, validationState);
  },
};

const mutations = {
  [Validation.Mutations.SET_INSURED](state, validationState) {
    state.pageValidationState.insured = validationState;
  },
  [Validation.Mutations.SET_CONFIGURATION](state, validationState) {
    state.pageValidationState.configuration = validationState;
  },
  [Validation.Mutations.SET_PERSONAL_DATA](state, validationState) {
    state.pageValidationState.personal_data = validationState;
  },
  [Validation.Mutations.SET_PERSONAL_EXTRA_DATA](state, validationState) {
    state.pageValidationState.personal_extra_data = validationState;
  },
  [Validation.Mutations.SET_OVERVIEW](state, validationState) {
    state.pageValidationState.overview = validationState;
  },
  [Validation.Mutations.SET_ALL](state, validationState) {
    state.pageValidationState.insured = validationState;
    state.pageValidationState.configuration = validationState;
    state.pageValidationState.personal_data = validationState;
    state.pageValidationState.overview = validationState;
  },
  [Validation.Mutations.POSTED_CONFIGURATION](state, postState) {
    state.pagePosted.configuration = postState;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
