<template>
  <div class="row mb-3">
    <div class="col text-right">
      <PlainText type="secondary" class="d-inline mr-4">
        {{ headerTitle }}
      </PlainText>
      <PlainText class="d-inline text">
        {{ formatPrice }}
      </PlainText>
    </div>
  </div>
</template>

<script>
import PlainText from '@/components/shared/typography/PlainText.vue';
import formatCurrencyHelper from '@/helpers/formatCurrency';

export default {
  name: 'PersonPremiumCardTotalRow',
  components: {
    PlainText,
  },
  props: {
    headerTitle: String,
    price: Number,
  },
  computed: {
    formatPrice() {
      return this.price === 0 ? this.$tExistsOrDefault('price.free') : this.formatCurrency(this.price);
    },
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 20px;
  font-family: var(--text-font-semibold);
}
</style>
