export default {
  install: (app, options) => {
    app.config.globalProperties.$tExistsOrDefault = (path, parameters) => {
      if (path === null || path === undefined) return null;

      const label = options.store.getters.labelSettings.label;
      const contractCode = options.store.getters.selectedContractCode;

      // Get the messages for the current locale
      const messagesForLocale = options.i18n.global.messages[options.i18n.global.locale];

      // Get the value of the path (. is the separator for nested objects in the path)
      const messagesForLocaleOnPath = path.split('.').reduce((p, c) => p?.[c], messagesForLocale);
      let result = getValueFori18nItem(messagesForLocaleOnPath, label, contractCode);

      if (parameters) {
        if (Array.isArray(result)) {
          let result = result.map((x) => replaceParametersInString(x, parameters));
        } else {
          result = replaceParametersInString(result, parameters);
        }
      }

      // If the value is not found, log a warning
      if (result === undefined || result === null) {
        // console.warn(`vuex-i18n :: Key '${path}' not found for locale '${options.i18n.global.locale}'`);
      }
      return result;
    };
  },
};

function getValueFori18nItem(item, label, contractCode) {
  if (typeof item === 'undefined' || item === null) return null;
  if (typeof item === 'string') return item;
  if (Array.isArray(item)) return getValueFromi18nArray(item, label, contractCode);
  if (typeof item === 'object') return getValueFromi18nObject(item, label, contractCode);
}

function getValueFromi18nArray(i18nArray, label, contractCode) {
  return i18nArray.map((x) => getValueFori18nItem(x, label, contractCode)).filter((x) => x !== null);
}

function getValueFromi18nObject(i18nObject, label, contractCode) {
  return i18nObject[contractCode] || i18nObject[label] || i18nObject.default;
}

/**
 *
 * @param {string} str
 * @param {[]} parameters
 * @returns
 */
function replaceParametersInString(str, parameters) {
  if (parameters) {
    for (const [key, value] of Object.entries(parameters)) {
      str = str.replace(`{${key}}`, value);
    }
  }
  return str;
}
