<template>
  <div>
    <section class="space-between-persons" v-for="insuredPerson in insuredPersons" :key="insuredPerson.id">
      <OverviewBlockHeader
        :title="getFullNameAndBirthDate(insuredPerson)"
        :insured-person="insuredPerson"
        @btn-click="gotoPersonalData"
      />
      <PersonPremiumCard :insured-person="insuredPerson" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OverviewBlockHeader from '@/components/viewelements/overview/OverviewBlockHeader.vue';
import PersonPremiumCard from '@/components/viewelements/configuration/previewOverview/PersonPremiumCard.vue';

import Dates from '@/helpers/dates.js';

export default {
  name: 'OverviewHealthInsurance',
  props: { insuredPersons: Array },
  components: {
    OverviewBlockHeader,
    PersonPremiumCard,
  },
  computed: {
    ...mapGetters(['getDateOfBirth']),
  },
  methods: {
    gotoPersonalData(event) {
      if (event) event.preventDefault();

      this.$router.push({
        name: 'personal_data',
        params: {
          lang: this.$i18n.locale,
          contractCode: this.$store.getters.selectedContractCode,
        },
      });
    },
    getFullNameAndBirthDate(insuredPerson) {
      return `${this.getFullName(insuredPerson)} ( ${this.getBirthDate(insuredPerson)} )`;
    },
    getBirthDate(insuredPerson) {
      return Dates.formatDate(this.getDateOfBirth(insuredPerson.id), 'dd MMMM yyyy');
    },
    getFullName(insuredPerson) {
      let result = '';
      if (insuredPerson.firstName) result += `${insuredPerson.firstName} `;
      if (insuredPerson.initials) result += `(${insuredPerson.initials})`;
      if (insuredPerson.lastNamePrefix) result += ` ${insuredPerson.lastNamePrefix}`;
      if (insuredPerson.lastName) result += ` ${insuredPerson.lastName}`;
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.space-between-persons:not(:last-child) {
  margin-bottom: 30px;
}
</style>
