<template>
  <div class="form-content">
    <div>
      <Form tag="form" @submit="sendMail">
        <div class="form-group row">
          <label class="col-12 col-md-4 col-form-label">{{ $tExistsOrDefault('personal.form.email.label') }}</label>
          <div class="col-12 col-md-8">
            <FormFieldValidation
              :rules="{ required: 'personal.form.email.required', email: true }"
              v-slot="{ handleBlur, handleChange }"
              field-name="email"
              :initial-value="form.emailaddress"
            >
              <input
                type="email"
                class="form-control mb-2"
                v-model="form.emailaddress"
                @change="handleChange"
                @blur="handleBlur"
              />
            </FormFieldValidation>
          </div>
        </div>
        <div :class="{ 'center-button': $screen.xs }">
          <Button type="submit" :class="{ 'float-right': !$screen.xs }">
            {{ $t('overview.revisit-email-popup.send-mail') }}
          </Button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { Form } from 'vee-validate';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';

import Revisit from '@/constants/revisit';
import Modal from '@/constants/modal';
import Button from '@/components/shared/button/Button.vue';

export default {
  components: {
    Form,
    FormFieldValidation,
    Button,
  },
  data() {
    return {
      isValid: false,
      form: {
        emailaddress: '',
      },
    };
  },
  computed: {
    ...mapGetters(['agreement']),
  },
  methods: {
    async sendMail() {
      this.isValid = true;
      this.$store.dispatch(Revisit.Actions.SAVE, {
        agreementModel: this.agreement,
        type: 'email',
        emailDetails: this.form,
        step: Revisit.RevisitStepEnum.unknown /* step is not important for email sending. */,
      });

      this.$store.dispatch(Modal.Actions.CLOSE);
      this.$store.dispatch(Modal.Actions.OPEN, {
        modalData: ModalDataFactory.createEmailSuccessfullySend(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  padding-top: 20px;
}

.center-button {
  text-align: center;
}
</style>
@/components/shared/modals/ModalDataFactory
