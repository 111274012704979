<template>
  <div>
    <div class="d-flex justify-content-center text-center">
      <LinkText
        :class="{
          invisible: !(conditionsDocument || coverDocument || product.insuranceCardLink),
        }"
        @click="showOverlay = true"
      >
        {{ $tExistsOrDefault('calculatePremium.downloads.showMore') }}
        <Chevron id="chevron" direction="right" :size="10" />
      </LinkText>
    </div>
    <IFramesOverlay v-model="showOverlay" :product="product" />
  </div>
</template>

<script>
import Chevron from '@/components/shared/icons/Chevron.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import DocumentUtils from '@/utils/document';
import IFramesOverlay from '@/components/viewelements/configuration/insuranceCard/documentDownloads/IFramesOverlay.vue';

export default {
  name: 'DocumentDownloads',
  props: {
    product: Object,
  },
  components: {
    Chevron,
    IFramesOverlay,
    LinkText,
  },
  data: function () {
    return {
      showOverlay: false,
    };
  },
  computed: {
    coverDocument() {
      if (!this.product.documentInsurancePolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(this.product.documentInsurancePolicy);
    },
    productComponentCode() {
      return this.product.productComponentCode.toString();
    },
    conditionsDocument() {
      if (!this.product.documentConditionsPolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(this.product.documentConditionsPolicy);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#chevron {
  margin-left: 5px;
}
</style>
