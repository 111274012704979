<template>
  <div class="block-wrapper">
    <div>
      <H2>{{ $tExistsOrDefault('personal.employer') }}</H2>
      <div class="main-form mt-3">
        <div class="form-group row mx-0">
          <label for="subcontractSelectionInput">{{ $tExistsOrDefault('personal.form.subcontract.label') }}</label>
          <div class="col-12 pl-0" v-if="subcontracts.length > 1">
            <FormFieldValidation
              rules="required:personal.form.subcontract.required"
              v-slot="{ handleBlur, handleChange }"
              field-name="targetGroupCode"
              :initial-value="form.targetGroupCode"
              :reserve-validation-space="true"
            >
              <select
                v-model="form.targetGroupCode"
                class="form-control active"
                name="industry"
                @change="
                  {
                    changeForm();
                    handleChange($event);
                  }
                "
                @blur="handleBlur"
                id="subcontractSelectionInput"
              >
                <option v-for="option in subcontracts" :key="`subcontract-${option.key}`" :value="option.key">
                  {{ option.value }}
                </option>
              </select>
            </FormFieldValidation>
            <div class="select-arrow">
              <Chevron :size="20" :color="'var(--color-grey-900)'" />
            </div>
          </div>
          <span v-else class="col-12 pl-0">{{ form.targetGroupName }}</span>
        </div>
        <div class="form-group" v-if="selectedContract.employeeNumberRequired">
          <label for="employeeNumberInput">{{ $tExistsOrDefault('personal.form.employeeNumber.label') }}</label>
          <div class="col-12 pl-0">
            <FormFieldValidation
              rules="required:personal.form.employeeNumber.required|employeeNumber"
              v-slot="{ handleBlur, handleChange }"
              field-name="employeeNumber"
              :initial-value="form.employeeNumber"
              :reserve-validation-space="true"
            >
              <input
                id="employeeNumberInput"
                v-model="form.employeeNumber"
                type="text"
                class="form-control"
                maxlength="25"
                @change="
                  {
                    changeForm();
                    handleChange($event);
                  }
                "
                @blur="handleBlur"
              />
            </FormFieldValidation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Contracts from '@/constants/contracts';
import Chevron from '@/components/shared/icons/Chevron.vue';
import H2 from '@/components/shared/typography/H2.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

export default {
  emits: ['formChange'],
  name: 'PersonalDataEmployer',
  components: {
    Chevron,
    H2,
    FormFieldValidation,
  },
  props: {
    initialForm: Object,
  },
  data() {
    return {
      form: { ...this.initialForm },
    };
  },
  computed: {
    ...mapGetters(['selectedContract', 'subcontracts']),
  },
  mounted() {
    this.$store.dispatch(Contracts.Actions.LOAD_SUBCONTRACTS_BY_CONTRACTCODE).then(() => {
      if (this.subcontracts.length === 1) {
        this.form.targetGroupCode = this.subcontracts[0].key;
        this.form.targetGroupName = this.subcontracts[0].value;
        this.changeForm();
      }
    });
  },
  methods: {
    changeForm() {
      this.$emit('formChange', this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-arrow {
  padding: 10px 25px;
}

select.form-control {
  font-size: 16px;
}
</style>
