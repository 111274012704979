<template>
  <Container>
    <Card>
      <H1>
        {{ $tExistsOrDefault('complete.title', { query: labelSettings.label }) }}
      </H1>
      <p
        v-if="isSubmittedSuccessfully"
        v-html="$tExistsOrDefault(`complete.requestSuccessfully.${labelSettings.label}`)"
      />
      <p v-else v-html="$tExistsOrDefault(`complete.requestFailed.${labelSettings.label}`)" />
    </Card>
  </Container>
</template>

<script>
import Validation from '@/constants/validation';
import { mapGetters } from 'vuex';
import Container from '@/components/shared/Container.vue';
import Card from '@/components/shared/card/Card.vue';
import H1 from '@/components/shared/typography/H1.vue';
export default {
  name: 'Complete',
  props: {
    isSubmittedSuccessfully: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Container,
    Card,
    H1,
  },
  computed: {
    ...mapGetters(['labelSettings']),
  },
  mounted() {
    this.$store.dispatch(Validation.Actions.SET_ALL, false);
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 24px;
}
</style>

<style lang="scss">
p {
  margin-bottom: 0;
}
</style>
