<template>
  <h2>
    <slot />
  </h2>
</template>
<script>
export default {
  name: 'H2',
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 22px;
  font-family: var(--text-font);
  color: var(--color-grey-900);
  margin-bottom: 0;
  line-height: 25px;
}
</style>
