import { reactive, getCurrentInstance, onUnmounted } from 'vue';

export default {
  install: (app) => {
    const screenSize = reactive({
      xxs: window.innerWidth <= 399,
      xs: window.innerWidth <= 767,
      sm: window.innerWidth > 767 && window.innerWidth <= 991,
      md: window.innerWidth > 991 && window.innerWidth <= 1199,
      lg: window.innerWidth > 1199 && window.innerWidth <= 1920,
      lt: {
        sm: window.innerWidth <= 767,
        md: window.innerWidth <= 991,
        lg: window.innerWidth <= 1199,
      },
      gt: {
        xs: window.innerWidth > 767,
        sm: window.innerWidth > 991,
        md: window.innerWidth > 1199,
        lg: window.innerWidth > 1920,
      },
    });

    const updateScreenProperties = () => {
      screenSize.xxs = window.innerWidth <= 399;
      screenSize.xs = window.innerWidth <= 767;
      screenSize.sm = window.innerWidth > 767 && window.innerWidth <= 991;
      screenSize.md = window.innerWidth > 991 && window.innerWidth <= 1199;
      screenSize.lg = window.innerWidth > 1199 && window.innerWidth <= 1920;
      screenSize.lt.sm = window.innerWidth <= 767;
      screenSize.lt.md = window.innerWidth <= 991;
      screenSize.lt.lg = window.innerWidth <= 1199;
      screenSize.gt.xs = window.innerWidth > 767;
      screenSize.gt.sm = window.innerWidth > 991;
      screenSize.gt.md = window.innerWidth > 1199;
      screenSize.gt.lg = window.innerWidth > 1920;
    };
    window.addEventListener('resize', updateScreenProperties);

    if (getCurrentInstance()) {
      onUnmounted(() => {
        window.removeEventListener('resize', updateScreenProperties);
      });
    }

    app.config.globalProperties.$screen = screenSize;
  },
};
