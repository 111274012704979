import { store } from '@/store';
import { app } from '@/main.js';
import Revisit from '@/constants/revisit';
import Modal from '@/constants/modal';
import BusinessRule from '@/constants/business-rule';
import Cookies from 'js-cookie';

import RevisitEmailFormModal from '@/components/shared/modals/revisitEmail/RevisitEmailFormModal.vue';
import RevisitEmailSuccesModal from '@/components/shared/modals/revisitEmail/RevisitEmailSuccesModal.vue';

const router = {
  push: (value) => app.config.globalProperties.$router.push(value),
  replace: (value) => app.config.globalProperties.$router.replace(value),
};
const $i18n = () => app.config.globalProperties.$i18n;

export default {
  create(title, text, text2, showClose = true, buttons = [], closeAction = null, formComponent = null) {
    return {
      title: title,
      text: text,
      text2: text2 ? text2 : null,
      showClose: showClose,
      buttons: buttons,
      closeAction: closeAction ? closeAction : null,
      formComponent: formComponent,
    };
  },
  createRevisit() {
    return this.create('modal.revisit.title', 'modal.revisit.paragraph', null, false, [
      {
        isPrimary: false,
        action: () => {
          store
            .dispatch(Revisit.Actions.PATCH, {
              id: Cookies.get(store.getters.revisitCookieName),
              state: Revisit.RevisitStateEnum.declined,
            })
            .then(() => {
              Cookies.remove(store.getters.revisitCookieName);
              store.dispatch(BusinessRule.Actions.RESET_VISITED_PACKAGE_TYPES);
              store.dispatch(Modal.Actions.CLOSE);
            });
        },
        text: 'modal.revisit.start-again',
      },
      {
        isPrimary: true,
        action: () => {
          store.dispatch(Modal.Actions.CLOSE);
          router.push({
            name: 'RevisitLoader',
            params: {
              lang: $i18n.locale,
              id: Cookies.get(store.getters.revisitCookieName),
              type: 'cookie',
            },
          });
        },
        text: 'modal.revisit.continue-configuration',
      },
    ]);
  },
  createInvalidRevisit() {
    return this.create('modal.revisit-invalid.title', 'modal.revisit-invalid.paragraph', null, true, null, () => {
      Cookies.remove(store.getters.revisitCookieName);
      router.replace({ name: 'home' });
    });
  },
  createConfigurationWarning() {
    return this.create(
      'calculatePremium.configurationWarning.title',
      'calculatePremium.configurationWarning.text',
      null,
      false,
      [
        {
          isPrimary: false,
          text: 'calculatePremium.configurationWarning.buttons.cancel',
          action: () => {
            store.dispatch(BusinessRule.Actions.REVERT_TO_PREVIOUS_SELECTION_STATE);
            store.dispatch(Modal.Actions.CLOSE);
          },
        },
        {
          isPrimary: true,
          text: 'calculatePremium.configurationWarning.buttons.edit',
          action: () => {
            store.dispatch(BusinessRule.Actions.SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE);
            store.dispatch(Modal.Actions.CLOSE);
          },
        },
      ],
    );
  },
  createSendEmail() {
    return this.create(
      'overview.revisit-email-popup.title',
      'overview.revisit-email-popup.paragraph',
      'overview.revisit-email-popup.permission-premium-calculation-email',
      true,
      null,
      null,
      RevisitEmailFormModal,
    );
  },
  createEmailSuccessfullySend() {
    return {
      component: RevisitEmailSuccesModal,
    };
  },
  createRemoveAllPackagesWhenDisabledBaiscInsuranceSelected(product, person) {
    return this.create(
      'calculatePremium.basicInsurance.resetSelectionModal.popUpTitle',
      'calculatePremium.basicInsurance.resetSelectionModal.popUpText',
      null,
      false,
      [
        {
          isPrimary: false,
          text: 'calculatePremium.basicInsurance.resetSelectionModal.popUpCancel',
          action: () => {
            store.dispatch(Modal.Actions.CLOSE);
          },
        },
        {
          isPrimary: true,
          text: 'calculatePremium.basicInsurance.resetSelectionModal.popUpSubmit',
          action: () => {
            store
              .dispatch(BusinessRule.Actions.SELECT_PRODUCT_FOR_PERSON, {
                product,
                person,
              })
              .then(() => {
                store.dispatch(BusinessRule.Actions.POST);
              });
            store.dispatch(Modal.Actions.CLOSE);
          },
        },
      ],
    );
  },
  createBSNInfo() {
    return this.create('personal.form.socialSecurityNumber.label', 'personal.form.socialSecurityNumber.tooltip', null);
  },
};
