import Config from '@/constants/config';

const Actions = {
  SET_DISABLED_ADDRESS: 'a.address.set_disabled_address',
};

const Mutations = {
  SET_DISABLED_ADDRESS: 'm.address.set_disabled_address',
};

const Endpoints = {
  Load: () => `${Config.API.BASE_URL}/api/Address`,
};

export default {
  Actions,
  Mutations,
  Endpoints,
};
