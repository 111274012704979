<template>
  <div>
    <section class="header-section">
      <div class="header-title">
        <Magnifier id="magnifier-icon" />
        <H1>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.title') }}
        </H1>
      </div>
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.paragraph') }}
      </PlainText>
    </section>

    <Form ref="healthcareProvidersOverlayForm">
      <div class="flex-item" :class="{ mobile: $screen.xs }">
        <div class="year-container">
          <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.year') }}</label>
          <Dropdown v-model="form.year" :options="yearOptions" :use-translation-keys="false" :disabled="!isEjw" />
        </div>

        <div class="flex-1 input-container">
          <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.whichCare') }}</label>
          <FormFieldValidation class="input-container" :rules="{ requiredCare: this.form.careId }">
            <InputField
              v-model="form.care"
              v-debounce:500="getCares"
              :is-options-string-array="false"
              @selected-value="(x) => setCareId(x)"
              :options="cares"
            />
            <div v-if="isLoadingCares" class="loading-icon-absolute">
              <font-awesome-icon icon="circle-notch" size="lg" spin />
            </div>
          </FormFieldValidation>
        </div>
      </div>

      <div class="flex-item" :class="{ mobile: $screen.xs }" v-show="form.careId != null && !isZorgCategory">
        <div class="flex-1">
          <label>{{
            $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.yourBasicInsurance')
          }}</label>
          <BasicInsuranceDropdown :model-value="modelValue" @update:model-value="updateModelValue" />
        </div>
      </div>

      <div class="flex-item" :class="{ mobile: $screen.xs }">
        <div class="flex-1">
          <label>{{
            $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.institutionName')
          }}</label>
          <div class="input-container">
            <InputField
              v-model="form.institution"
              v-debounce:500="getInstitutions"
              :should-filter-by-value="false"
              :use-translation-keys="false"
              :options="institutions"
            />
            <div v-if="isLoadingInstitution" class="loading-icon-absolute">
              <font-awesome-icon icon="circle-notch" size="lg" spin />
            </div>
          </div>
        </div>
      </div>

      <div class="flex-item" :class="{ mobile: $screen.xs }">
        <div class="flex-1">
          <label>{{
            $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.zipcodeLocation')
          }}</label>
          <LocationInputField
            v-model="form.location"
            @zipcode="(x) => (form.zipcode = x)"
            @city="(x) => (form.city = x)"
          />
        </div>

        <div>
          <label>{{
            $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.maxDistance')
          }}</label>
          <DistanceDropdown v-model="form.distance" />
        </div>
      </div>

      <Button class="search-button" :disabled="isLoading" @click="getHealthCareProviders()">
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.search') }}
      </Button>

      <PlainText class="results-info" v-if="showResultsInfo">
        {{
          $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.searchResults', {
            numberOfResults: healthCareProviders.length,
            care: selectedCare.text,
            zipcodeCity: form.zipcode ?? form.city,
          })
        }}
      </PlainText>

      <LoadingSpinner :is-loading="isLoading">
        <HealthcareProviderInfo
          :selected-year="form.year"
          @click-health-care-provider="
            (x) =>
              $emit('clickHealthCareProvider', {
                healthcareProvider: x,
                selectedYear: form.year,
              })
          "
          :health-care-providers="healthCareProviders"
        />
      </LoadingSpinner>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import H1 from '@/components/shared/typography/H1.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

import HealthcareProviderInfo from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderInfo.vue';
import Button from '@/components/shared/button/Button.vue';
import InputField from '@/components/shared/input/InputField.vue';
import Dropdown from '@/components/shared/input/Dropdown.vue';
import DistanceDropdown from '@/components/viewelements/configuration/basicInsurance/overlays/DistanceDropdown.vue';
import BasicInsuranceDropdown from '@/components/viewelements/configuration/basicInsurance/overlays/BasicInsuranceDropdown.vue';
import LocationInputField from '@/components/viewelements/configuration/basicInsurance/overlays/LocationInputField.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

import ZorgzoekerUtils from '@/utils/zorgzoeker';
import Magnifier from '@/components/shared/icons/Magnifier.vue';

import HealthCareProviderCategory from '@/constants/HealthCareProviderCategory';

export default {
  name: 'HealthcareProviderSearch',
  emits: ['clickHealthCareProvider', 'update:modelValue'],
  components: {
    Form,
    H1,
    PlainText,
    HealthcareProviderInfo,
    Button,
    InputField,
    Dropdown,
    DistanceDropdown,
    BasicInsuranceDropdown,
    LocationInputField,
    FormFieldValidation,
    LoadingSpinner,
    Magnifier,
  },
  props: {
    modelValue: Number,
  },
  data() {
    return {
      form: {
        year: 2023,
        care: null,
        careId: null,
        institution: null,
        location: '',
        zipcode: null,
        city: null,
        distance: 10000,
      },
      isLoadingCares: false,
      cares: [],
      isLoadingInstitution: false,
      institutions: [],
      healthCareProviders: [],
      isLoading: false,
      showResultsInfo: false,
    };
  },
  computed: {
    ...mapGetters(['isEjw']),
    yearOptions() {
      var options = [this.currentYear];
      if (this.isEjw) {
        options.push(this.currentYear + 1);
      }
      return options.map((x) => ({ value: x, text: x }));
    },
    currentYear() {
      return new Date().getFullYear();
    },
    isZorgCategory() {
      return this.selectedCareCategory === HealthCareProviderCategory.Zorg;
    },
    selectedCareCategory() {
      return this.selectedCare?.category;
    },
    selectedCare() {
      return this.cares.find((x) => x.value === this.form.careId);
    },
  },
  methods: {
    onClose() {
      this.resetData();
      this.updateModelValue(false);
    },
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
    getCares() {
      if (this.form.care && this.form.care.length >= 3) {
        this.isLoadingCares = true;
        ZorgzoekerUtils.Get_Cares(this.form.care)
          .then((x) => {
            const list = [];
            x.data.forEach((y) => {
              y.cares.forEach((z) => {
                list.push({
                  value: z.id,
                  text: z.name,
                  category: y.category,
                });
              });
            });

            this.cares = list;
          })
          .finally(() => {
            this.isLoadingCares = false;
          });
      }
    },
    getInstitutions() {
      if (this.form.careId && this.form.institution && this.form.institution.length >= 3) {
        this.isLoadingInstitution = true;
        ZorgzoekerUtils.Get_Institutions(
          this.selectedCareCategory === HealthCareProviderCategory.Zorg ? this.form.careId : null,
          this.selectedCareCategory === HealthCareProviderCategory.Specialismen ? this.form.careId : null,
          this.selectedCareCategory === HealthCareProviderCategory.Aandoeningen ? this.form.careId : null,
          this.form.institution,
        )
          .then((x) => {
            this.institutions = x.data;
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      }
    },
    async getHealthCareProviders() {
      const validObject = await this.$refs.healthcareProvidersOverlayForm.validate();

      if (validObject.valid) {
        this.isLoading = true;
        ZorgzoekerUtils.Get_CareLocations(
          this.form.year,
          this.selectedCareCategory === HealthCareProviderCategory.Zorg ? this.form.careId : null,
          this.selectedCareCategory === HealthCareProviderCategory.Specialismen ? this.form.careId : null,
          this.selectedCareCategory === HealthCareProviderCategory.Aandoeningen ? this.form.careId : null,
          this.form.institution,
          this.form.zipcode,
          this.form.city,
          this.form.distance,
          this.isZorgCategory ? null : this.modelValue,
        )
          .then((x) => {
            this.healthCareProviders = x.data;
            this.showResultsInfo = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    chrevonColor() {
      return 'var(--color-primary)';
    },
    setCareId(careId) {
      this.form.careId = careId;
    },
    resetData() {
      this.healthCareProviders = [];
      this.showResultsInfo = false;
    },
  },
  watch: {
    form: {
      handler() {
        this.resetData();
      },
      deep: true,
    },
  },
  mounted() {
    this.form.year = this.isEjw ? this.currentYear + 1 : this.currentYear;
  },
};
</script>

<style lang="scss" scoped>
.header-section {
  margin-bottom: 24px;
}

.header-title {
  display: flex;
  gap: 15px;
  margin-bottom: 12px;
}

#magnifier-icon {
  color: var(--color-primary);
}

.flex-item {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
}

.loading-icon-absolute {
  position: absolute;
  top: 8px;
  right: 10px;
  color: var(--color-grey-500);
}

.flex-1 {
  flex: 1;
}

.input-container {
  position: relative;
}

.flex-item.mobile {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.year-container {
  width: 104px;
}

.search-button {
  margin-bottom: 32px;
}

.results-info {
  margin-bottom: 32px;
}
</style>
