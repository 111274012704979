<template>
  <Container class="home-container" :background-alt="true">
    <div>
      <!-- Header block -->
      <section class="header-block">
        <H1>{{ $tExistsOrDefault('main.title') }}</H1>
        <PlainText>{{ $tExistsOrDefault('main.title-intro') }}</PlainText>
      </section>

      <Card class="healthcare-box">
        <PlainText type="secondary">
          {{ $tExistsOrDefault('main.healthinsuranceWithUs.title') }}
        </PlainText>
        <PlainText class="d-inline">
          {{ $tExistsOrDefault('main.healthinsuranceWithUs.intro') }}
        </PlainText>
        <LinkText class="d-inline" target="_blank" :href="$tExistsOrDefault('main.healthinsuranceWithUs.link')">
          {{ $tExistsOrDefault('main.healthinsuranceWithUs.linkText') }}.
        </LinkText>
      </Card>

      <section v-if="labelSettings.label === 'aev'">
        <div class="text-collectivity-choice">
          <H3>{{ $tExistsOrDefault('main.collectivityAevSection.title') }}</H3>
          <PlainText>
            {{ $tExistsOrDefault('main.collectivityAevSection.intro') }}
          </PlainText>
        </div>

        <div class="radio-buttons-collectivity-choice">
          <RadioButton
            v-for="collectivityChoice in collectivityChoices"
            name="collectivityChoice"
            :model-value="selectedCollectivityChoice"
            @update:model-value="setSelectedCollectivityChoice"
            :radio-value="collectivityChoice.value"
            :key="collectivityChoice.value"
          >
            {{ $tExistsOrDefault(collectivityChoice.label) }}
          </RadioButton>
        </div>

        <template v-if="selectedCollectivityChoice !== collectivityChoiceNee">
          <div class="display-choice" v-if="!selectedContractCode && selectedCollectivityChoice">
            <SearchCollectivity class="search-collectivity" />
          </div>
        </template>

        <template v-else>
          <PlainText class="laef-text">
            {{ $tExistsOrDefault('main.laef.infoText') }}
          </PlainText>
        </template>
      </section>

      <section v-else>
        <H3 class="text-collectivity-choice">
          {{ $tExistsOrDefault('main.collectivitySection.title') }}
        </H3>
        <SearchCollectivity class="search-collectivity" />
      </section>

      <router-view ref="router-view" />

      <Tooltip
        class="d-inline-block"
        :disabled="!!selectedContractCode"
        :class="{
          'space-for-tooltip': selectedCollectivityChoice === null && selectedContractCode === null,
        }"
      >
        <ButtonNext :disabled="!selectedContractCode" @click="navigate()" />
        <template #content>
          <PlainText>
            {{ $tExistsOrDefault('main.nextDisableTooltip') }}
          </PlainText>
        </template>
      </Tooltip>
    </div>
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';
import Contracts from '@/constants/contracts';
import CollectivityChoice from '@/constants/CollectivityChoice';
import ContractCodes from '@/constants/ContractCodes';

import Card from '@/components/shared/card/Card.vue';

import Container from '@/components/shared/Container.vue';
import H1 from '@/components/shared/typography/H1.vue';
import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import Tooltip from '@/components/shared/Tooltip.vue';

import ButtonNext from '@/components/shared/button/ButtonNext.vue';
import RadioButton from '@/components/shared/input/RadioButton.vue';

import SearchCollectivity from '@/components/viewelements/home/searchCollectivity/SearchCollectivity.vue';

export default {
  name: 'Home',
  data() {
    return {
      collectivityChoices: [
        {
          label: 'main.collectivityAevSection.no',
          value: CollectivityChoice.CollectivityChoiceEnum.Nee,
        },
        {
          label: 'main.collectivityAevSection.yes',
          value: CollectivityChoice.CollectivityChoiceEnum.Ja,
        },
        {
          label: 'main.collectivityAevSection.dontKnow',
          value: CollectivityChoice.CollectivityChoiceEnum.WeetIkNiet,
        },
      ],
      search: null,
      collectivityChoiceNee: CollectivityChoice.CollectivityChoiceEnum.Nee,
      collectivityChoiceJa: CollectivityChoice.CollectivityChoiceEnum.Ja,
      collectivityChoiceWeetIkNiet: CollectivityChoice.CollectivityChoiceEnum.WeetIkNiet,
    };
  },
  components: {
    Container,
    Card,
    H1,
    H3,
    PlainText,
    LinkText,
    ButtonNext,
    RadioButton,
    Tooltip,
    SearchCollectivity,
  },
  computed: {
    ...mapGetters(['labelSettings', 'selectedContractCode', 'selectedCollectivityChoice']),
    searchOptions() {
      return this.$store.getters.contracts.map((x) => x.contractName);
    },
  },
  watch: {
    selectedContractCode: {
      handler(val) {
        if (val) {
          // scroll smoothly to the contract details once a contract is selected.
          // the timeout is needed to fetch the collectivity's USPs first.
          setTimeout(() => {
            // TODO: Home.vue:175 Uncaught TypeError: _this.$refs.router-view.$el.scrollIntoView is not a function
            this.$refs['router-view'].$el.scrollIntoView({
              behavior: 'smooth',
            });
          }, 300);
        }
      },
      immediate: false, // Don't run on mount
    },
  },
  methods: {
    clearSelectedContract() {
      this.$router.push({ name: 'home', params: { lang: this.$i18n.locale } });
    },
    setSelectedCollectivityChoice(value) {
      this.$store.dispatch(Contracts.Actions.SET_COLLECTIVITY_CHOICE, value);
      if (value === CollectivityChoice.CollectivityChoiceEnum.Nee) {
        this.setContractCode(ContractCodes.Laef);
      } else if (this.selectedContractCode) {
        this.clearSelectedContract();
      }
    },
    setContractCode(contractCode) {
      if (this.selectedContractCode !== contractCode) {
        this.$router.push({
          name: 'contract-overview',
          params: {
            ...this.$route.params,
            contractCode,
          },
        });
      }
    },
    navigate() {
      this.$router.push({
        name: 'insured',
        params: {
          lang: this.$i18n.locale,
          contractCode: this.selectedContractCode,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.home-container > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style lang="scss" scoped>
.header-block {
  margin-bottom: 32px;
}

.healthcare-box {
  max-width: 424px;
}

.text-collectivity-choice {
  margin-bottom: 16px;
}

.radio-buttons-collectivity-choice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 424px;
}

.display-choice {
  margin-bottom: 32px;
}

.search-collectivity {
  max-width: 600px;
}

.space-for-tooltip {
  margin-top: 80px;
}

.laef-text {
  margin-bottom: 16px;
  max-width: none;
}
</style>
