import Settings from '@/constants/settings';
import SettingsUtils from '@/utils/settings';

const state = {
  isCancelationServiceLoading: false,
  isCancelationServiceActive: undefined,
};

const getters = {
  isCancelationServiceLoading: (state) => state.isCancelationServiceLoading,
  isCancelationServiceActive: (state) => state.isCancelationServiceActive,
};

const actions = {
  [Settings.Actions.GetCancelationServiceActive]({ commit }) {
    return SettingsUtils.GetCancelationServiceActive().then((response) =>
      commit(Settings.Mutations.SET_CANCELATION_SERVICE_ACTIVE, response.data),
    );
  },
};

const mutations = {
  [Settings.Mutations.SET_CANCELATION_LOADING](state, payload) {
    state.isCancelationServiceLoading = payload;
  },

  [Settings.Mutations.SET_CANCELATION_SERVICE_ACTIVE](state, payload) {
    state.isCancelationServiceActive = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
