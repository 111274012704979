<template>
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <circle stroke="red" fill="#FAFAFA" cx="10.5" cy="10.5" r="10" />
      <path d="M2 2h17v17H2z" />
      <path d="m6.25 6.25 8.5 8.5m0-8.5-8.5 8.5" stroke="red" stroke-width="2" stroke-linecap="round" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NotCompensated',
};
</script>
