import CollectivityLabel from '@/constants/collectivityLabel';
import BusinessRule from '@/constants/business-rule';
import Config from '@/constants/config';

const state = {
  settings: {
    id: 0,
    domain: '',
    fullName: '',
    label: '',
    theme: '',
    logo: '',
    mainContracts: [],
  },
};

const getters = {
  labelSettings: (state) => state.settings,
};

const actions = {
  [CollectivityLabel.Actions.SET_SETTINGS]({ commit, getters }, url) {
    commit(CollectivityLabel.Mutations.SET_SETTINGS, url);

    if (getters.labelSettings.label !== 'aev') {
      commit(BusinessRule.Mutations.SET_NEWSLETTER, false);
    }
  },
};

const mutations = {
  [CollectivityLabel.Mutations.SET_SETTINGS](state, url) {
    const domain = new URL(url);

    let settings = Config.COLLECTIVITY_LABEL_SETTINGS.filter((x) => domain.hostname.includes(x.domain));
    if (!settings.some((x) => x)) settings = Config.COLLECTIVITY_LABEL_SETTINGS.filter((x) => x.label === 'aev');

    state.settings = settings[0];
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
