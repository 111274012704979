<template>
  <Container>
    <div class="d-flex">
      <div class="mt-3">
        <Form class="main-form" ref="personalExtraDataObserver" tag="form">
          <PersonalDataAddress :initial-form="form.address" @form-change="addressFormChange" />
          <hr />
          <PersonalDataContact :initial-form="form.contact" @form-change="contactFormChange" />
          <hr />
          <PersonalDataEmployer :initial-form="form.employer" @form-change="employerFormChange" />
          <hr />
          <PersonalDataPayment :initial-form="form.payment" @form-change="paymentFormChange" />
          <PaymentTerm v-if="!isBorderelPayment" />
        </Form>
        <div>
          <div class="button-group-previous-next mt-3 pb-5">
            <ButtonPrevious @click="back()">
              {{ $tExistsOrDefault('personal.prev') }}
            </ButtonPrevious>
            <ButtonNext @click="confirm()">
              {{ $tExistsOrDefault('personal.next') }}
            </ButtonNext>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { some } from 'lodash-es';

import { mapGetters } from 'vuex';

import PersonalDataAddress from '@/components/viewelements/personalDataExtra/PersonalDataAddress.vue';
import PersonalDataContact from '@/components/viewelements/personalDataExtra/PersonalDataContact.vue';
import PersonalDataEmployer from '@/components/viewelements/personalDataExtra/PersonalDataEmployer.vue';
import PersonalDataPayment from '@/components/viewelements/personalDataExtra/PersonalDataPayment.vue';
import BusinessRule from '@/constants/business-rule';
import { Form } from 'vee-validate';
import Validation from '@/constants/validation';
import CircleXMark from '@/components/shared/icons/CircleXMark.vue';
import 'vue-toastification/dist/index.css';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';
import PaymentTerm from '@/components/viewelements/configuration/PaymentTerm.vue';
import Container from '@/components/shared/Container.vue';

export default {
  name: 'PersonalExtraData',
  components: {
    PersonalDataAddress,
    PersonalDataContact,
    PersonalDataEmployer,
    PersonalDataPayment,
    Form,
    ButtonPrevious,
    ButtonNext,
    PaymentTerm,
    Container,
  },
  computed: {
    ...mapGetters(['agreement']),
    valid() {
      return this.$refs.personalExtraDataObserver.validate();
    },
    isBorderelPayment() {
      return this.agreement.possiblePaymentMethods.includes('I') || this.agreement.possiblePaymentMethods.includes('B');
    },
  },
  data() {
    return {
      form: {
        address: {
          zipcode: '',
          housenumber: '',
          housenumberAddition: '',
          street: '',
          city: '',
          country: '',
        },
        contact: {
          telephoneNumber: '',
          languageCode: '',
        },
        employer: {
          targetGroupCode: '',
          targetGroupName: '',
          employeeNumber: '',
        },
        payment: {
          hasAgreedToTransferService: false,
          hasAgreedToAutomaticCollection: false,
          paymentMethod: '',
          IBAN: '',
        },
      },
    };
  },
  created() {
    // Set all initial form data.
    // This must happen in the 'created' lifecycle, because 'mounted' happens after the child component
    // creation, which causes that state data is not ready available in de child components.
    this.form.address.zipcode = this.agreement.address.zipcode;
    this.form.address.housenumber = this.agreement.address.housenumber; // Inconsistent casing between front-end and back-end
    this.form.address.housenumberAddition = this.agreement.address.housenumberAddition;
    this.form.address.street = this.agreement.address.street;
    this.form.address.city = this.agreement.address.city;
    this.form.address.country = this.agreement.address.country;
    this.form.contact.telephoneNumber = this.agreement.address.telephoneNumber;
    this.form.contact.languageCode = this.agreement.languageCode;
    this.form.employer.targetGroupCode = this.agreement.targetGroupCode;
    this.form.employer.employeeNumber = this.agreement.employeeNumber;
    this.form.payment.hasAgreedToTransferService = this.agreement.hasCancellationService;
    this.form.payment.paymentMethod =
      this.agreement.possiblePaymentMethods.includes('B') || this.agreement.possiblePaymentMethods.includes('I')
        ? this.agreement.PaymentMethodForCosts
        : this.agreement.paymentMethod;
    this.form.payment.IBAN = this.agreement.paymentInformation.primaryBankAccountNumber;
  },
  mounted() {
    let valid = true;

    if (some(this.agreement.insuredPersons, (person) => !person.dateOfBirth)) {
      valid = false;
    }

    if (!valid) {
      this.$router.push({
        name: 'home',
        params: {
          lang: this.$i18n.locale,
        },
      });
      return;
    }
  },
  methods: {
    addressFormChange(event) {
      this.form.address.zipcode = event.zipcode ? event.zipcode.toUpperCase() : event.zipcode;
      this.form.address.housenumber = event.housenumber;
      this.form.address.housenumberAddition = event.housenumberAddition
        ? event.housenumberAddition.toUpperCase()
        : event.housenumberAddition;
      this.form.address.street = event.street;
      this.form.address.city = event.city;
      this.form.address.country = event.country;
    },
    contactFormChange(event) {
      this.form.contact.telephoneNumber = event.telephoneNumber;
      this.form.contact.languageCode = event.languageCode;
    },
    employerFormChange(event) {
      this.form.employer.targetGroupCode = event.targetGroupCode;
      this.form.employer.employeeNumber = event.employeeNumber;
    },
    paymentFormChange(event) {
      this.form.payment.IBAN = event.IBAN;
      this.form.payment.paymentMethod = event.paymentMethod;
      this.form.payment.hasAgreedToAutomaticCollection = event.hasAgreedToAutomaticCollection;
      this.form.payment.hasAgreedToTransferService = event.hasAgreedToTransferService;
    },
    async confirm() {
      const validObject = await this.valid;
      this.$store.dispatch(Validation.Actions.SET_PERSONAL_EXTRA_DATA, validObject.valid);

      if (validObject.valid) {
        this.$store.commit(BusinessRule.Mutations.SET_PERSONAL_EXTRA_DATA, {
          data: this.form,
        });
        this.$store.commit(BusinessRule.Mutations.SET_PAYMENT_INFO, this.form.payment);

        this.$router.push({
          name: 'overview',
          params: {
            lang: this.$i18n.locale,
            contractCode: this.$store.getters.selectedContractCode,
          },
        });
      } else {
        if (this.$screen.xs) {
          this.$toast(this.$tExistsOrDefault('personal.form.toasterInfo'), {
            position: 'top-center',
            draggable: false,
            icon: CircleXMark,
            toastClassName: 'body-toast-class mobile',
            closeButtonClassName: 'close-button-toast-class mobile',
          });
        } else {
          this.$toast(this.$tExistsOrDefault('personal.form.toasterInfo'), {
            position: 'top-right',
            draggable: false,
            icon: CircleXMark,
            toastClassName: 'body-toast-class',
            closeButtonClassName: 'close-button-toast-class',
          });
        }
        // dirty way of going to the first error.
        var el = this.$el.getElementsByClassName('is-invalid')[0].parentNode;
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    async back() {
      this.$store.commit(BusinessRule.Mutations.SET_PERSONAL_EXTRA_DATA, {
        data: this.form,
      });

      const validObject = await this.valid;

      this.$store.dispatch(Validation.Actions.SET_PERSONAL_EXTRA_DATA, validObject.valid);
      this.$router.push({
        name: 'personal_data',
        params: {
          activationStep: 2,
          lang: this.$i18n.locale,
          contractCode: this.$store.getters.selectedContractCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-form {
  max-width: 620px;
}

hr {
  display: block;
  height: 1px;
  background-color: solid 1px var(--color-grey-500);
}
</style>
