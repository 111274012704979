<template>
  <div class="step-wrap">
    <slot v-if="canNavigateTo(name)" :class="{ active: this.$route.name === name && $screen.gt.xs }" />
    <slot v-else v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    name: String,
  },
  methods: {
    canNavigateTo(pageName) {
      return this.$store.getters.pageValidationState[pageName] === true || this.$route.name === pageName;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-wrap {
  margin-right: 5px;
}
</style>
