function isObject(v) {
  return typeof v === 'object';
}

export default {
  toQueryString(obj, prefix) {
    var str = [];
    for (var p in obj) {
      var k = prefix ? prefix + '[' + p + ']' : p;
      var v = obj[p];
      str.push(isObject(v) ? this.toQueryString(v, k) : k + '=' + encodeURIComponent(v));
    }
    return str.join('&');
  },
};
