const Actions = {
  OPEN: 'a.modal.open',
  CLOSE: 'a.modal.close',
  SET_IS_PERSISTENT: 'a.modal.set-is-persistent',
  SHOW_OVERLAY: 'a.modal.show-overlay',
};

const Mutations = {
  OPEN: 'm.modal.open',
  CLOSE: 'm.modal.close',
  SET_IS_PERSISTENT: 'm.modal.set-is-persistent',
  SHOW_OVERLAY: 'm.modal.show-overlay',
};

export default {
  Actions,
  Mutations,
};
