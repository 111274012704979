<template>
  <div class="vh-100">
    <div id="component-header" class="text-right text-md-left">
      <CloseButton @click="onClose" />
    </div>
    <div id="component-body" :class="{ padded: padded }">
      <slot />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/shared/button/CloseButton.vue';

/**
 * @description Takes the full height of the parent. It has a sticky header with a close button and a body that takes the remaining space.
 */
export default {
  name: 'CloseButtonStickyHeaderSlot',
  emits: ['close'],
  components: {
    CloseButton,
  },
  props: {
    padded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
#component-header {
  padding: 24px 40px;
  @media screen and (max-width: 767px) {
    padding: 10px 24px;
  }
}
#component-body {
  height: calc(100% - 85px);
  overflow-y: auto;
  &.padded {
    padding: 0 40px;
    @media screen and (max-width: 767px) {
      padding: 0 24px;
    }
  }
}
</style>
