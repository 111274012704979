<template>
  <div>
    <div class="subtitle mt-4">
      {{ insuredTitleLabel }}
    </div>
    <!-- Question 1 -->
    <div class="question">
      {{ $tExistsOrDefault('medicalSelection.question1') }}
    </div>
    <FormFieldValidation
      tag="div"
      rules="requiredChoice"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer1"
      :initial-value="answers.answer1"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']"
          name="question-1"
          v-model="answers.answer1"
          @update:model-value="
            (x) => {
              setAnswer();
              handleChange(x);
            }
          "
          :radio-value="option == 'yes'"
          :key="`question1-${option}`"
          @blur="handleBlur"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
    </FormFieldValidation>

    <!-- Question 2 -->
    <div class="question">
      {{ $tExistsOrDefault('medicalSelection.question2') }}
    </div>
    <FormFieldValidation
      tag="div"
      rules="requiredChoice"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer2"
      :initial-value="answers.answer2"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']"
          name="question-2"
          v-model="answers.answer2"
          @update:model-value="
            (x) => {
              setAnswer();
              handleChange(x);
            }
          "
          :radio-value="option == 'yes'"
          :key="`question2-${option}`"
          @blur="handleBlur"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
    </FormFieldValidation>

    <!-- Question 3 -->
    <div class="question">
      {{ $tExistsOrDefault('medicalSelection.question3') }}
    </div>
    <FormFieldValidation
      tag="div"
      rules="requiredChoice"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer3"
      :initial-value="answers.answer3"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']"
          name="question-3"
          v-model="answers.answer3"
          @update:model-value="
            (x) => {
              setAnswer3();
              handleChange(x);
            }
          "
          :radio-value="option == 'yes'"
          :key="`question3-${option}`"
          @blur="handleBlur"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
    </FormFieldValidation>
    <div v-if="answers.answer3" class="question ml-4 mb-1">
      {{ $tExistsOrDefault('medicalSelection.infoQuestion3') }}
    </div>
    <FormFieldValidation
      v-if="answers.answer3"
      tag="div"
      rules="requiredExplanation"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer3comment"
      :initial-value="answers.answer3Comment"
    >
      <div v-if="answers.answer3" class="ml-4">
        <textarea
          class="form-control"
          v-model="answers.answer3Comment"
          maxlength="200"
          rows="3"
          :disabled="!answers.answer3 || undefined"
          @blur="
            {
              setAnswer();
              handleBlur();
            }
          "
          @change="handleChange"
        />
      </div>
    </FormFieldValidation>

    <!-- Question 4 -->
    <div class="question">
      {{ $tExistsOrDefault('medicalSelection.question4') }}
    </div>
    <FormFieldValidation
      tag="div"
      rules="requiredChoice"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer4"
      :initial-value="answers.answer4"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']"
          name="question-4"
          v-model="answers.answer4"
          @update:model-value="
            (x) => {
              setAnswer4();
              handleChange(x);
            }
          "
          :radio-value="option == 'yes'"
          :key="`question4-${option}`"
          @blur="handleBlur"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
    </FormFieldValidation>
    <div v-if="answers.answer4" class="question ml-4 mb-1">
      {{ $tExistsOrDefault('medicalSelection.infoQuestion4') }}
    </div>
    <FormFieldValidation
      v-if="answers.answer4"
      tag="div"
      rules="requiredExplanation"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer4-comment"
      :initial-value="answers.answer4Comment"
    >
      <div v-if="answers.answer4" class="ml-4">
        <textarea
          class="form-control"
          v-model="answers.answer4Comment"
          maxlength="200"
          rows="3"
          :disabled="!answers.answer4 || undefined"
          @change="handleChange"
          @blur="
            {
              setAnswer();
              handleBlur();
            }
          "
        />
      </div>
    </FormFieldValidation>

    <!-- Question 5 -->
    <div class="question">
      {{ $tExistsOrDefault('medicalSelection.question5') }}
    </div>
    <FormFieldValidation
      tag="div"
      rules="requiredChoice"
      v-slot="{ handleBlur, handleChange }"
      field-name="answer5"
      :initial-value="answers.answer5selected"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']"
          name="question-5"
          v-model="answers.answer5selected"
          @update:model-value="
            (x) => {
              setAnswer5Options(option == 'yes');
              handleChange(x);
            }
          "
          :radio-value="option == 'yes'"
          :key="`question1-${option}`"
          @blur="handleBlur"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
    </FormFieldValidation>
    <!-- Question 5 checkboxes -->
    <div v-if="enableAnswer5Options[personId]" class="ml-4 mb-1">
      <div class="mt-0 mb-2">
        {{ $tExistsOrDefault('medicalSelection.subquestion5') }}
      </div>
      <FormFieldValidation
        :rules="{
          requiredTreatments: this.answers.answer5,
        }"
        tag="div"
        v-slot="{ handleBlur, handleChange }"
        field-name="answer5-extra"
      >
        <Checkbox
          v-for="question in question5subquestions"
          v-model="question.selected"
          @update:model-value="
            (x) => {
              setAnswer5(question.value);
              handleChange(x);
            }
          "
          :key="`checkbox-${question.label}`"
          @blur="handleBlur"
          :id="`anwsers-${question.value}´`"
        >
          <PlainText class="checkBoxText">
            {{ $tExistsOrDefault(question.label) }}
          </PlainText>
        </Checkbox>
      </FormFieldValidation>
    </div>
    <div v-else>
      <ul class="ml-4">
        <li class="bullet" v-for="question in question5subquestions" :key="`li-${question.label}`">
          <label class="my-0">{{ $tExistsOrDefault(question.label) }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BusinessRule from '@/constants/business-rule';
import { mapGetters } from 'vuex';
import Dates from '@/helpers/dates';
import RadioButton from '@/components/shared/input/RadioButton.vue';
import Checkbox from '@/components/shared/input/Checkbox.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

export default {
  name: 'MedicalSelectionForm',
  components: {
    FormFieldValidation,
    RadioButton,
    Checkbox,
    PlainText,
  },
  props: {
    personId: Number,
  },
  data() {
    return {
      answers: {
        answer1: null,
        answer2: null,
        answer3: null,
        answer3Comment: '',
        answer4: null,
        answer4Comment: '',
        answer5selected: null,
        answer5: [],
      },
      question5subquestions: [
        { label: 'medicalSelection.answer1', value: '4 of meer vullingen', selected: false },
        { label: 'medicalSelection.answer2', value: 'een of meer kronen', selected: false },
        { label: 'medicalSelection.answer3', value: 'een of meer bruggen', selected: false },
        { label: 'medicalSelection.answer4', value: 'een of meer implantaten', selected: false },
        { label: 'medicalSelection.answer5', value: 'een gedeeltelijke gebitsprothese', selected: false },
        { label: 'medicalSelection.answer6', value: 'een uitgebreide tandvleesbehandeling', selected: false },
        { label: 'medicalSelection.answer7', value: '2 of meer wortelkanaalbehandelingen', selected: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['enableAnswer5Options', 'getDateOfBirth']),
    insured() {
      return this.$store.getters.insured.find((i) => i.id === this.personId);
    },
    packageNameWithMedicalSelection() {
      return this.insured.chosenInsurances.find((ci) => ci.hasMedicalSelection).title;
    },
    insuredTitleLabel() {
      return `${this.getInsuredLabel()} (${this.formatDate()}), ${this.packageNameWithMedicalSelection}`;
    },
  },
  methods: {
    getInsuredLabel() {
      if (this.insured != null) {
        switch (this.insured.typeOfInsured) {
          case 'H':
            return this.$tExistsOrDefault('personal.yourselfTitle');
          case 'P':
            return this.$tExistsOrDefault('personal.partnerTitle');
          case 'M':
            return this.$tExistsOrDefault('personal.childTitle');
        }
      }
    },
    formatDate() {
      return Dates.formatDate(this.getDateOfBirth(this.insured.id), 'dd MMMM yyyy');
    },
    setAnswer() {
      this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWERS, {
        personId: this.personId,
        answers: this.answers,
      });
    },
    setAnswer3() {
      if (this.answers.answer3 === false) {
        this.answers.answer3Comment = '';
      }
      this.setAnswer();
    },
    setAnswer4() {
      if (this.answers.answer4 === false) {
        this.answers.answer4Comment = '';
      }
      this.setAnswer();
    },
    setAnswer5(value) {
      if (this.answers.answer5.includes(value)) {
        this.answers.answer5 = this.answers.answer5.filter((val) => val !== value);
      } else {
        this.answers.answer5 = this.answers.answer5.concat([value]);
      }
      this.setAnswer();
    },
    setAnswer5Options(value) {
      this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT, {
        key: this.personId,
        value: value,
      });
      if (value) {
        this.answers.answer5 = this.answers.answer5.filter((val) => val !== 'nee');
      } else {
        this.answers.answer5 = ['nee'];
        this.question5subquestions.forEach((x) => (x.selected = false));
      }
      this.setAnswer();
    },
  },
  created() {
    const questions = this.insured.medicalSelection.question5 ?? [];

    this.answers.answer1 = this.insured.medicalSelection.question1;
    this.answers.answer2 = this.insured.medicalSelection.question2;
    this.answers.answer3 = this.insured.medicalSelection.question3;
    this.answers.answer3Comment = this.insured.medicalSelection.additionalInfoQuestion3;
    this.answers.answer4 = this.insured.medicalSelection.question4;
    this.answers.answer4Comment = this.insured.medicalSelection.additionalInfoQuestion4;
    this.answers.answer5selected = questions.length === 0 ? null : questions.some((val) => val !== 'nee');
    this.answers.answer5 = questions;

    this.question5subquestions.forEach((x) => {
      if (questions.includes(x.value)) {
        x.selected = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.radio-button-group {
  margin-top: 6px;
}
.question {
  margin-top: 24px;
  line-height: 22px;
}
textarea {
  resize: none;
  width: 100%;
}
ul li {
  text-indent: -1em;
  padding-left: 1em;
}
ul li label {
  display: inline;
  margin-left: -0.9em;
}

.checkBoxText {
  margin-left: 11px;
  margin-bottom: 4px;
}

.checkBoxText {
  margin-left: 11px;
  margin-bottom: 4px;
}
ul li.bullet::before {
  content: '\2022';
  color: var(--color-primary-900);
  color: var(--color-primary-900);
  font-weight: bold;
  font-size: 25px;
  display: inline-block;
  width: 22px !important;
  margin-left: 0.5rem;
}
ul li::before {
  content: '';
  display: inline-block;
  width: 22px !important;
  margin-left: 0.5rem;
}
</style>
