<template>
  <div>
    <PlainText class="search-text">
      {{ searchText }}
    </PlainText>
    <div class="input-container search-input">
      <FormFieldValidation :rules="{ minLengthSearchCollectivity: true }">
        <InputField
          :placeholder="$tExistsOrDefault('main.collectivitySection.searchYourCollectivity')"
          v-model="search"
          :options="searchOptions"
          :is-options-string-array="false"
          @selected-value="setContractCode"
          v-debounce:300="handleLoadAll"
        />
        <div v-if="isLoadingContracts" class="loading-icon-absolute">
          <font-awesome-icon icon="circle-notch" size="2x" spin />
        </div>
      </FormFieldValidation>
    </div>

    <div class="card-container">
      <ContractCard
        v-for="contract in contractCards"
        :key="contract.code"
        :image-source="contract.image"
        :contract-code="parseInt(contract.code, 10)"
        :total-count="contractCards.length"
        @selected-contract-code="setContractCode"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CollectivityChoice from '@/constants/CollectivityChoice';
import Contracts from '@/constants/contracts';
import Search from '@/constants/search';
import ContractCodes from '@/constants/ContractCodes';

import PlainText from '@/components/shared/typography/PlainText.vue';

import InputField from '@/components/shared/input/InputField.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

import ContractCard from '@/components/viewelements/home/searchCollectivity/ContractCard.vue';

export default {
  name: 'SearchCollectivity',
  components: {
    PlainText,
    FormFieldValidation,
    InputField,
    ContractCard,
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters(['labelSettings', 'selectedCollectivityChoice', 'selectedContractCode', 'contractsLoading']),
    searchOptions() {
      return this.$store.getters.contracts
        .filter((x) => x.contractCode !== ContractCodes.Laef.toString())
        .map((x) => ({
          value: x.contractCode,
          text: `${x.organisationName} (${x.contractName} - ${x.contractCode})`,
        }));
    },
    isLoadingContracts() {
      return this.contractsLoading['SEARCH'];
    },
    searchText() {
      return this.selectedCollectivityChoice === CollectivityChoice.CollectivityChoiceEnum.WeetIkNiet
        ? this.$tExistsOrDefault('main.collectivitySection.checkYourCollectivity')
        : this.$tExistsOrDefault('main.collectivitySection.searchBelowYourCollectivity');
    },
    contractCards() {
      return this.labelSettings.mainContractCodes.map((cc) => {
        return { image: `/images/${cc}.png`, code: `${cc}` };
      });
    },
  },
  methods: {
    handleLoadAll() {
      if (this.search.length >= 3) {
        this.$store.commit(Search.Mutations.SET, this.search);
        this.$store.dispatch(Contracts.Actions.SEARCH, this.search);
      }
      if (this.search.length === 0) {
        this.$store.dispatch(Contracts.Actions.CLEAR_SEARCH);
      }
    },
    setContractCode(contractCode) {
      if (contractCode) {
        this.search = null;

        if (this.selectedContractCode !== parseInt(contractCode)) {
          this.$router.push({
            name: 'contract-overview',
            params: {
              ...this.$route.params,
              contractCode,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
}
.loading-icon-absolute {
  position: absolute;
  bottom: 0px;
  right: 8px;
  color: var(--color-grey-500);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.search-text {
  margin-bottom: 14px;
}

.search-input {
  margin-bottom: 30px;
}
</style>
