<template>
  <div class="position-relative">
    <input
      @focus="hasFocus = true"
      @blur="blur()"
      :placeholder="placeholder"
      v-model="value"
      @input="updateSearchString($event.target.value)"
      class="form-control"
    />

    <DropdownList
      :model-value="dropdownListSelect"
      @update:model-value="dropdownListSelect = $event"
      :should-filter-by-value="isOptionsStringArray"
      :minimum-length-show-dropdown="minimumLengthShowDropdown"
      :open-dropdown="hasFocus"
      :use-translation-keys="false"
      :options="optionsValueText"
    />
  </div>
</template>

<script>
import DropdownList from '@/components/shared/input/DropdownList.vue';

export default {
  name: 'InputField',
  components: {
    DropdownList,
  },
  emits: ['update:modelValue', 'blur', 'input', 'selected-value'],
  props: {
    modelValue: null,
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isOptionsStringArray: {
      type: Boolean,
      default: true,
    },
    minimumLengthShowDropdown: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  computed: {
    dropdownListSelect: {
      get() {
        return this.isOptionsStringArray ? this.value : this.optionsValueText.find((x) => x.text === this.value)?.value;
      },
      set(v) {
        const result = this.getSelectedTextByValue(v);

        this.updateSearchString(result);
        this.$emit('selected-value', v);
      },
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);

        const selectedValue = this.getSelectedValueByText(v);
        this.$emit('selected-value', selectedValue);
      },
    },
    optionsValueText() {
      return this.isOptionsStringArray ? this.options.map((x) => ({ value: x, text: x })) : this.options;
    },
  },
  methods: {
    updateSearchString(value) {
      this.$emit('input', value);
      this.$emit('update:modelValue', value);
    },
    blur() {
      this.hasFocus = false;
      this.$emit('blur');
    },
    getSelectedTextByValue(value) {
      return this.isOptionsStringArray ? value : this.optionsValueText.find((x) => x.value === value)?.text;
    },
    getSelectedValueByText(value) {
      return this.isOptionsStringArray ? value : this.optionsValueText.find((x) => x.text === value)?.value;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  margin-bottom: 0;
}
</style>
