<template>
  <div id="app-base" style="min-height: 100vh" :class="{ 'app-mobile': $screen.xs }">
    <Modal :is-model-open="isModalOpen" :modal-data="modalData" />
    <Header :hide-header="shouldHideHeader" />
    <FloatingPremium class="floating-container" @active-floating-premium="hasActiveFloatingPremium" />
    <div>
      <WebChat v-if="!isCare4Life" class="fixed" />
    </div>
    <div
      class="main-content-wrapper"
      id="floatingPremiumHeightTarget"
      :class="{ 'no-scroll': $screen.xs && isFloatingPremiumActive }"
    >
      <div class="main-content content-fullheight">
        <router-view />
      </div>
      <Container :background-alt="true">
        <Footer />
      </Container>
    </div>

    <transition name="fade">
      <div v-show="shouldShowOverlay" class="greyed-out-overlay absolute-stretch" />
    </transition>
    <div id="loading-indicator" class="loading-bar" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/shared/modals/Modal.vue';
import CollectivityLabel from '@/constants/collectivityLabel';
import ModalConst from '@/constants/modal';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';
import Header from '@/components/viewelements/app/header/Header.vue';
import FloatingPremium from '@/components/shared/floatingPremium/FloatingPremium.vue';
import cookieBanner from '@/mixins/cookieBanner.js';
import Footer from '@/components/viewelements/app/footer/Footer.vue';
import Container from '@/components/shared/Container.vue';
import Cookies from 'js-cookie';
import siteimprove from '@/mixins/siteimprove.js';
import googletagmanager from '@/mixins/googleTagManager.js';
import googleSearchConsole from './mixins/googleSearchConsole';
import WebChat from '@/components/webchat/WebChat.vue';

export default {
  name: 'App',
  mixins: [siteimprove, cookieBanner, googletagmanager, googleSearchConsole],
  components: {
    Modal,
    Header,
    FloatingPremium,
    Footer,
    Container,
    WebChat,
  },
  data() {
    return {
      isFloatingPremiumActive: false,
    };
  },
  computed: {
    ...mapGetters({
      modalData: 'modalData',
      isModalOpen: 'isModalOpen',
      shouldShowOverlay: 'shouldShowOverlay',
      collectivityLabelSetting: 'labelSettings',
      revisitCookieName: 'revisitCookieName',
    }),
    shouldHideHeader() {
      return this.$screen.xs && this.isFloatingPremiumActive;
    },
    isCare4Life() {
      return this.labelSettings.label === 'c4l';
    },
  },
  methods: {
    hasActiveFloatingPremium(value) {
      this.isFloatingPremiumActive = value;
    },
  },
  beforeMount() {
    this.$store.dispatch(CollectivityLabel.Actions.SET_SETTINGS, window.location).then(() => {
      document.documentElement.setAttribute('theme', this.collectivityLabelSetting.theme);

      setTimeout(() => {
        const currentRouteName = this.$router.currentRoute.value.name;
        if (
          Cookies.get(this.revisitCookieName) !== undefined &&
          (currentRouteName === 'home' || currentRouteName === 'contract-overview')
        ) {
          this.$store.dispatch(ModalConst.Actions.OPEN, {
            modalData: ModalDataFactory.createRevisit(),
            isPersistent: true,
          });
        }
      }, 800);
    });
  },
};
</script>

<style lang="scss">
@use '@/assets/stylesheets/main.scss';

#app-base {
  background-color: var(--color-white);
}

.absolute {
  position: absolute;
  bottom: 0;
}

.z-index-10 {
  z-index: 10;
}

.loading-bar {
  position: fixed;
  z-index: 11;
  min-height: 11px !important;
  height: 11px !important;
  width: 100%;
  bottom: 0;
}

.greyed-out-overlay {
  background-color: rgba(75, 75, 75, 0.4);
  z-index: 80;
  cursor: progress;
}
.absolute-stretch {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.floating-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
}

.loading-icon {
  will-change: transform;
  animation: appear 2s 0.6s 1 forwards;
  opacity: 0;
}

.no-scroll {
  position: fixed;
}

@keyframes appear {
  to {
    opacity: 0.8;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.content-fullheight {
  min-height: calc(100vh - 225px);
}
</style>
