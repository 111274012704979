<template>
  <div>
    <HorizontalScrollBullets
      @click-bullet="(x) => $refs.horizontal.scrollToIndex(x)"
      :number-of-elements="numberOfElements"
      :current-selected-index="currentIndex"
      v-if="$screen.xs"
    />
    <VueHorizontal
      id="horizontal-scroll"
      :displacement="displacement"
      ref="horizontal"
      :snap="$screen.xs ? 'center' : 'none'"
      @scroll="onScroll"
      @scroll-debounce="onScrollDebounce"
      :button="!$screen.xs"
    >
      <slot />
    </VueHorizontal>
  </div>
</template>

<script>
import HorizontalScrollBullets from '@/components/shared/horizontalScroll/new/HorizontalScrollBullets.vue';
import VueHorizontal from '@/components/shared/horizontalScroll/new/VueHorizontal.vue';
export default {
  name: 'HorizontalScroll',
  components: {
    HorizontalScrollBullets,
    VueHorizontal,
  },
  props: {
    gap: {
      type: String,
      default: null,
    },
    maxWidthProcentage: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      numberOfElements: 0,
      width: 0,
      left: 0,
      scrollWidth: 0,
    };
  },
  computed: {
    currentIndex() {
      const widthOfOneElement = this.scrollWidth / this.numberOfElements;
      const index = Math.round(this.left / widthOfOneElement);

      return index;
    },
    displacement() {
      const widthOfOneElement = this.scrollWidth / this.numberOfElements;

      return widthOfOneElement / this.width;
    },
    maxWidhtContainer() {
      return this.maxWidthProcentage + '%';
    },
  },
  methods: {
    onScroll(data) {
      this.left = data.left;
    },
    onScrollDebounce(data) {
      this.left = data.left;
      this.width = data.width;
      this.scrollWidth = data.scrollWidth;
      this.numberOfElements = this.$refs.horizontal.children().length;
    },
  },
};
</script>

<style lang="scss">
#horizontal-scroll .v-hl-container {
  gap: v-bind(gap);
}

#horizontal-scroll .v-hl-container > * {
  max-width: v-bind(maxWidhtContainer);
}
</style>

<style lang="scss" scoped>
#horizontal-scroll:deep().v-hl-btn {
  svg {
    background: var(--color-primary-900);
    color: white;
    width: 39px;
    height: 39px;
    border-radius: 50%;
  }
}
</style>
