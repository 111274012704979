import { api } from '@/utils/api';

import Address from '@/constants/address';

export default {
  Load(zipCode, houseNumber) {
    const endpoint = Address.Endpoints.Load();
    const params = {
      zipCode: zipCode,
      houseNumber: houseNumber,
    };
    return api.get(endpoint, { params });
  },
};
