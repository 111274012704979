import Modal from '@/constants/modal';

const state = {
  isModalOpen: false,
  modalData: null,
  isPersistent: false,
  shouldShowOverlay: false,
};

const getters = {
  isModalOpen: (state) => state.isModalOpen,
  modalData: (state) => state.modalData,
  isPersistent: (state) => state.isPersistent,
  shouldShowOverlay: (state) => state.shouldShowOverlay,
};

const actions = {
  [Modal.Actions.OPEN]({ commit }, modalData) {
    commit(Modal.Mutations.OPEN, modalData);
  },

  [Modal.Actions.CLOSE]({ commit }) {
    commit(Modal.Mutations.CLOSE);
  },

  [Modal.Actions.SHOW_OVERLAY]({ commit }, shouldShowOverlay) {
    commit(Modal.Mutations.SHOW_OVERLAY, shouldShowOverlay);
  },
};

const mutations = {
  [Modal.Mutations.OPEN](state, payload) {
    state.isModalOpen = true;
    state.modalData = payload.modalData;
    if (payload.isPersistent) {
      state.isPersistent = payload.isPersistent;
    }
  },

  [Modal.Mutations.CLOSE](state) {
    state.modalData = null;
    state.isModalOpen = false;
    state.isPersistent = false;
  },

  [Modal.Mutations.SHOW_OVERLAY](state, payload) {
    state.shouldShowOverlay = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
