import { defineRule } from 'vee-validate';
import Dates from '@/helpers/dates';
import BSNService from '@/helpers/bsn-service';
import { app } from '@/main';
import { filterPhoneNumber } from '@/utils/phone-number';

const $tExistsOrDefault = (value, parameters) => app.config.globalProperties.$tExistsOrDefault(value, parameters);

// install rules
defineRule('max', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value) {
    return true;
  }
  if (value >= limit) {
    return `${value} is larger than the max allowed value of: ${limit}`;
  }
  return true;
});

defineRule('min', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value) {
    return true;
  }
  if (value <= limit) {
    return `${value} is smaller than the min allowed value of: ${limit}`;
  }
  return true;
});

defineRule('required', (value, [errorMessage]) => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    value === false ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return errorMessage ?? $tExistsOrDefault('personal.form.required');
  }
  return true;
});

defineRule('requiredChoice', (value) => {
  if (value === null || value === undefined) {
    return 'medicalSelection.errorRequiredChoice';
  }
  return true;
});

defineRule('requiredExplanation', (value) => {
  if (!value || !value.length) {
    return 'medicalSelection.errorRequiredExplanation';
  }
  return true;
});

defineRule('requiredTreatments', (value, selectedTreatments) => {
  if (selectedTreatments.length === 0) return 'medicalSelection.errorRequiredTreatments';
  return true;
});

defineRule('email', (value) => {
  // https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/email.ts
  /* eslint-disable-next-line no-useless-escape */
  const emailRE = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
  if (!emailRE.test(value)) return 'personal.form.email.message';
  return true;
});

defineRule('validBSN', (value, allBsns) => {
  const result = BSNService.verifyBSN(value.toString(), allBsns);

  if (result) {
    return result;
  } else {
    return true;
  }
});

defineRule('validateTerms', (value) => {
  if (!value) return 'overview.termsRequired';
  return true;
});

defineRule('validateThruthfullyCondition', (value) => {
  if (!value) return 'overview.questionTruthfullyRequired';
  return true;
});

defineRule('name', (value) => {
  const regex = /^[a-zA-ZÀ-ÿ-, ']*$/;
  return regex.test(value) || $tExistsOrDefault('personal.form.onlyNumbersAndSpecialCharacters');
});

defineRule('namePrefix', (value) => {
  const regex = /^[a-zA-ZÀ-ÿ-,' ]*$/;
  return regex.test(value) || 'personal.form.onlyNumbersAndSpacesAndSpecialCharacters';
});

defineRule('zipCode', (value) => {
  const regex = /^\d{4}\s?\w{2}$/;
  return regex.test(value) || $tExistsOrDefault('personal.form.zipcode.message');
});

defineRule('housenumber', (value) => {
  const regex = /^[0-9]{1,6}$/;
  return regex.test(value) || $tExistsOrDefault('personal.form.houseNumber.message');
});

defineRule('housenumberAddition', (value) => {
  const regex = /^[a-zA-ZÀ-ÿ0-9 ]{1,20}$/;
  return regex.test(value) || 'personal.form.onlyNumbersAndSpacesAndSpecialCharacters';
});

defineRule('street', (value) => {
  if (!value || !value.length) {
    return 'personal.form.street.required';
  }
  const regex = /^[a-zA-ZÀ-ÿ-, ']*$/;
  return regex.test(value) || 'personal.form.street.required';
});

defineRule('streetpobox', (value) => {
  const valueToCheck = value?.toLowerCase() ?? '';

  return (
    (!valueToCheck.includes('postbus') && !valueToCheck.includes('antwoordnummer')) || 'personal.form.street.invalid'
  );
});

defineRule('phone', (val) => {
  val = filterPhoneNumber(val);

  // Validates international phone number
  const length = val.length;
  if (val.startsWith('+31')) return length === 12 ? true : 'personal.form.phone.message';
  return (val.startsWith('+') && length > 9 && length <= 15) || 'personal.form.phone.message';
});

defineRule('employeeNumber', (value) => {
  const regex = /^[a-zA-ZÀ-ÿ0-9]{1,25}$/;
  return regex.test(value) || 'personal.form.employeeNumber.invalid';
});

defineRule('validDate', (value) => {
  if (!value) return true;
  if (value.length < 10) return $tExistsOrDefault('field.invalid');
  const [dayString, monthString, yearString] = value.split('-');
  const day = parseInt(dayString);
  const month = parseInt(monthString) - 1;
  const year = parseInt(yearString);
  const d = new Date(year, month, day);
  return (
    (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) || $tExistsOrDefault('field.invalid')
  );
});

defineRule('minDate', (value, [minDate]) => {
  const _minDate = new Date(minDate);
  if (!value | !minDate) return true;
  return (
    Dates.dateStringToDate(value) >= _minDate ||
    $tExistsOrDefault('field.minDate', {
      date: Dates.formatDate(_minDate),
    })
  );
});

defineRule('maxDate', (value, [maxDate]) => {
  const _maxDate = new Date(maxDate);
  if (!value || !_maxDate) return true;
  return (
    Dates.dateStringToDate(value) <= _maxDate ||
    $tExistsOrDefault('field.maxDate', {
      date: Dates.formatDate(_maxDate),
    })
  );
});

defineRule('minLengthSearchCollectivity', (value) => {
  value?.length >= 3 || $tExistsOrDefault('main.messages.minimal');
});

defineRule('requiredCare', (value, [careId]) => {
  return !!careId || 'validationMessages.requiredCare';
});

defineRule('validCorrectLocationOrZipcode', (value, options) => {
  if (
    value &&
    value.length > 0 &&
    ((value.match(/^\d/) && value.length >= 4) || options.map((x) => x.toLowerCase()).includes(value.toLowerCase()))
  ) {
    return true;
  }
  return 'validationMessages.cityOrPostalCodeRequired';
});
