<template>
  <div>
    <div class="mb-1">
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.paymentTerm.title') }}
      </PlainText>
      <PlainText class="text-box-size">
        {{
          $tExistsOrDefault('calculatePremium.paymentTerm.intro', {
            korting_jaar: jaarKorting,
          })
        }}
      </PlainText>
    </div>
    <PaymentTermSlot>
      <div class="radio-button-group" v-if="paymentTermNetDiscounts">
        <RadioButton
          v-for="{ paymentTerm, paymentTermNetPremiumWithOwnRiskDiscountTotal } in paymentTermNetDiscounts"
          name="payment-term"
          :model-value="selectedPaymentTermInConfiguration"
          @update:model-value="setSelectedPaymentTerm"
          :radio-value="paymentTerm"
          :key="paymentTerm"
        >
          <PlainText>
            Per
            {{ $tExistsOrDefault(`calculatePremium.paymentTerm.paymentTerms.${paymentTerm}`) }},
            {{ formatCurrency(paymentTermNetPremiumWithOwnRiskDiscountTotal) }}
            <span v-if="paymentTerm == 12" class="text-lowercase">
              ({{ jaarKorting }}% {{ $tExistsOrDefault('calculatePremium.paymentTerm.discount') }})
            </span>
          </PlainText>
        </RadioButton>
      </div>
    </PaymentTermSlot>
  </div>
</template>

<script>
import BusinessRule from '@/constants/business-rule';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import { mapGetters } from 'vuex';
import PaymentTermSlot from '@/components/viewelements/configuration/PaymentTermSlot.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import RadioButton from '@/components/shared/input/RadioButton.vue';

export default {
  name: 'PaymentTerm',
  components: {
    PaymentTermSlot,
    PlainText,
    RadioButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['paymentTermNetDiscounts', 'selectedPaymentTermInConfiguration', 'selectedContract']),
    paymentTerms() {
      return this.paymentTermNetDiscounts.map((x) => x.paymentTerm);
    },
    jaarKorting() {
      return this.selectedContract.paymentDiscountYear.toLocaleString(this.$i18n.locale);
    },
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
    setSelectedPaymentTerm(value) {
      this.$store.commit(BusinessRule.Mutations.SET_PAYMENT_TERM_SELECTION, value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
