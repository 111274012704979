<template>
  <!-- Payment details -->
  <div class="block-wrapper mb-0">
    <div class="pb-1">
      <H2>{{ $tExistsOrDefault('overview.paymentDetails') }}</H2>
      <!-- IBAN -->
      <FormFieldValidation
        ref="IBANValidationRef"
        :rules="{ required: 'overview.ibanAndBic.required', validateIBAN: true }"
        v-slot="{ handleBlur, handleChange }"
        field-name="IBAN"
        :initial-value="form.IBAN"
        :reserve-validation-space="true"
      >
        <div class="form-group">
          <label for="ibanInput">{{ $tExistsOrDefault('overview.ibanAndBic.label') }}</label>
          <div class="col-12 pl-0">
            <input
              id="ibanInput"
              v-model="form.IBAN"
              placeholder="NL00 BANK 0123 4567 89"
              type="text"
              class="form-control text-uppercase"
              v-maska="options"
              @change="handleChange"
              @blur="
                {
                  formChange();
                  handleBlur();
                }
              "
            />
          </div>
        </div>
      </FormFieldValidation>
      <div class="mt-3">
        <p class="borderel-intermediar-background" v-if="agreement.possiblePaymentMethods.includes('I')">
          {{ $tExistsOrDefault('calculatePremium.paymentTerm.intermediary') }}
        </p>
        <p class="borderel-intermediar-background" v-else-if="agreement.possiblePaymentMethods.includes('B')">
          {{ $tExistsOrDefault('calculatePremium.paymentTerm.employer') }}
        </p>
      </div>
      <!-- Payment method -->
      <div class="form-group">
        <PlainText>{{ $tExistsOrDefault(paymentMethodLabel) }}</PlainText>
        <div class="col-12 mt-2 pl-0">
          <!-- Payment method choice -->
          <FormFieldValidation
            :rules="{ required: 'overview.selectPayment.select-error' }"
            v-slot="{ handleBlur, handleChange }"
            field-name="paymentMethod"
            :initial-value="form.paymentMethod"
            :reserve-validation-space="true"
          >
            <div class="radio-button-group">
              <RadioButton
                v-for="paymentMethodOption in [
                  { label: 'automaticCollection', value: 'AUT' },
                  { label: 'ideal', value: 'IDEAL' },
                ]"
                name="paymentMethod"
                :model-value="form.paymentMethod"
                @update:model-value="
                  (x) => {
                    setForm(x);
                    handleChange(x);
                  }
                "
                @blur="handleBlur"
                :radio-value="paymentMethodOption.value"
                :key="paymentMethodOption.value"
              >
                {{ $tExistsOrDefault(`overview.${paymentMethodOption.label}`) }}
              </RadioButton>
            </div>
          </FormFieldValidation>
          <!-- Automatic payment extra information -->
          <!-- Using string for rule because a boolean just makes sur the validation is active or not.-->
          <FormFieldValidation
            v-if="form.paymentMethod === 'AUT'"
            :rules="{ validateAutomaticCollection: form.hasAgreedToAutomaticCollection + '' }"
            ref="autoCollectValidationProvider"
            v-slot="{ handleBlur, handleChange }"
            field-name="hasAgreedToAutomaticCollection"
            :initial-value="form.hasAgreedToAutomaticCollection"
          >
            <input
              type="checkbox"
              v-show="false"
              @blur="handleBlur"
              @update:model-value="handleChange"
              v-model="form.hasAgreedToAutomaticCollection"
            />
            <div
              v-if="hasVisitedAutomaticCollectionOverlay && !showAutomaticCollectionOverlay"
              class="pt-1"
              id="automatic-collection-result-wrapper"
            >
              <div>
                <span v-if="form.hasAgreedToAutomaticCollection" class="positive-text-color">
                  <img src="/images/icons/check-new.svg" alt="checkmark" />
                  {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.accepted') }}
                </span>
              </div>
              <component
                :is="form.hasAgreedToAutomaticCollection ? 'ButtonPrevious' : 'ButtonNext'"
                @click="showAutomaticCollectionOverlay = true"
              >
                {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.goto-mandate') }}
              </component>
            </div>
          </FormFieldValidation>
          <AutomaticCollectionOverlay
            v-model="showAutomaticCollectionOverlay"
            @on-accept="automaticCollectionFormChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Field, defineRule } from 'vee-validate';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import BusinessRule from '@/constants/business-rule';
import IBAN from 'iban';
import CircleXMark from '@/components/shared/icons/CircleXMark.vue';
import AutomaticCollectionOverlay from '@/components/viewelements/personalData/AutomaticCollectionOverlay.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import RadioButton from '@/components/shared/input/RadioButton.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import H2 from '@/components/shared/typography/H2.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

function validateIBAN(iban) {
  return IBAN.isValid(iban);
}

defineRule('validateIBAN', (value) => {
  return validateIBAN(value) ? true : 'overview.ibanNotValid';
});
//boolean string because set the true value only says to the rule to be active and it does not send the data to the define rule.
defineRule('validateAutomaticCollection', (value, [booleanString]) => {
  return booleanString === 'true' ? true : 'overview.selectPayment.automaticCollectionInfo.required';
});

export default {
  name: 'PersonalDataPayment',
  //// TODO: is this emit necessary?
  emits: ['formChange'],
  components: {
    Field,
    AutomaticCollectionOverlay,
    CircleXMark,
    ButtonNext,
    ButtonPrevious,
    RadioButton,
    PlainText,
    H2,
    FormFieldValidation,
  },
  props: {
    initialForm: Object,
  },
  data() {
    return {
      showAutomaticCollectionOverlay: false,
      hasVisitedAutomaticCollectionOverlay: false,
      form: { ...this.initialForm, hasAgreedToAutomaticCollection: false },
    };
  },
  computed: {
    ...mapGetters(['agreement', 'selectedPaymentTermInConfiguration']),
    paymentTerm() {
      return this.selectedPaymentTermInConfiguration;
    },
    paymentTermWithNetDiscount() {
      return this.agreement.paymentTermNetDiscounts.find(
        (pt) => pt.paymentTerm === this.selectedPaymentTermInConfiguration,
      );
    },
    paymentTermMonthWithNetDiscount() {
      return this.agreement.paymentTermNetDiscounts.find((pt) => pt.paymentTerm === 1);
    },
    paymentTermDiscount() {
      let yearAmountWithoutDiscount =
        this.paymentTermMonthWithNetDiscount.paymentTermNetPremiumWithOwnRiskDiscountTotal *
        this.selectedPaymentTermInConfiguration;
      return yearAmountWithoutDiscount - this.paymentTermWithNetDiscount.paymentTermNetPremiumWithOwnRiskDiscountTotal;
    },
    paymentMethod() {
      return this.form.paymentMethod;
    },
    paymentMethodLabel() {
      if (this.agreement.possiblePaymentMethods.includes('I') || this.agreement.possiblePaymentMethods.includes('B')) {
        return 'overview.paymentmethod.labelRisk';
      }
      return 'overview.paymentmethod.label';
    },
    options() {
      var options = {
        tokens: { A: { pattern: /[A-Z]/, transform: (chr) => chr.toUpperCase() } },
        eager: true,
      };

      if (this.form.IBAN.startsWith('NL')) {
        options.mask = 'NL## AAAA #### #### ##';
      } else {
        options.mask = 'AA## **** **** **** **** **** **** ***';
      }

      return options;
    },
  },
  methods: {
    //// TODO: test this dude when FE page is available to test
    //// Check whether to delete this dude, is used multiple times in this component,
    //// can it be replaced by setForm(value) method?
    formChange() {
      this.$emit('formChange', this.form);
      this.storePaymentInfo();
    },
    setForm(value) {
      //// Is this the way xD ?
      this.form.paymentMethod = value; // update value of radiobutton concerning paymentMethod
      this.$emit('formChange', this.form); // emit form (including paymentMethod to parent component)
      this.storePaymentInfo();
    },
    async automaticCollectionFormChange(event) {
      this.form.hasAgreedToAutomaticCollection = event;
      this.hasVisitedAutomaticCollectionOverlay = true;
      this.storePaymentInfo();
      await this.$refs['autoCollectValidationProvider'].validate();
    },
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
    validIban(iban) {
      return validateIBAN(iban);
    },
    gotoConfiguration(event) {
      if (event) event.preventDefault();
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$store.getters.selectedContractCode}/configuration`,
      });
    },
    async storePaymentInfo() {
      const IBANValidationObject = await this.$refs.IBANValidationRef.validate();

      if (IBANValidationObject.valid) {
        this.$store.commit(BusinessRule.Mutations.SET_PAYMENT_INFO, this.form);
      }
    },
    revokeAuthorizationAutomaticCollection() {
      this.hasVisitedAutomaticCollectionOverlay = true;
      this.form.hasAgreedToAutomaticCollection = false;
    },
  },
  mounted() {
    if (this.paymentMethod === 'AUT') {
      this.revokeAuthorizationAutomaticCollection();
      if (this.form.IBAN) {
        this.showAutomaticCollectionOverlay = true;
      }
    }
  },
  watch: {
    paymentMethod(method) {
      if (method === 'AUT' && !this.form.hasAgreedToAutomaticCollection) {
        this.showAutomaticCollectionOverlay = true;
      } else if (method === 'IDEAL') {
        this.revokeAuthorizationAutomaticCollection();
      }
    },
    showAutomaticCollectionOverlay(value) {
      if (!value) {
        this.hasVisitedAutomaticCollectionOverlay = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#automatic-collection-result-wrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.positive-text-color {
  color: var(--color-positive);
  img {
    vertical-align: baseline;
    margin-right: 5px;
  }
}
</style>
