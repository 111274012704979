<template>
  <div>
    <div>
      <Checkbox :model-value="!!insured" @update:model-value="setInsured" :check-box-size="24" :id="`child-${idChild}`">
        <PlainText class="label-text">
          {{ $tExistsOrDefault('insured.child') }}
        </PlainText>
      </Checkbox>
      <div class="row mb-3" v-if="insured">
        <PlainText class="col-12 mt-2 col-md-3 mb-2" id="input-label">
          {{ $tExistsOrDefault('insured.dateOfBirth') }}
        </PlainText>
        <ManualDateInput
          class="col"
          :model-value="insuredDateOfBirth"
          @update:model-value="insuredDateOfBirth = $event"
          :min-date="childValidDateRange.to"
          :max-date="childValidDateRange.from"
          :name="`insured-child-${insured.id}`"
        />
        <PlainText class="col-12" type="secondary" v-if="showFreeInsuranceText">
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.kidNotification') }}
        </PlainText>
      </div>
    </div>
  </div>
</template>

<script>
import { subYears } from 'date-fns';
import { mapGetters } from 'vuex';

import BusinessRule from '@/constants/business-rule';
import CalculatePremiumHelpers from '@/helpers/calculate-premium';

import ManualDateInput from '@/components/viewelements/insured/ManualDateInput.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Checkbox from '@/components/shared/input/Checkbox.vue';

export default {
  name: 'CalculatePremiumInsuredChild',
  components: {
    ManualDateInput,
    PlainText,
    Checkbox,
  },
  props: {
    insured: Object,
    idChild: Number,
  },
  computed: {
    ...mapGetters(['startDate', 'getDateOfBirth']),
    childValidDateRange() {
      return CalculatePremiumHelpers.childrenDisabledDates(this.startDate);
    },
    insuredDateOfBirth: {
      get() {
        return this.getDateOfBirth(this.insured.id);
      },
      set(value) {
        this.$store.dispatch(BusinessRule.Actions.SET_DATE_OF_BIRTH_FOR_INSURED, {
          insuredId: this.insured.id,
          dateOfBirth: value,
        });
      },
    },
    showFreeInsuranceText() {
      const dateOfBirth = this.getDateOfBirth(this.insured.id);

      return dateOfBirth && this.startDate && dateOfBirth > subYears(this.startDate, 18);
    },
  },
  methods: {
    setInsured() {
      if (this.insured) {
        this.$store.commit(BusinessRule.Mutations.REMOVE_INSURED, this.insured.id);
      } else {
        this.$store.commit(BusinessRule.Mutations.ADD_INSURED, { typeOfInsured: 'M' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label-text {
  margin-left: 13px;
}

.row {
  margin-left: 22px;
}
</style>
