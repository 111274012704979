<template>
  <Step v-if="$screen.gt.sm" class="d-flex">
    <StepDesktop
      v-for="(step, i) in stepInformation"
      :key="i"
      :is-visited-or-active="isVisitedOrActive(step.number)"
      :step-number="step.number"
      :step-name="$tExistsOrDefault(step.name)"
      :max-steps="step.maxSteps"
      :current-step="step.currentStep"
    />
  </Step>
  <Step v-else class="d-flex align-content-end">
    <StepMobile
      v-for="(step, i) in stepInformation"
      :key="i"
      :is-visited-or-active="isVisitedOrActive(step.number)"
      :step-number="step.number"
      :step-name="$tExistsOrDefault(step.name)"
      :max-steps="step.maxSteps"
      :current-step="step.currentStep"
    />
  </Step>
</template>

<script>
import Step from '@/components/viewelements/app/header/steps/Step.vue';
import StepDesktop from '@/components/viewelements/app/header/steps/StepDesktop.vue';
import StepMobile from '@/components/viewelements/app/header/steps/StepMobile.vue';

export default {
  name: 'Steps',
  components: {
    Step,
    StepDesktop,
    StepMobile,
  },
  data() {
    return {
      stepInformation: [
        {
          number: 1,
          name: 'breadcrumb.selectPersons',
          maxSteps: 1,
          currentStep: 0,
        },
        {
          number: 2,
          name: 'breadcrumb.choosePackages',
          maxSteps: 5,
          currentStep: 0,
        },
        {
          number: 3,
          name: 'breadcrumb.personalDetails',
          maxSteps: 3,
          currentStep: 0,
        },
        {
          number: 4,
          name: 'breadcrumb.checkAndSubmit',
          maxSteps: 1,
          currentStep: 0,
        },
      ],
    };
  },
  methods: {
    isVisitedOrActive(stepNumber) {
      if (stepNumber === 2 && this.$route.meta.step === 2) {
        this.stepInformation[stepNumber - 1].currentStep = parseInt(this.$route.params.activationStep) + 1;
      } else {
        this.stepInformation[stepNumber - 1].currentStep = this.$route.meta.stepProgress;
      }
      return {
        isVisited: this.$route.meta.step > stepNumber,
        isActive: this.$route.meta.step === stepNumber,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
