<template>
  <PartialOverlayWithCloseButton :model-value="showHospitalOverlay" @update:model-value="close">
    <KeepAlive>
      <Form ref="hospitalOverlayForm" v-if="healthcareProviderSelected == null">
        <section>
          <PlainText class="mt-3 d-inline">
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.paragraph') }}
          </PlainText>
          <LinkText class="d-inline" href="https://aevitae.com/zoek-jouw-zorgverlener" target="_blank">
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.paragraphLink') }}.
          </LinkText>
        </section>

        <section>
          <div class="container mt-3 mb-3">
            <div class="row justify-content-between">
              <div class="col-12 col-md-8 p-0 mb-3">
                <label>
                  {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.yourBasicInsurance') }}</label
                >
                <BasicInsuranceDropdown :model-value="modelValue" @update:model-value="updateModelValue" />
              </div>
              <div class="col-12 col-md-4 p-0 pl-md-3 mb-3">
                <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.year') }}</label>
                <Dropdown
                  :options="yearOptions"
                  v-model="selectedYear"
                  :use-translation-keys="false"
                  :disabled="!isEjw"
                />
              </div>
              <div class="col-12 col-md-8 p-0 mb-3">
                <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.nearby') }}</label>
                <LocationInputField
                  :model-value="location"
                  @update:model-value="(x) => (location = x)"
                  @zipcode="(x) => (zipcode = x)"
                  @city="(x) => (city = x)"
                  @blur="resetData()"
                />
              </div>
              <div class="col-12 col-md-4 p-0 pl-md-3 mb-3">
                <label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.maxDistance') }}</label>
                <DistanceDropdown v-model="selectedDistance" />
              </div>
            </div>
            <div class="row">
              <Button :disabled="isLoading" class="mt-3 button-search-hospital" @click="getListOfHospitals()">
                {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.search') }}
              </Button>
            </div>
          </div>
        </section>

        <LoadingSpinner :is-loading="isLoading">
          <HealthcareProviderInfo
            v-if="hospitals?.length > 0"
            :health-care-providers="hospitals"
            :selected-year="searchedYear"
            @click-health-care-provider="(x) => (healthcareProviderSelected = x)"
          />
          <PlainText v-if="isFailedCall" class="no-results">
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.failedCall') }}
          </PlainText>
          <PlainText v-else-if="hospitals?.length === 0" class="no-results">
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.noResults') }}
          </PlainText>
        </LoadingSpinner>
      </Form>
      <HealthcareProviderDetails
        v-else
        :selected-year="searchedYear"
        :health-care-provider="healthcareProviderSelected"
        @close-healthcare-provider="healthcareProviderSelected = null"
      />
    </KeepAlive>
  </PartialOverlayWithCloseButton>
</template>

<script>
import Zorgzoeker from '@/constants/zorgzoeker';

import { mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import Dropdown from '@/components/shared/input/Dropdown.vue';

import DistanceDropdown from '@/components/viewelements/configuration/basicInsurance/overlays/DistanceDropdown.vue';
import LocationInputField from '@/components/viewelements/configuration/basicInsurance/overlays/LocationInputField.vue';
import BasicInsuranceDropdown from '@/components/viewelements/configuration/basicInsurance/overlays/BasicInsuranceDropdown.vue';

import PartialOverlayWithCloseButton from '@/components/shared/overlay/PartialOverlayWithCloseButton.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import Button from '@/components/shared/button/Button.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

import ZorgzoekerUtils from '@/utils/zorgzoeker';
import HealthcareProviderInfo from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderInfo.vue';
import HealthcareProviderDetails from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderDetails.vue';

export default {
  name: 'HospitalOverlay',
  emits: ['update:modelValue'],
  components: {
    Form,
    Dropdown,
    DistanceDropdown,
    BasicInsuranceDropdown,
    PartialOverlayWithCloseButton,
    PlainText,
    LinkText,
    Button,
    LoadingSpinner,
    LocationInputField,
    HealthcareProviderInfo,
    HealthcareProviderDetails,
  },
  props: {
    modelValue: {
      type: [Number, null],
    },
  },
  data() {
    return {
      selectedYear: null,
      selectedDistance: 10000,
      hospitals: null,
      isLoading: false,
      isFailedCall: false,
      location: '',
      zipcode: null,
      city: null,
      healthcareProviderSelected: null,
      searchedYear: null,
    };
  },
  mounted() {
    this.$store.dispatch(Zorgzoeker.Actions.GET_CITIES);
    this.selectedYear = this.isEjw ? this.currentYear + 1 : this.currentYear;
  },
  computed: {
    ...mapGetters(['isEjw']),
    showHospitalOverlay() {
      return this.modelValue != null;
    },
    yearOptions() {
      var options = [this.currentYear];
      if (this.isEjw) {
        options.push(this.currentYear + 1);
      }
      return options.map((x) => ({ value: x, text: x }));
    },
    currentYear() {
      return new Date().getFullYear();
    },
    isMobile() {
      return this.$screen.xs;
    },
  },
  methods: {
    async getListOfHospitals() {
      const isValid = await this.$refs.hospitalOverlayForm.validate();

      if (isValid.valid) {
        this.resetData();
        this.isLoading = true;
        ZorgzoekerUtils.Get_Hospitals(
          this.zipcode,
          this.city,
          this.selectedDistance,
          this.modelValue,
          this.selectedYear,
        )
          .then((x) => {
            this.searchedYear = this.selectedYear;
            this.hospitals = x.data;
          })
          .catch(() => {
            this.isFailedCall = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    resetData() {
      this.hospitals = null;
      this.isFailedCall = false;
    },
    close() {
      this.$emit('update:modelValue', null);
    },
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
  watch: {
    selectedDistance() {
      this.resetData();
    },
    modelValue() {
      this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 100%;
}

.table-hospital {
  width: 100%;
}

.header-box {
  height: 57px;
  background-color: var(--color-grey-050);
}

.header-box th {
  padding: 18px 0px 18px 24px;
  font-weight: normal;
}

.data-box {
  border-bottom: solid 1px var(--color-primary-100);
}

.data-box td {
  padding: 18px 0px 18px 24px;
}

.header {
  color: var(--color-grey-500);
}

.row-box {
  width: auto;
  height: 50px;
  border-bottom: 1px solid var(--color-primary-100);
}

.data {
  font-size: 16px;
  color: var(--color-grey-900);
  line-height: 22px;
  padding: 0px 6px;
}

.no-results {
  text-align: center;
}

.button-search-hospital {
  width: 78px;
}
</style>
