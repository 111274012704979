<template>
  <div class="block-wrapper">
    <div>
      <H2>{{ $tExistsOrDefault('personal.contract') }}</H2>
      <div class="main-form mt-3">
        <div class="form-group mb-2">
          <label for="telephoneInput">{{ $tExistsOrDefault('personal.form.phone.label') }}</label>
          <FormFieldValidation
            :rules="{ required: 'personal.form.phone.required', phone: true }"
            v-slot="{ handleBlur, handleChange }"
            field-name="phone"
            :initial-value="form.telephoneNumber"
            :reserve-validation-space="true"
          >
            <vue-tel-input
              id="telephoneInput"
              style="height: auto !important"
              class="mb-2"
              v-model="form.telephoneNumber"
              mode="international"
              default-country="NL"
              :valid-characters-only="true"
              :preffered-countries="['NL', 'BE', 'DE']"
              :input-options="{
                placeholder: '6 1234 5678',
                required: true,
                maxlength: 20,
                showDialCode: true,
                required: true,
              }"
              @blur="
                {
                  changeForm();
                  handleBlur();
                }
              "
              maxlength="15"
              @input="handleChange($event)"
            />
          </FormFieldValidation>
        </div>
        <div class="form-group">
          <label class="col-sm-4 pl-0">{{ $tExistsOrDefault('personal.form.correspondentieLanguage.label') }}</label>
          <div class="col-sm-8 pl-0">
            <FormFieldValidation
              :rules="{ required: 'personal.form.correspondentieLanguage.required' }"
              v-slot="{ handleChange }"
              field-name="language"
              :initial-value="form.languageCode"
            >
              <div class="horizontal-radio mb-2">
                <div class="radio-button-group">
                  <RadioButton
                    v-for="language in ['NL', 'EN']"
                    name="correspondentieLanguage"
                    :model-value="form.languageCode"
                    @update:model-value="
                      (x) => {
                        setForm(x);
                        handleChange(x);
                      }
                    "
                    :radio-value="language"
                    :key="language"
                  >
                    {{ $tExistsOrDefault(`personal.languages.${language}`) }}
                  </RadioButton>
                </div>
              </div>
            </FormFieldValidation>
          </div>
        </div>
        <div>
          <div class="col-md-8 mt-2 pl-0">
            <span v-html="$tExistsOrDefault(`personal.corresponding.info.${labelSettings.label}`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import H2 from '@/components/shared/typography/H2.vue';
import RadioButton from '@/components/shared/input/RadioButton.vue';
import { filterPhoneNumber } from '@/utils/phone-number';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

export default {
  name: 'PersonalDataContact',
  //// TODO: is this emit necessary?
  emits: ['formChange'],
  components: {
    H2,
    RadioButton,
    FormFieldValidation,
  },
  props: {
    initialForm: Object,
  },
  data() {
    return {
      form: { ...this.initialForm },
      telInputProps: {
        defaultCountry: 'NL',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: '6 1234 5678',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['NL', 'BE', 'DE'],
        name: 'telephone',
        maxLen: 20,
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
        validCharactersOnly: true,
      },
    };
  },
  computed: {
    ...mapGetters(['labelSettings']),
  },
  methods: {
    //// TODO: test this dude when FE page is available to test
    //// Check whether to delete this dude, is a;so used on line 32 for telephonenumber,
    //// can it be replaced by setForm(value) method?
    changeForm() {
      this.$emit('formChange', this.form);
    },
    setForm(value) {
      this.form.languageCode = value;
      this.$emit('formChange', this.form);
    },
    filterPhoneNumber,
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0rem;
}

.flex-1 {
  padding-left: 25px;
}
</style>

<style lang="scss">
@import 'vue-tel-input/vue-tel-input.css';

.vue-tel-input {
  border-radius: 0.25rem;
  font-size: 1rem;
  border: 1px solid var(--color-grey-400) !important;
  background-color: var(--color-white) !important;
  &:focus-within {
    border-color: var(--color-primary-800) !important;
    box-shadow: none !important;
  }
  .vti__dropdown,
  input {
    padding: 8px;
    color: var(--color-grey-700);
  }
  .highlighted {
    background-color: var(--color-primary-800) !important;
    color: var(--color-white) !important;
  }
  .vti__flag {
    box-shadow: none;
  }
  strong {
    font-weight: normal;
  }
}
</style>
