<template>
  <Container :padded="false">
    <div class="max-width-page">
      <!-- Header block -->
      <Container>
        <div v-if="selectedContract">
          <H1 class="h1-title">{{ $tExistsOrDefault('calculatePremium.calculationOverview.title') }}</H1>
          <PlainText class="intro" v-html="$tExistsOrDefault('calculatePremium.calculationOverview.intro')" />
        </div>
        <H3 class="overview-title">{{
          $tExistsOrDefault('calculatePremium.calculationOverview.yourHealthinsurance')
        }}</H3>
      </Container>

      <!-- Main content -->
      <div class="main-content">
        <Form ref="overviewObserver" tag="form">
          <!-- Premium overview table -->
          <CardDesktopOnly :class="{ 'shadow-margin': $screen.gt.xs }">
            <OverviewHealthInsurance :insured-persons="agreement.insuredPersons" />
          </CardDesktopOnly>

          <Container :padded="false">
            <OverviewTotalPremium class="total-premium" :class="{ mobile: $screen.xs }" />
          </Container>

          <CardDesktopOnly :class="{ 'shadow-margin': $screen.gt.xs }">
            <OverviewContactDetails />
          </CardDesktopOnly>

          <div v-if="$screen.xs" class="space-between-contact-payment" />

          <CardDesktopOnly :class="{ 'shadow-margin': $screen.gt.xs }">
            <OverviewPaymentDetails />
          </CardDesktopOnly>

          <HorizontalRule v-if="$screen.gt.xs" class="horizontal-rule" />

          <Container :padded="false" class="last-section">
            <!-- Switching service -->
            <section class="title-text-section">
              <H3>{{ $tExistsOrDefault('overview.cancellationService.switchingServiceTitle') }}</H3>
              <template v-if="isJanuary">
                <PlainText>{{
                  $tExistsOrDefault('overview.cancellationService.cancellationNotPossibleDeclaration.part1')
                }}</PlainText>
                <PlainText>{{
                  $tExistsOrDefault('overview.cancellationService.cancellationNotPossibleDeclaration.part2')
                }}</PlainText>
                <PlainText>{{
                  $tExistsOrDefault('overview.cancellationService.cancellationNotPossibleDeclaration.part3')
                }}</PlainText>
              </template>
              <template v-else>
                <PlainText>{{
                  $tExistsOrDefault('overview.cancellationService.switchingServiceDeclaration')
                }}</PlainText>
              </template>
            </section>

            <!-- Newsletter -->
            <section class="title-text-section" v-if="labelSettings.label === 'aev'">
              <H3>{{ $tExistsOrDefault('overview.newsletter') }}</H3>
              <div>
                <Checkbox
                  class="checkbox"
                  :check-box-size="22"
                  :model-value="agreement.hasDigitalNews"
                  @update:model-value="setDigitalNews"
                  id="digital-news"
                >
                  <PlainText class="checkBoxText">{{
                    agreement.insuredPersons.length > 1
                      ? $tExistsOrDefault('overview.newsletterMutipleText')
                      : $tExistsOrDefault('overview.newsletterSingleText')
                  }}</PlainText>
                </Checkbox>
              </div>
            </section>

            <!-- Terms and conditions -->
            <section class="title-text-section">
              <H3>{{ $tExistsOrDefault('overview.conditions') }}</H3>
              <FormFieldValidation
                class="margin-bottom"
                :should-have-space-between="false"
                :rules="{ validateThruthfullyCondition: true }"
                v-slot="{ handleBlur, handleChange }"
                field-name="hasAgreedToTerms"
                :initial-value="form.hasAgreedToTerms"
              >
                <Checkbox
                  class="checkbox"
                  :check-box-size="22"
                  v-model="form.hasAgreedToTerms"
                  @update:model-value="handleChange"
                  @blur="handleBlur"
                  id="agreed-terms"
                >
                  <PlainText class="checkBoxText">{{
                    $tExistsOrDefault('overview.questionTruthfullyAnswered')
                  }}</PlainText>
                </Checkbox>
              </FormFieldValidation>

              <FormFieldValidation
                class="margin-bottom"
                :should-have-space-between="false"
                :rules="{ validateTerms: true }"
                v-slot="{ handleBlur, handleChange }"
                field-name="hasAgreedToGeneralTerms"
                :initial-value="form.hasAgreedToGeneralTerms"
              >
                <Checkbox
                  class="checkbox"
                  :check-box-size="22"
                  v-model="form.hasAgreedToGeneralTerms"
                  @update:model-value="handleChange"
                  @blur="handleBlur"
                  id="agreed-general-terms"
                >
                  <div class="checkBoxText">
                    <PlainText class="d-inline">{{
                      $tExistsOrDefault('overview.readTermsAndConditions.part1')
                    }}</PlainText>
                    <LinkText
                      class="d-inline"
                      target="_blank"
                      href="https://www.aevitae.com/over-aevitae/algemene-voorwaarden/"
                      >{{ $tExistsOrDefault('overview.readTermsAndConditions.link') }}</LinkText
                    >
                    <PlainText class="d-inline">
                      {{ $tExistsOrDefault('overview.readTermsAndConditions.part2') }}</PlainText
                    >
                  </div>
                </Checkbox>
              </FormFieldValidation>

              <Checkbox
                class="checkbox"
                :check-box-size="22"
                :model-value="agreement.hasDigitalPolicy"
                @update:model-value="setDigitalPolicy"
                id="digital-policy"
              >
                <div class="checkBoxText">
                  <PlainText class="d-inline">{{ $tExistsOrDefault('overview.digitalPolicy.part1') }} </PlainText>
                  <LinkText
                    class="d-inline"
                    target="_blank"
                    href="https://bjjxnp3xwdg5ostandardsa.blob.core.windows.net/media/7159/akkoordverklaring.pdf"
                    >{{ $tExistsOrDefault('overview.digitalPolicy.link') }}</LinkText
                  >
                </div>
              </Checkbox>
            </section>

            <section class="title-text-section">
              <H3>{{ $tExistsOrDefault('overview.processingPersonalData') }}</H3>
              <PlainText class="d-inline"
                >{{ $tExistsOrDefault('overview.processingPersonalDataText.part1') }}
              </PlainText>
              <LinkText class="d-inline" target="_blank" href="https://www.aevitae.com/privacy-statement/">{{
                $tExistsOrDefault('overview.processingPersonalDataText.link')
              }}</LinkText>
              <PlainText class="d-inline">
                {{ $tExistsOrDefault('overview.processingPersonalDataText.part2') }}</PlainText
              >
            </section>

            <section class="title-text-section">
              <H3>{{ $tExistsOrDefault('overview.reflectionPeriod') }}</H3>
              <PlainText>{{ $tExistsOrDefault('overview.reflectionPeriodText') }}</PlainText>
            </section>
          </Container>
        </Form>
      </div>

      <HorizontalRule class="horizontal-rule" />

      <!-- Buttons -->
      <Container class="pb-5">
        <div class="button-group-previous-next">
          <ButtonPrevious @click="$router.go(-1)" />
          <Button layout-type="action" @click="handleConfirmation">{{
            $tExistsOrDefault('calculatePremium.calculationOverview.sendYourApplication')
          }}</Button>
        </div>
      </Container>
    </div>
  </Container>
</template>

<script>
import { some } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import OverviewHealthInsurance from '@/components/viewelements/overview/OverviewHealthInsurance.vue';
import OverviewTotalPremium from '@/components/viewelements/overview/OverviewTotalPremium.vue';
import OverviewPaymentDetails from '@/components/viewelements/overview/OverviewPaymentDetails.vue';
import OverviewContactDetails from '@/components/viewelements/overview/OverviewContactDetails.vue';
import BusinessRule from '@/constants/business-rule';
import Validation from '@/constants/validation';
import Settings from '@/constants/settings';
import formatCurrencyHelper from '@/helpers/formatCurrency';

import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import Button from '@/components/shared/button/Button.vue';
import Checkbox from '@/components/shared/input/Checkbox.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

import H1 from '@/components/shared/typography/H1.vue';
import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import CardDesktopOnly from '@/components/shared/card/CardDesktopOnly.vue';
import Container from '@/components/shared/Container.vue';

import HorizontalRule from '@/components/shared/HorizontalRule.vue';

import 'vue-toastification/dist/index.css';
import Cookies from 'js-cookie';
import Dates from '@/helpers/dates.js';

export default {
  components: {
    OverviewHealthInsurance,
    OverviewTotalPremium,
    OverviewPaymentDetails,
    OverviewContactDetails,
    Form,
    ButtonPrevious,
    Button,
    Checkbox,
    FormFieldValidation,
    Container,
    H1,
    H3,
    PlainText,
    LinkText,
    CardDesktopOnly,
    HorizontalRule,
  },
  data() {
    return {
      form: {
        hasAgreedToTerms: false,
        hasAgreedToGeneralTerms: false,
      },
      hasSubmitted: false,
    };
  },
  computed: {
    ...mapGetters(['agreement', 'labelSettings', 'selectedContract', 'revisitCookieName']),
    getMainInsured() {
      return this.agreement.insuredPersons.find((p) => p.typeOfInsured === 'H');
    },
    valid() {
      return this.$refs.overviewObserver.validate();
    },
    hasDigitalPolicy: {
      get() {
        return this.agreement.hasDigitalPolicy;
      },
      set(v) {
        this.digitalPolicyCheckboxChanged(v);
      },
    },
    isJanuary() {
      const currentDate = new Date();
      return Dates.isJanuary(currentDate);
    },
  },
  methods: {
    ...mapActions({ getCancelationService: Settings.Actions.GetCancelationServiceActive }),
    toggleForm(parameter) {
      // unused atm
      this.$set(this.form, parameter, !this.form[parameter]);
    },
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale() ? this.$i18n.locale() : 'NL');
    },
    setDigitalNews(value) {
      this.$store.dispatch(BusinessRule.Actions.SET_NEWSLETTER, value);
    },
    setDigitalPolicy(value) {
      this.$store.dispatch(BusinessRule.Actions.SET_DIGITAL_POLICY, value);
    },
    async handleConfirmation() {
      if (!this.hasSubmitted) {
        const validObject = await this.valid;

        if (validObject.valid) {
          this.hasSubmitted = true;
          await this.complete();
        } else {
          // dirty way of going to the first error.
          var el = this.$el.getElementsByClassName('is-invalid')[0].parentNode;
          el.scrollIntoView();
        }
      } else {
        this.showToastMessageBusy();
      }
    },
    showToastMessageBusy() {
      let toastClassName = 'body-toast-class';
      let closeButtonClassName = 'close-button-toast-class';
      if (this.$screen.xs) {
        toastClassName += ' mobile';
        closeButtonClassName += ' mobile';
      }

      this.$toast(this.$tExistsOrDefault('overview.requestProcessingToast'), {
        position: 'top-right',
        draggable: false,
        toastClassName: toastClassName,
        closeButtonClassName: closeButtonClassName,
        icon: false,
      });
    },
    closeToast() {
      this.$toast.clear();
    },
    async complete() {
      this.$store
        .dispatch(Validation.Actions.SET_OVERVIEW, await this.valid)
        .then(() => this.$store.commit(BusinessRule.Mutations.SET_COLLECTIVITY_LABEL))
        .then(() => this.$store.dispatch(BusinessRule.Actions.SUBMIT_AGREEMENT))
        .then(() => {
          Cookies.remove(this.revisitCookieName);
          this.$store.commit(BusinessRule.Mutations.CLEAR);
          this.closeToast();
          this.$router.push({
            name: 'complete',
            params: {
              lang: this.$i18n.locale,
              contractCode: this.$store.getters.selectedContractCode,
              isSubmittedSuccessfully: true,
            },
          });
        })
        .catch(() =>
          this.$router.push({
            name: 'complete',
            params: {
              lang: this.$i18n.locale,
              contractCode: this.$store.getters.selectedContractCode,
              isSubmittedSuccessfully: false,
            },
          }),
        );
    },
  },
  mounted() {
    let valid = true;

    if (some(this.agreement.insuredPersons, (person) => !person.dateOfBirth)) {
      valid = false;
    }

    if (!valid) {
      this.$router.push({
        name: 'home',
        params: {
          lang: this.$i18n.locale,
        },
      });
    }
    this.getCancelationService();
  },
};
</script>

<style lang="scss" scoped>
.max-width-page {
  max-width: 780px;
}

.h1-title {
  margin-bottom: 7px;
}

.intro {
  margin-bottom: 22px;
}

.total-premium {
  margin-bottom: 35px;
  margin-right: 24px;
  padding: 0px 24px;
}

.total-premium.mobile {
  margin-top: 30px;
  margin-right: 0px;
}

.shadow-margin {
  margin: 0px 24px;
}

.title-text-section {
  margin-bottom: 22px;
  H3 {
    margin-bottom: 15px;
  }
  .checkBoxText {
    margin-left: 11px;
  }
  .margin-bottom {
    margin-bottom: 15px;
  }
  * > .validation-message {
    margin-left: 30px;
  }
}

.horizontal-rule {
  margin: 0px 24px;
}

.space-between-contact-payment {
  height: 24px;
}

.last-section {
  padding-top: 20px;
  margin: 0px 24px;
}
</style>
