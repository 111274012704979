<template>
  <Form ref="configurationObserver" tag="form" @submit.prevent="storeValidationState">
    <div>
      <!-- Basic insurance -->
      <div v-if="selectedContract && step === 0">
        <!-- <BasicInsuranceOverlay v-model="showBasicInsuranceOverlay" /> -->
        <BasicInsuranceOverlay
          :model-value="showBasicInsuranceOverlay"
          @update:model-value="showBasicInsuranceOverlay = $event"
        />

        <!-- <HospitalsOverlay v-model="hospitalOverlayProductComponentCode" /> -->
        <HospitalsOverlay
          :model-value="hospitalOverlayProductComponentCode"
          @update:model-value="hospitalOverlayProductComponentCode = $event"
        />

        <HealthcareProvidersOverlay v-model="healthcareProviderOverlayProductComponentCode" />

        <BasicInsuranceIntroduction @show-basic-insurance-overlay="showBasicInsuranceOverlay = true" />
        <InsuranceGroup
          :package-types="packageTypeByStep[0]"
          :step="0"
          @open-hospital-overlay="(productCode) => openHospitalOverlay(productCode)"
          @open-healthcare-provider-overlay="(productCode) => openHealthcareProviderOverlay(productCode)"
        >
          <template #disclaimer>
            <BasicInsuranceProductDisclaimer />
          </template>
        </InsuranceGroup>
      </div>

      <!-- Extra Additional insurances -->
      <div v-else-if="extraAdditionalInsuranceAvailable && step === 1">
        <Container :background-alt="true">
          <H1 id="title">
            {{ $tExistsOrDefault('calculatePremium.extraAdditionalInsurance.title') }}
          </H1>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.extraAdditionalInsurance.intro') }}
          </PlainText>
        </Container>
        <InsuranceGroup :package-types="packageTypeByStep[1]" :step="1" />
      </div>

      <!-- Additional insurance -->
      <div v-else-if="additionalInsuranceAvailable && step === 2">
        <Container :background-alt="true">
          <H1 id="title">
            {{ $tExistsOrDefault('calculatePremium.additionalInsurance.title') }}
          </H1>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.additionalInsurance.intro') }}
          </PlainText>
        </Container>
        <InsuranceGroup :package-types="packageTypeByStep[2]" :step="2" />
      </div>

      <!-- Dental insurance -->
      <div v-else-if="dentalInsuranceAvailable && step === 3">
        <Container :background-alt="true">
          <H1 id="title">
            {{ $tExistsOrDefault('calculatePremium.dentalInsurance.title') }}
          </H1>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.dentalInsurance.intro') }}
          </PlainText>
        </Container>
        <InsuranceGroup :package-types="packageTypeByStep[3]" :step="3" />
      </div>

      <div v-else-if="step === 4">
        <Container>
          <PreviewOverview @complete-application="next" />
          <div class="button-group-previous-next" v-if="$screen.gt.xs">
            <ButtonPrevious @click="previous" />
            <Button layout-type="action" @click="next">
              {{ $tExistsOrDefault('calculatePremium.previewOverview.buttonCompleteInsuranceRequest') }}
            </Button>
          </div>
        </Container>
        <PreviewOverviewMobileButtons v-if="$screen.xs" @back="previous" @complete-application="next" />
      </div>

      <!-- Medical selection  -->
      <MedicalSelectionOverlay v-model="showMedicalSelectionOverlay" @accepted-terms="next" />

      <!-- Own risk -->
      <Container v-if="step === 0">
        <OwnRiskGroup :product-list="productList" :has-selected-basic-insurance="basicInsuranceSelected" />
      </Container>
    </div>
    <Container class="pb-5" v-if="step !== 4">
      <div class="button-group-previous-next">
        <ButtonPrevious @click="previous" />

        <ExtraInformationContainer
          :text="isCurrentStepValid.message ? isCurrentStepValid.message : ''"
          :title="isCurrentStepValid.title ? isCurrentStepValid.title : ''"
          :should-show-information="!isCurrentStepValid.valid"
        >
          <ButtonNext
            :disabled="isBusinessRulePostLoading || (this.$screen.gt.sm && !isCurrentStepValid.valid)"
            @click="next"
          />
        </ExtraInformationContainer>
      </div>
    </Container>
  </Form>
</template>

<script>
import { mapGetters } from 'vuex';

import InsuranceGroup from '@/components/viewelements/configuration/InsuranceGroup.vue';
import OwnRiskGroup from '@/components/viewelements/configuration/OwnRiskGroup.vue';
import PreviewOverviewMobileButtons from '@/components/viewelements/configuration/previewOverview/PreviewOverviewMobileButtons.vue';
import BasicInsuranceProductDisclaimer from '@/components/viewelements/configuration/basicInsurance/BasicInsuranceProductDisclaimer.vue';
import HospitalsOverlay from '@/components/viewelements/configuration/basicInsurance/overlays/hospitalOverlay/HospitalsOverlay.vue';
import HealthcareProvidersOverlay from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProvidersOverlay.vue';
import BasicInsuranceIntroduction from '@/components/viewelements/configuration/basicInsurance/BasicInsuranceIntroduction.vue';
import BasicInsuranceOverlay from '@/components/viewelements/configuration/basicInsurance/BasicInsuranceOverlay.vue';
import PreviewOverview from '@/components/viewelements/configuration/previewOverview/PreviewOverview.vue';

import ExtraInformationContainer from '@/components/viewelements/configuration/ExtraInformationContainer.vue';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';

import Modal from '@/constants/modal';
import BusinessRule from '@/constants/business-rule';
import Contracts from '@/constants/contracts';
import Revisit from '@/constants/revisit';
import Validation from '@/constants/validation';
import ConfigurationHelper from '@/helpers/configuration';
import { Form } from 'vee-validate';

import MedicalSelectionOverlay from '@/components/viewelements/configuration/medicalSelection/MedicalSelectionOverlay.vue';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';

import Container from '@/components/shared/Container.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

import Button from '@/components/shared/button/Button.vue';
import H1 from '@/components/shared/typography/H1.vue';

export default {
  name: 'Configuration',
  props: {
    activationStep: Number,
    openMedicalSelectionOverlay: Boolean,
  },
  components: {
    Form,
    InsuranceGroup,
    OwnRiskGroup,
    ExtraInformationContainer,
    MedicalSelectionOverlay,
    BasicInsuranceOverlay,
    BasicInsuranceIntroduction,
    BasicInsuranceProductDisclaimer,
    HospitalsOverlay,
    HealthcareProvidersOverlay,
    PreviewOverview,
    PreviewOverviewMobileButtons,
    ButtonPrevious,
    ButtonNext,
    Button,
    Container,
    PlainText,
    H1,
  },
  data() {
    return {
      step: this.activationStep ? this.activationStep : 0,
      packageTypeByStep: {
        0: ['MB'],
        1: ['XA', 'AE'],
        2: ['AP'],
        3: ['TA'],
      },
      showBasicInsuranceOverlay: false,
      showMedicalSelectionOverlay: false,
      hospitalOverlayProductComponentCode: null,
      healthcareProviderOverlayProductComponentCode: null,
    };
  },
  computed: {
    ...mapGetters([
      'productList',
      'agreement',
      'selectedContract',
      'selectedContractCode',
      'dentalInsuranceAvailable',
      'additionalInsuranceAvailable',
      'extraAdditionalInsuranceAvailable',
      'isBusinessRulePostLoading',
      'startDate',
      'getDateOfBirth',
    ]),
    showHospitalOverlay: {
      get() {
        return this.hospitalOverlayProductComponentCode != null;
      },
      set(value) {
        if (!value) {
          this.hospitalOverlayProductComponentCode = null;
        }
      },
    },
    displayChildTip() {
      return [0, 1, 2].includes(this.step);
    },
    hasChildYoungerThan18() {
      let isUnder18 = false;
      this.agreement.insuredPersons.forEach((item) => {
        if (!ConfigurationHelper.isAdult(this.startDate, this.getDateOfBirth(item.id))) {
          isUnder18 = true;
        }
      });
      return isUnder18;
    },
    isPagePosted() {
      return this.$store.getters.pagePosted.configuration;
    },
    hideGroupLabel() {
      return this.$tExistsOrDefault('calculatePremium.general.close');
    },
    showGroupLabel() {
      return this.$tExistsOrDefault('calculatePremium.general.viewOrEditInsurance');
    },
    valid() {
      return this.$refs.configurationObserver.validate();
    },
    basicInsuranceSelected() {
      return ConfigurationHelper.validateInsurance(this.agreement.insuredPersons, 'MB');
    },
    basicInsuranceValid() {
      return {
        valid: this.basicInsuranceSelected,
        message: 'calculatePremium.basicInsurance.nextDisabledModal.text',
        title: 'calculatePremium.basicInsurance.nextDisabledModal.title',
      };
    },
    additionalInsuranceValid() {
      return {
        valid: this.additionalInsuranceAvailable
          ? ConfigurationHelper.validateInsurance(this.agreement.insuredPersons, 'AP')
          : true,
        message: 'landing.premium.nextErrorMessage',
        title: 'landing.premium.nextErrorTitle',
      };
    },
    dentalInsuranceValid() {
      return {
        // only validate insured persons > 18 years old
        valid: this.dentalInsuranceAvailable
          ? ConfigurationHelper.validateInsurance(
              ConfigurationHelper.getAdultInsureds(this.startDate, this.agreement.insuredPersons),
              'TA',
            )
          : true,
        message: 'landing.premium.nextErrorMessage',
        title: 'landing.premium.nextErrorTitle',
      };
    },
    language() {
      return this.$store.getters.businessRule.language;
    },
    isCurrentStepValid() {
      switch (this.step) {
        case 0:
          return this.basicInsuranceValid;
        case 1:
          return { valid: true };
        case 2:
          return this.additionalInsuranceValid;
        case 3:
          return this.dentalInsuranceValid;
        case 4:
          return { valid: true };
        default:
          return { valid: false };
      }
    },
    hasMedicalSelection() {
      return ConfigurationHelper.hasMedicalSelection(this.$store.getters.businessRule.agreementModel);
    },
    currentProductComponentCodes() {
      const test = this.productList
        .filter((x) => x.packageType === 'MB')[0]
        .products.map((x) => Number(x.productComponentCode));
      return test;
    },
  },
  methods: {
    resetStorage() {
      window.localStorage.clear();
      location.reload();
    },
    validateBusinessRule() {
      this.$store.dispatch(BusinessRule.Actions.POST);
    },
    storeValidationState() {
      this.$nextTick(() => {
        var el = this.$el.parentElement.getElementsByClassName('error')[0];
        if (el) {
          el.scrollIntoView();
        }
      });

      let valid =
        this.basicInsuranceValid.valid && this.additionalInsuranceValid.valid && this.dentalInsuranceValid.valid;
      this.$store.dispatch(Validation.Actions.SET_CONFIGURATION, valid);
    },
    next() {
      if (!this.isCurrentStepValid.valid) return;
      const insuredPersons = this.$store.getters.businessRule.agreementModel.insuredPersons;
      const insuredPersonsWithMedicalSelection = insuredPersons.filter((i) =>
        i.chosenInsurances.some((c) => c.hasMedicalSelection),
      );

      const doesEveryInsuredPersonHaveMedicalSelectionFormFilled = insuredPersonsWithMedicalSelection.every((i) =>
        ConfigurationHelper.hasFilledMedicalSelection(i.medicalSelection),
      );

      if (this.step === 3 && this.hasMedicalSelection && !doesEveryInsuredPersonHaveMedicalSelectionFormFilled) {
        this.shouldShowMedicalSelectionOverlay();
        return;
      }
      this.step++;
      if (this.step === 1 && !this.extraAdditionalInsuranceAvailable) this.step++;
      if (this.step === 2 && !this.additionalInsuranceAvailable) this.step++;
      if (this.step === 3 && !this.dentalInsuranceAvailable) this.step++;

      if (this.step > 4) {
        this.step = 4;
        this.nextPage();
      } else {
        this.$router.replace({
          name: 'configuration',
          params: {
            activationStep: this.step,
            lang: this.$i18n.locale,
            contractCode: this.$store.getters.selectedContractCode,
          },
        });
      }
    },
    previous() {
      this.step--;
      if (this.step === 3 && !this.dentalInsuranceAvailable) this.step--;
      if (this.step === 2 && !this.additionalInsuranceAvailable) this.step--;
      if (this.step === 1 && !this.extraAdditionalInsuranceAvailable) this.step--;
      if (this.step < 0) {
        this.step = 0;
        this.$router.push({
          name: 'insured',
          params: {
            contractCode: this.selectedContractCode,
            lang: this.$i18n.locale,
          },
        });
      } else {
        this.$router.replace({
          name: 'configuration',
          params: {
            activationStep: this.step,
            lang: this.$i18n.locale,
            contractCode: this.$store.getters.selectedContractCode,
          },
        });
      }
    },
    nextPage() {
      let businessRuleModel = this.$store.getters.businessRule;
      let goToNextPage = ConfigurationHelper.validateFinalAgreementModel(
        businessRuleModel.agreementModel,
        this.$store.getters.dentalInsuranceAvailable,
        this.$store.getters.additionalInsuranceAvailable,
      );
      if (goToNextPage) {
        let route = 'personal_data';
        this.$store.commit(BusinessRule.Mutations.SET_SHOULDLOCKCONTRACT, {
          value: true,
        });
        this.$store.dispatch(BusinessRule.Actions.POST);
        this.$router.push({
          path: `/${this.$i18n.locale}/${this.$store.getters.selectedContractCode}/${route}`,
        });
      } else {
        this.$store.dispatch(Validation.Actions.POSTED_CONFIGURATION, true);
        this.$store.dispatch(Modal.Actions.OPEN, {
          modalData: ModalDataFactory.create('landing.premium.errorTitle', 'landing.premium.errorMessage'),
        });
      }
    },
    shouldShowMedicalSelectionOverlay() {
      if (!this.hasMedicalSelection) return;
      this.showMedicalSelectionOverlay = true;
    },
    openHospitalOverlay(productCode) {
      this.hospitalOverlayProductComponentCode = productCode;
    },
    openHealthcareProviderOverlay(productCode) {
      this.healthcareProviderOverlayProductComponentCode = productCode;
    },
    getCurrentRevisitStep() {
      if (this.step === 0) {
        return Revisit.RevisitStepEnum.basicInsurance;
      } else if (this.step === 1) {
        return Revisit.RevisitStepEnum.extraAdditionalInsurance;
      } else if (this.step === 2) {
        return Revisit.RevisitStepEnum.additionalInsurance;
      } else if (this.step === 3) {
        return Revisit.RevisitStepEnum.dentalInsurance;
      } else if (this.step === 4) {
        return Revisit.RevisitStepEnum.paymentTerm;
      } else {
        throw new Error('new step not configured...');
      }
    },
    setVisitedPackageTypes() {
      for (let i = 0; i <= this.step; i++) {
        this.packageTypeByStep[i]?.forEach((p) =>
          this.$store.dispatch(BusinessRule.Actions.ADD_VISITED_PACKAGE_TYPE, p),
        );
      }
    },
    saveAgreementToStore() {
      this.$store.dispatch(Revisit.Actions.SAVE, {
        agreementModel: this.agreement,
        step: this.getCurrentRevisitStep(),
      });
    },
  },
  watch: {
    '$i18n.locale'() {
      this.validateBusinessRule();
    },
    step: {
      immediate: false,
      handler(val) {
        this.setVisitedPackageTypes();

        //Last step then save store again so revisit navigates to here.
        if (val === 4) {
          this.saveAgreementToStore();
        }
      },
    },
    agreement() {
      this.saveAgreementToStore();
    },
  },
  async mounted() {
    this.setVisitedPackageTypes();
    let valid = true;

    this.agreement.insuredPersons.forEach((person) => {
      if (!person.dateOfBirth) {
        valid = false;
      }
    });

    if (!valid) {
      this.$router.push({
        name: 'home',
        params: {
          lang: this.$i18n.locale,
        },
      });
    } else {
      this.$store.commit(BusinessRule.Mutations.SET_SHOULDLOCKCONTRACT, {
        value: false,
      });

      this.$store.dispatch(Contracts.Actions.LOAD_REIMBURSEMENT_CATEGORIES);
      this.validateBusinessRule();
      if (this.openMedicalSelectionOverlay) {
        this.shouldShowMedicalSelectionOverlay();
      }
      //this.$store.dispatch(BusinessRule.Actions.LOAD_PAYMENT_INFO_BY_SUBCONTRACT);
    }
  },
  beforeUnmount() {
    this.$store.dispatch(Modal.Actions.CLOSE);
  },
};
</script>

<style lang="scss" scoped>
#title {
  margin-bottom: 12px;
}
</style>
