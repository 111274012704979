<template>
  <h3>
    <slot />
  </h3>
</template>
<script>
export default {
  name: 'H3',
};
</script>
<style lang="scss" scoped>
h3 {
  font-size: 20px;
  font-family: var(--text-font-semibold);
  color: var(--color-grey-900);
  margin-bottom: 0;
}
</style>
