import { store } from '@/store';
import { api } from '@/utils/api';

import Settings from '@/constants/settings';

const isLoading = function (value) {
  store.commit(Settings.Mutations.SET_CANCELATION_LOADING, value);
};

export default {
  GetCancelationServiceActive() {
    isLoading(true);
    const endpoint = Settings.Endpoints.cancelationService();

    return api.get(endpoint).finally(() => isLoading(false));
  },
};
