<template>
  <div v-if="logoSrc && imageFound">
    <img class="right-logo" :src="logoSrc" @error="imageFound = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CollectivityLogo',
  data() {
    return {
      imageFound: true,
    };
  },
  props: {
    contractCode: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(['labelSettings']),
    logoSrc() {
      let src = '';
      if (this.contractCode && this.labelSettings.mainContractCodes.includes(this.contractCode)) {
        src = `/images/${this.contractCode}.png`;
      } else if (this.$store.state.Logo.logo) {
        src = 'data:image/png;base64, ' + this.$store.state.Logo.logo;
      }
      return src;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-logo {
  overflow: hidden;
  height: auto;
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
