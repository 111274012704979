<template>
  <div class="d-flex">
    <div v-if="!isHeaderColumn" class="pr-1">
      <img v-if="isNoReimbursement" src="/images/icons/unchecked.svg" />
      <img v-else src="/images/icons/check.svg" />
    </div>
    <div
      class="reimbursement"
      :class="{
        'grey-text': isNoReimbursement,
        'ml-auto text-right pt-1': isHeaderColumn,
      }"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: 'CompareReimbursementRowDesktop',
  props: {
    text: {
      type: String,
      required: true,
    },
    isHeaderColumn: Boolean,
  },
  computed: {
    isNoReimbursement() {
      return this.text === 'Geen vergoeding' || this.text === 'No reimbursement';
    },
  },
};
</script>

<style lang="scss" scoped>
.reimbursement {
  font-size: 16px;
  text-align: start;
  &.grey-text {
    color: #0031407f !important;
  }
}
</style>
