<template>
  <section class="header-block-container" :class="{ 'header-block-mobile': $screen.lt.sm }">
    <div>
      <H3>{{ title }}</H3>
      <PlainText>
        {{ getGender }}, {{ insuredPerson.nationality }},
        <PlainText class="d-inline" type="secondary">
          {{ $tExistsOrDefault('overview.socialSecurityNumber') }}: </PlainText
        >{{ insuredPerson.socialSecurityNumber }}
      </PlainText>
      <PlainText v-if="insuredPerson.email">
        <PlainText class="d-inline" type="secondary"> {{ $tExistsOrDefault('overview.email') }}: </PlainText
        >{{ insuredPerson.email }}
      </PlainText>
      <PlainText v-if="insuredPerson.employeeNumber">
        <PlainText class="d-inline" type="secondary">
          {{ $tExistsOrDefault('personal.form.employeeNumber.label') }}: </PlainText
        >{{ insuredPerson.employeeNumber }}
      </PlainText>
    </div>

    <ButtonPrevious @click="onEdit">
      {{ $tExistsOrDefault('overview.button.changePersonalDetails') }}
    </ButtonPrevious>
  </section>
</template>

<script>
import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';

export default {
  emits: ['btnClick'],
  name: 'OverviewBlockHeader',
  props: {
    title: String,
    insuredPerson: Object,
  },
  components: {
    H3,
    ButtonPrevious,
    PlainText,
  },
  methods: {
    onEdit() {
      this.$emit('btnClick');
    },
  },
  computed: {
    getGender() {
      return this.insuredPerson.gender === 'M'
        ? this.$tExistsOrDefault('overview.men')
        : this.$tExistsOrDefault('overview.woman');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-block-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header-block-mobile {
  flex-direction: column;
  gap: 20px;
}
</style>
