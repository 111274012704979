<template>
  <Card id="wrapper">
    <div id="header">
      <p>3.</p>
      <svg width="42" height="44" viewBox="0 0 42 44" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path stroke="#CCC" fill="#F5F5F5" d="M.5 16.5h7v21h-7z" />
          <path stroke="#CCC" fill="#FFCE34" d="M.5 27.5h7v10h-7z" />
          <path stroke="#CCC" fill="#F5F5F5" d="M12.5 16.5h7v21h-7z" />
          <path stroke="#CCC" fill="#FFCE34" d="M12.5 22.5h7v15h-7z" />
          <path stroke="#CCC" fill="#F5F5F5" d="M24.5 16.5h7v21h-7z" />
          <path stroke="#CCC" fill="#FFCE34" d="M24.5 16.5h7v21h-7z" />
          <path d="M0 0h42v44H0z" />
        </g>
      </svg>
    </div>
    <div id="content">
      <H2>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card3.title') }}
      </H2>
      <ul>
        <li v-for="item in $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card3.items')" :key="item">
          <BulletPoint />
          <PlainText display="inline">
            {{ item }}
          </PlainText>
        </li>
      </ul>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/shared/card/Card.vue';
import H2 from '@/components/shared/typography/H2.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import BulletPoint from '@/components/shared/icons/BulletPoint.vue';

export default {
  name: 'ExplanationPt2',
  components: {
    H2,
    Card,
    PlainText,
    BulletPoint,
  },
  emits: ['handleButtonClick'],
};
</script>

<style lang="scss" scoped>
#wrapper {
  position: relative;
  margin-top: 26px;
  width: 330px;
  min-height: 258px;
}
#header {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
  top: -26px;
  margin-bottom: 18px;
  p {
    margin-bottom: 0;
    color: var(--color-primary);
    font-size: 36px;
    font-family: var(--text-font);
  }
}
#content {
  margin-top: 8px;
  H2 {
    margin-bottom: 14px;
  }
}

ul {
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: var(--color-primary-800);
    margin-bottom: 4px;
    :first-child {
      margin-top: 8px;
      flex-shrink: 0;
    }
  }
}
</style>
