<template>
  <div class="d-flex bullets">
    <HorizontalScrollBullet
      @click="$emit('clickBullet', currentElementIndex(index))"
      v-for="(_, index) in numberOfElementsToShow"
      :key="index"
      :size="smallOrBig(currentElementIndex(index))"
      :is-active="currentElementIndex(index) === currentSelectedIndex"
    />
  </div>
</template>

<script>
import HorizontalScrollBullet from '@/components/shared/horizontalScroll/new/HorizontalScrollBullet.vue';

export default {
  name: 'HorizontalScrollBullets',
  emits: ['clickBullet'],
  components: {
    HorizontalScrollBullet,
  },
  props: {
    numberOfElements: {
      type: Number,
      required: true,
    },
    currentSelectedIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      maxVisible: 5,
    };
  },
  methods: {
    currentElementIndex(currentPlaceIndex) {
      return this.arrayCurrentElemenIndexes[currentPlaceIndex];
    },
    isFirstVisibleOvalNotFirstElement(index) {
      return index - 1 >= 0 && this.arrayCurrentElemenIndexes[0] === index;
    },
    isLastVisibleOvalNotLastElement(index) {
      return (
        this.numberOfElements - 1 >= index + 1 &&
        this.arrayCurrentElemenIndexes[this.arrayCurrentElemenIndexes.length - 1] === index
      );
    },
    smallOrBig(index) {
      if (this.isFirstVisibleOvalNotFirstElement(index) || this.isLastVisibleOvalNotLastElement(index)) {
        return 0; //small
      } else {
        return 1; //big
      }
    },
  },
  computed: {
    numberOfElementsToShow() {
      return Math.min(this.numberOfElements, this.maxVisible);
    },
    arrayCurrentElemenIndexes() {
      let startIndex = this.currentSelectedIndex - Math.floor(this.maxVisible / 2);

      if (startIndex + this.maxVisible - 1 >= this.numberOfElements) {
        startIndex = this.numberOfElements - this.maxVisible;
      }

      if (startIndex < 0) {
        startIndex = 0;
      }

      return Array.from({ length: this.numberOfElementsToShow }, (_, i) => i + startIndex);
    },
  },
};
</script>

<style lang="scss">
.bullets {
  align-items: center;
  gap: 4.5px;
  justify-content: center;
  transition: width 0.5s ease-in-out;
}
</style>
