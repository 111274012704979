import Config from '@/constants/config';

const Actions = {
  LOAD_ALL: 'a.contracts.load-all',
  LOAD_BY_LANDINGSNAME: 'a.contracts-load-by-landingsname',
  SEARCH: 'a.contracts.search',
  LOAD_BY_SELECTED_CONTRACT: 'a.contracts.load-by-selected-contract',
  CLEAR_SEARCH: 'a.contracts.clear-search',
  LOAD_SUBCONTRACTS_BY_CONTRACTCODE: 'a.contracts.load-subcontracts-by-contractcode',
  LOAD_CONTRACT: 'a.contracts.load-contract',
  LOAD_EJW_INFO: 'a.contracts.load-ejw-info',
  LOAD_REIMBURSEMENT_CATEGORIES: 'a.contracts.load-reimbursement-categories',
  SET_COLLECTIVITY_CHOICE: 'a.contracts.set-collectivity-choice',
};

const Mutations = {
  SET: 'm.contracts.set',
  SET_SELECTED: 'm.contracts.set-selected',
  SET_SELECTED_ID: 'm.contracts.set-selected-id',
  CLEAR: 'm.contracts.clear',
  CLEAR_SEARCH: 'm.contracts.clear-search',
  SET_LOADING: 'm.contract.set-is-loading',
  SET_SUBCONTRACTS: 'm.contract.set-subcontracts',
  SET_CONTRACT: 'm.contract.set-contract',
  SET_EJW_INFO: 'm.contract.set-ejw-info',
  SET_REIMBURSEMENTS: 'm.contract.set-reimbursements',
  SET_COLLECTIVITY_CHOICE: 'm.contracts.set-collectivity-choice',
};

const Endpoints = {
  LoadByLandingsname: () => `${Config.API.BASE_URL}/api/Contract/bylandingsname`,
  Search: () => `${Config.API.BASE_URL}/api/Contract/search`,
  LoadSubcontractsByContractCode: (contractCode, lastPolicyYear) =>
    `${Config.API.BASE_URL}/api/contract/${contractCode}/subcontracts?lastPolicyYear=${lastPolicyYear}`,
  LoadContract: (contractCode, lastPolicyYear, label) =>
    `${Config.API.BASE_URL}/api/Contract/${contractCode}/minimal?lastPolicyYear=${lastPolicyYear}&label=${label}`,
  LoadEjwInfo: (contractcode) => `${Config.API.BASE_URL}/api/contract/${contractcode}/isEjw`,
  LoadReimbursementCategories: () => `${Config.API.BASE_URL}/api/Reimbursement/categories`,
};

export default {
  Actions,
  Mutations,
  Endpoints,
};
