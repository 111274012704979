import { filter } from 'lodash-es';

export default class {
  static verifyBSN(bsn, existingSsns) {
    if (!bsn || bsn.length < 8 || bsn.length > 9) {
      return 'personal.form.socialSecurityNumber.message';
    }
    if (!bsn.match(/^\d{8,9}$/)) {
      return 'personal.form.socialSecurityNumber.message';
    }

    var usersWithSameBsn = filter(existingSsns, (existingSsn) => existingSsn === bsn);

    if (usersWithSameBsn.length > 1) {
      return 'personal.form.socialSecurityNumber.customMessage';
    }

    var j = bsn.length;
    var pos = 0;
    var result = 0;
    for (let i = j; i > 0; i--) {
      result += i !== 1 ? bsn.charAt(pos) * i : bsn.charAt(pos) * i * -1;
      pos++;
    }
    if (result === 0) {
      return 'personal.form.socialSecurityNumber.message';
    }
    return result % 11 === 0 ? '' : 'personal.form.socialSecurityNumber.message';
  }
}
