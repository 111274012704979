import { store } from '@/store';
import { api } from '@/utils/api';

import Logos from '@/constants/logos';

export default {
  LoadByContract(contractCode) {
    store.commit(Logos.Mutations.SET_LOADING, true);

    const endpoint = Logos.Endpoints.LoadByContract();
    const params = { key: `contract.${contractCode}` };

    return api.get(endpoint, { params }).finally(() => store.commit(Logos.Mutations.SET_LOADING, false));
  },
};
