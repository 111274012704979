<template>
  <div class="border" @click="navigate">
    <img :src="imageSource" />
  </div>
</template>

<script>
export default {
  name: 'ContractCard',
  emits: ['selectedContractCode'],
  props: {
    imageSource: String,
    contractCode: Number,
    url: String,
  },
  methods: {
    navigate() {
      if (this.contractCode) {
        this.$emit('selectedContractCode', this.contractCode);
      } else if (this.url) {
        window.location.href = this.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 70px;
  cursor: pointer;
  background: var(--color-white);
}

.border {
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
}

img {
  padding: 0 0.5em;
  max-height: 50px;
  max-width: 145px;
}
</style>
