<template>
  <CloseButtonStickyHeaderSlot @close="onClose" :padded="false">
    <H1>{{ title }}</H1>

    <div class="table-scroll">
      <table :style="getWidthOfTable()">
        <thead>
          <tr>
            <CompareMobileInsuranceHeader
              v-if="!compareProductExclusions.includes(basicReimbursementProduct.productComponentCode)"
              :type-of-insurance="basicReimbursementProduct.packageTypeForWebApplications"
              :product="basicReimbursementProduct"
              :amount-of-cards-compared="amountOfCardsCompared"
              @exclude-from-comparison="(id) => compareProductExclusions.push(id)"
            />
            <CompareMobileInsuranceHeader
              v-for="(product, i) in filteredProducts"
              :key="i"
              :type-of-insurance="typeOfInsurance"
              :product="product"
              :amount-of-cards-compared="amountOfCardsCompared"
              @exclude-from-comparison="(id) => compareProductExclusions.push(id)"
            />
          </tr>
        </thead>
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <template v-for="reimbursement in filteredReimbursementTypes" :key="`header-${reimbursement.categoryId}`">
          <tr>
            <th colspan="2" class="reimbursement-category pl-3" :style="getWidthOfHeadersAndDataCells()">
              {{ reimbursement.categoryName }}
            </th>
          </tr>
          <tr>
            <td
              v-if="!compareProductExclusions.includes(basicReimbursementProduct.productComponentCode)"
              class="pl-3"
              :style="getWidthOfHeadersAndDataCells()"
            >
              <span :class="isNoReimbursement(basicReimbursementProduct, reimbursement.categoryId) ? 'text-grey' : ''">
                {{ getProductReimbursementValue(basicReimbursementProduct, reimbursement.categoryId) }}
              </span>
            </td>
            <td
              v-for="product in filteredProducts"
              class="pl-3"
              :style="getWidthOfHeadersAndDataCells()"
              :key="product.productComponentCode"
            >
              <span :class="isNoReimbursement(product, reimbursement.categoryId) ? 'text-grey' : ''">
                {{ getProductReimbursementValue(product, reimbursement.categoryId) }}
              </span>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </CloseButtonStickyHeaderSlot>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderBy } from 'lodash-es';
import CompareMobileInsuranceHeader from '@/components/viewelements/configuration/insuranceCard/compareOverlay/compareMobile/CompareMobileInsuranceHeader.vue';
import CloseButtonStickyHeaderSlot from '@/components/shared/button/CloseButtonStickyHeaderSlot.vue';
import H1 from '@/components/shared/typography/H1.vue';

export default {
  name: 'CompareMobile',
  components: {
    CompareMobileInsuranceHeader,
    CloseButtonStickyHeaderSlot,
    H1,
  },
  emits: ['closeOverlay'],
  data() {
    return {
      compareProductExclusions: [],
      dentalReimbursementIds: [8, 9, 10],
    };
  },
  props: {
    typeOfInsurance: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['reimbursements', 'productList']),
    amountOfCardsCompared() {
      return this.compareProductExclusions.includes(this.basicReimbursementProduct.productComponentCode)
        ? this.filteredProducts.length
        : this.filteredProducts.length + 1;
    },
    title() {
      if (this.typeOfInsurance === 'XA' || this.typeOfInsurance === 'AE')
        return this.$tExistsOrDefault('calculatePremium.extraAdditionalInsurance.compareExtraAdditionalInsurance');
      if (this.typeOfInsurance === 'AP')
        return this.$tExistsOrDefault('calculatePremium.additionalInsurance.compareAdditionalInsurance');
      if (this.typeOfInsurance === 'TA')
        return this.$tExistsOrDefault('calculatePremium.dentalInsurance.compareDentalInsurance');
      return '';
    },
    filteredProducts() {
      return orderBy(
        this.insurancesToCompare.filter(
          (x) => !!x.productComponentCode && !this.compareProductExclusions.includes(x.productComponentCode),
        ),
        ['key'],
      );
    },
    filteredReimbursementTypes() {
      let filteredReimbursementTypes = this.reimbursements.filter(
        (item) => item.language === (this.$i18n.locale ? this.$i18n.locale.toUpperCase() : 'NL'),
      );

      if (this.typeOfInsurance !== 'TA') {
        filteredReimbursementTypes = filteredReimbursementTypes.filter(
          (frt) => !this.dentalReimbursementIds.includes(frt.categoryId),
        );
      }
      if (this.typeOfInsurance === 'TA') {
        filteredReimbursementTypes = filteredReimbursementTypes.filter((frt) =>
          this.dentalReimbursementIds.includes(frt.categoryId),
        );
      }
      if (filteredReimbursementTypes.length > 0) {
        return orderBy(filteredReimbursementTypes, ['categoryId']);
      }
      return [];
    },
    basicReimbursementProduct() {
      const basicReimbursements = this.reimbursements
        .filter((r) => r.language.toUpperCase() === this.$i18n.locale.toUpperCase())
        .map((r) => {
          return {
            key: r.categoryId,
            value: r.basicReimbursementText,
          };
        });

      return {
        title: this.$tExistsOrDefault('calculatePremium.basicInsurance.default.title'),
        productComponentCode: 'MB',
        packageTypeForWebApplications: 'MB',
        reimbursementTypes: basicReimbursements,
      };
    },
    insurancesToCompare() {
      if (this.typeOfInsurance === 'XA' || this.typeOfInsurance === 'AE') return this.getExtraAdditionalInsurances();
      if (this.typeOfInsurance === 'AP') return this.getAdditionalInsurances();
      if (this.typeOfInsurance === 'TA') return this.getDentalInsurances();
      return [];
    },
  },
  methods: {
    getWidthOfHeadersAndDataCells() {
      const calcWidth = 100 / this.amountOfCardsCompared;
      return `width: ${calcWidth}%;`;
    },
    getWidthOfTable() {
      const calcWidth = 166.66 * this.amountOfCardsCompared;
      return `min-width: ${calcWidth}px;`;
    },
    getProductReimbursementValue(product, key) {
      if (!product) return;

      const reimbursementOfProduct = product.reimbursementTypes.find((r) => r.key === key);
      if (!reimbursementOfProduct || !reimbursementOfProduct.value) {
        return this.$tExistsOrDefault('calculatePremium.additionalInsurance.noCompensation');
      }
      return reimbursementOfProduct.value;
    },
    isNoReimbursement(product, key) {
      const reimbursementOfProduct = product.reimbursementTypes.find((r) => r.key === key);
      return !reimbursementOfProduct || !reimbursementOfProduct.value ? true : false;
    },
    onClose() {
      this.compareProductExclusions = [];
      this.$emit('closeOverlay', false);
    },
    getAdditionalInsurances() {
      if (this.productList != null) {
        let productGroups = this.productList.filter(
          (group) => group.cardGroupId.includes('AP') && group.products.length > 0,
        );
        let additionalInsurancesList = [];

        let packSetNr = productGroups.map((x) => x.packageSet);
        let maxPackSetNr = 1;
        if (packSetNr.length > 1) {
          maxPackSetNr = productGroups.map((x) => x.packageSet).sort((a, b) => (a < b ? -1 : 1))[0];
        }

        let emptyProductComponent = null;
        productGroups.forEach((item) => {
          let productComponents = item.products;
          if (item.packageSet === maxPackSetNr) {
            emptyProductComponent = productComponents.find((pc) => pc.productComponentCode === null);
          }

          productComponents = productComponents.filter((pc) => pc.productComponentCode !== null);
          productComponents.forEach((product) => {
            additionalInsurancesList.push(product);
          });
        });

        if (emptyProductComponent != null) {
          additionalInsurancesList.push(emptyProductComponent);
        }

        return additionalInsurancesList;
      } else {
        return [];
      }
    },
    getDentalInsurances() {
      if (this.productList != null) {
        let filtered = this.productList.find((group) => group.packageType === 'TA');
        return filtered ? filtered.products : [];
      } else {
        return [];
      }
    },
    getExtraAdditionalInsurances() {
      if (this.productList != null) {
        let filtered = this.productList.filter((group) => group.packageType === 'XA' || group.packageType === 'AE');

        return filtered.length > 0
          ? filtered
              .map((x) => x.products)
              .reduce(function (a, b) {
                return a.concat(b);
              }, [])
          : [];
      } else {
        return [];
      }
    },
    beforeUnmount() {
      this.compareProductExclusions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-scroll {
  position: relative;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: calc(100vh - 180px);
}
.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  background: var(--color-white);
  vertical-align: top;
}
.table-scroll td:not(:last-child) {
  border-right-style: solid;
  border-right-color: white;
  border-right-width: 3px;
}
.table-scroll thead th {
  background: var(--color-white);
  color: var(--color-primary-900);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
thead th {
  z-index: 5;
}
tr > td {
  background: var(--color-grey-200) !important;
  color: var(--color-grey-900);
  font-size: 16px;
  text-align: left;
}
.reimbursement-category {
  color: var(--color-grey-900);
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
thead tr th {
  color: var(--color-primary);
  text-align: left;
}
tr > th:not(.reimbursement-category) {
  font-size: 19px !important;
}
.text-grey {
  color: rgba(0, 0, 0, 0.5);
}

H1 {
  margin-left: 10px;
}
</style>
