<template>
  <a role="button">
    <slot />
  </a>
</template>
<script>
export default {
  name: 'LinkText',
};
</script>
<style lang="scss" scoped>
a {
  font-size: 16px;
  font-family: var(--text-font);
  color: var(--color-primary-800) !important;
  margin-bottom: 0;
  line-height: 25px;
  text-decoration: underline !important;

  &:hover {
    cursor: pointer;
  }
}
</style>
