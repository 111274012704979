<template>
  <Card id="wrapper">
    <div id="header">
      <p>2.</p>
      <svg width="42" height="44" viewBox="0 0 42 44" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path stroke="#CCC" fill="#FFCE34" d="M.5 16.5h7v21h-7zM12.5 16.5h7v21h-7zM24.5 16.5h7v21h-7z" />
          <circle stroke="gray" stroke-width="2" fill-opacity=".437" fill="#BFBFBF" cx="20" cy="17" r="16" />
          <path stroke="gray" stroke-width="2" d="m29 29 12 14" />
          <path stroke="#CCC" fill="#FFCE34" d="M.5 16.5h7v21h-7zM12.5 16.5h7v21h-7zM24.5 16.5h7v21h-7z" />
          <circle stroke="gray" stroke-width="2" fill-opacity=".437" fill="#BFBFBF" cx="20" cy="17" r="16" />
          <path stroke="gray" stroke-width="2" d="m29 29 12 14" />
          <path d="M0 0h42v44H0z" />
        </g>
      </svg>
    </div>
    <div id="content">
      <H2>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card2.title') }}
      </H2>
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card2.text') }}
      </PlainText>
      <div class="button-wrapper">
        <Button @click="$emit('handleButtonClick')">
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card2.btnText') }}
        </Button>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/shared/card/Card.vue';
import H2 from '@/components/shared/typography/H2.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  name: 'ExplanationPt2',
  components: {
    H2,
    Card,
    PlainText,
    Button,
  },
  emits: ['handleButtonClick'],
};
</script>

<style lang="scss" scoped>
#wrapper {
  position: relative;
  margin-top: 26px;
  width: 330px;
  min-height: 258px;
}
#header {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
  top: -26px;
  margin-bottom: 18px;
  p {
    margin-bottom: 0;
    color: var(--color-primary);
    font-size: 36px;
    font-family: var(--text-font);
  }
}
#content {
  margin-top: 8px;
  H2 {
    margin-bottom: 14px;
  }
}
.button-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
</style>
