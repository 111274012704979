<template>
  <Container :background-alt="true">
    <H1 id="title">
      {{ $tExistsOrDefault('calculatePremium.basicInsurance.title') }}
    </H1>
    <H3 id="subtitle">
      {{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.title') }}
    </H3>
    <HorizontalScroll id="explanation-card-container" gap="15px" :max-width-procentage="75">
      <ExplanationPt1 class="part1" />
      <ExplanationPt2 @handle-button-click="$emit('showBasicInsuranceOverlay')" />
      <ExplanationPt3 class="part2" />
    </HorizontalScroll>
  </Container>
</template>

<script>
import ExplanationPt1 from '@/components/viewelements/configuration/basicInsurance/explanation/BasicInsuranceExplanationCard1.vue';
import ExplanationPt2 from '@/components/viewelements/configuration/basicInsurance/explanation/BasicInsuranceExplanationCard2.vue';
import ExplanationPt3 from '@/components/viewelements/configuration/basicInsurance/explanation/BasicInsuranceExplanationCard3.vue';
import HorizontalScroll from '@/components/shared/horizontalScroll/new/HorizontalScroll.vue';

import H1 from '@/components/shared/typography/H1.vue';
import H3 from '@/components/shared/typography/H3.vue';
import Container from '@/components/shared/Container.vue';

export default {
  name: 'BasicInsuranceIntroduction',
  emits: ['showBasicInsuranceOverlay', 'input'],
  components: {
    ExplanationPt1,
    ExplanationPt2,
    ExplanationPt3,
    H1,
    H3,
    HorizontalScroll,
    Container,
  },
  props: {
    value: Boolean,
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    onClose() {
      this.modelValue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#title {
  margin-bottom: 36px;
}
#subtitle {
  margin-bottom: 16px;
}
.part1 {
  margin-left: 5px;
}

.part2 {
  margin-right: 5px;
}
</style>
