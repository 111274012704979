import ProductComponentCodes from '@/constants/ProductComponentCodes';
import { app } from '@/main';

const $tExistsOrDefault = (value, parameters) => app.config.globalProperties.$tExistsOrDefault(value, parameters);

export default class Insurance {
  static basicInsuranceTitle(basicInsurance) {
    const allDefaultProductComponentCodes = Object.values(ProductComponentCodes);

    if (allDefaultProductComponentCodes.find((x) => x.toString() === basicInsurance.productComponentCode)) {
      return $tExistsOrDefault(
        `calculatePremium.previewOverview.basicInsuranceTitle.${basicInsurance.productComponentCode}`,
      );
    } else {
      return basicInsurance.title;
    }
  }
}
