<template>
  <div>
    <div v-if="contractedType === Contracted">
      <Compensated />
      <PlainText class="d-inline margin-left">{{
        $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.contracted', { year: year })
      }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li
              v-for="item in $tExistsOrDefault(
                'calculatePremium.basicInsurance.healthcareProviderOverlay.contractedTooltipText.items',
              )"
              :key="item"
            >
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
          </ul>
          <ul class="nested-list-style">
            <li
              v-for="extraItem in $tExistsOrDefault(
                'calculatePremium.basicInsurance.healthcareProviderOverlay.contractedTooltipText.extraItems',
              )"
              :key="extraItem"
              class="nested-list-items"
            >
              <BulletPoint />
              <PlainText class="d-inline">{{ extraItem }}</PlainText>
            </li>
          </ul>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="contractedType === NegotiatingPartiallyNotCompensated">
      <NotYetNotCompensated />
      <PlainText class="d-inline margin-left">{{
        $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingNotCompensated', {
          year: year,
        })
      }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li
              v-for="item in $tExistsOrDefault(
                'calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingPartiallyNotCompensatedTooltipText.items',
              )"
              :key="item"
            >
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
            <ul class="nested-list-style">
              <li
                v-for="extraItem in $tExistsOrDefault(
                  'calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingPartiallyNotCompensatedTooltipText.extraItems',
                )"
                :key="extraItem"
                class="nested-list-items"
              >
                <BulletPoint />
                <PlainText class="d-inline">{{ extraItem }}</PlainText>
              </li>
            </ul>
          </ul>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="contractedType === NegotiatingCompensated">
      <NotYetCompensated />
      <PlainText class="d-inline margin-left">{{
        $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingCompensated', {
          year: year,
        })
      }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li
              v-for="item in $tExistsOrDefault(
                'calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingCompensatedTooltipText.items',
              )"
              :key="item"
            >
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
            <ul class="nested-list-style">
              <li
                v-for="extraItem in $tExistsOrDefault(
                  'calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingCompensatedTooltipText.extraItems',
                )"
                :key="extraItem"
                class="nested-list-items"
              >
                <BulletPoint />
                <PlainText class="d-inline">{{ extraItem }}</PlainText>
              </li>
            </ul>
          </ul>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="contractedType === NotContracted">
      <NotCompensated />
      <PlainText class="d-inline margin-left">{{
        $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.notContracted', { year: year })
      }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li
              v-for="item in $tExistsOrDefault(
                'calculatePremium.basicInsurance.healthcareProviderOverlay.notContractedTooltipText.items',
              )"
              :key="item"
            >
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
            <ul class="nested-list-style">
              <li
                v-for="extraItem in $tExistsOrDefault(
                  'calculatePremium.basicInsurance.healthcareProviderOverlay.notContractedTooltipText.extraItems',
                )"
                :key="extraItem"
                class="nested-list-items"
              >
                <BulletPoint />
                <PlainText class="d-inline">{{ extraItem }}</PlainText>
              </li>
            </ul>
          </ul>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import ContractType from '@/constants/ContractType';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Compensated from '@/components/shared/icons/Compensated.vue';
import InformationIcon from '@/components/shared/icons/InformationIcon.vue';
import NotCompensated from '@/components/shared/icons/NotCompensated.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import BulletPoint from '@/components/shared/icons/BulletPoint.vue';
import NotYetCompensated from '@/components/shared/icons/NotYetNotCompensated.vue';
import NotYetNotCompensated from '@/components/shared/icons/NotYetCompensated.vue';

export default {
  name: 'HealthcareProviderContracted',
  components: {
    PlainText,
    Compensated,
    InformationIcon,
    NotCompensated,
    NotYetCompensated,
    NotYetNotCompensated,
    Tooltip,
    BulletPoint,
  },
  data() {
    return {
      Contracted: ContractType.Contracted,
      NegotiatingCompensated: ContractType.NegotiatingCompensated,
      NegotiatingPartiallyNotCompensated: ContractType.NegotiatingPartiallyNotCompensated,
      NotContracted: ContractType.NotContracted,
    };
  },
  props: {
    contractedType: null,
    year: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style scoped lang="scss">
.margin-left {
  margin-left: 7px;
}
#info-icon {
  color: var(--color-primary);
  height: 24px;
}
ul {
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: var(--color-primary-800);
    margin-bottom: 4px;
    :first-child {
      margin-top: 8px;
      flex-shrink: 0;
    }
    &.nested-list-items {
      gap: 20px;
    }
  }
}
ul .nested-list-style {
  padding-left: 20px;
}
</style>
