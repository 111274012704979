import Config from '@/constants/config';
import { mapGetters } from 'vuex';
import Environments from '@/constants/environments';

export default {
  computed: {
    ...mapGetters(['labelSettings']),
  },
  methods: {
    environment() {
      return Config.ENVIRONMENT;
    },
    loadGoogleSearchConsole() {
      if (this.environment() === Environments.Production) {
        const meta = document.createElement('meta');

        meta.async = true;
        meta.name = 'google-site-verification';
        meta.setAttribute('content', this.labelSettings.googleSearchConsole);

        document.head.appendChild(meta);
      }
    },
  },
  mounted() {
    this.loadGoogleSearchConsole();
  },
};
