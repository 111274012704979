<template>
  <Container class="insured-container" :background-alt="true">
    <Form v-slot="{ meta }" ref="formRef" @submit="storeValidationState">
      <div class="flex-wrapper">
        <div class="d-flex flex-column" v-if="selectedContract">
          <div>
            <ContractStartDate />
            <CalculatePremiumForm />
          </div>
        </div>
        <div v-if="!this.$screen.xs" id="contract-advantages-wrapper">
          <ContractAdvantages :contract-code="selectedContractCode" :contract="selectedContract" logo-position="top" />
        </div>
      </div>

      <div class="button-group-previous-next">
        <ButtonPrevious type="button" @click="previous" />
        <ButtonNext
          type="submit"
          @click="handleCalculatePremium"
          :disabled="meta.valid === false || disableCalculatePremium"
        />
      </div>
    </Form>
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';

import BusinessRule from '@/constants/business-rule';
import Contracts from '@/constants/contracts';
import Logo from '@/constants/logos';
import Validation from '@/constants/validation';
import Modal from '@/constants/modal';
import NProgress from 'nprogress';

import { Form } from 'vee-validate';

import CalculatePremiumForm from '@/components/viewelements/insured/CalculatePremiumForm.vue';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';

import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';
import Container from '@/components/shared/Container.vue';
import ContractStartDate from '@/components/viewelements/insured/ContractStartDate.vue';
import ContractAdvantages from '@/components/viewelements/home/ContractAdvantages.vue';

export default {
  name: 'Insured',
  props: {
    contractCode: Number,
  },
  components: {
    CalculatePremiumForm,
    Form,
    ButtonPrevious,
    ButtonNext,
    Container,
    ContractStartDate,
    ContractAdvantages,
  },
  data() {
    return {
      disableCalculatePremium: false,
    };
  },
  computed: {
    ...mapGetters(['selectedContract', 'selectedContractCode', 'startDate']),
    formRef() {
      return this.$refs.formRef;
    },
  },
  beforeMount() {
    this.$store.commit(BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR, this.contractCode);
    this.$store.commit(Contracts.Mutations.SET_SELECTED_ID, this.contractCode);
    this.$store.dispatch(Contracts.Actions.LOAD_EJW_INFO);
    this.$store.dispatch(Logo.Actions.LOAD_BY_CONTRACT_CODE, this.contractCode);
    this.$store.dispatch(Contracts.Actions.LOAD_CONTRACT, this.$store.getters.businessRule.lastPolicyYear);
  },
  methods: {
    async handleCalculatePremium() {
      if (await this.$refs.formRef?.validate()) {
        let businessRuleModel = this.$store.getters.businessRule;
        const currentYear = new Date().getFullYear();
        const startYear = this.startDate.getFullYear();
        if (!this.$store.getters.isEjw && startYear !== currentYear) {
          this.$store.dispatch(Modal.Actions.OPEN, {
            modalData: ModalDataFactory.create(
              this.$tExistsOrDefault('insured.nextYearPremiumsUnknown.title'),
              this.$tExistsOrDefault('insured.nextYearPremiumsUnknown.message'),
            ),
          });
        } else {
          this.disableCalculatePremium = true;
          this.startLoadingIndicatorBar();
          this.$store.commit(BusinessRule.Mutations.SET_LASTPOLICYYEAR, {
            lastPolicyYear: this.$store.getters.lastPolicyYearAvailable === true && startYear === currentYear,
          });
          NProgress.inc(0.1);

          this.$store.commit(BusinessRule.Mutations.SET_LANGUAGE, { language: this.$i18n.locale.toUpperCase() });

          this.$store
            .dispatch(BusinessRule.Actions.POST)
            .then(() => {
              this.$store.dispatch(Contracts.Actions.LOAD_CONTRACT, businessRuleModel.lastPolicyYear);
              NProgress.inc(0.25);
            })
            .then(() => NProgress.set(1.0))
            .finally(() => {
              setTimeout(() => {
                NProgress.done();
                this.disableCalculatePremium = false;
                this.navigateToConfiguration();
              }, 250);
            });
        }
      } else {
        // dirty way of going to the first error.
        var el = this.$el.getElementsByClassName('error')[0].parentNode;
        el.scrollIntoView();
      }
    },
    startLoadingIndicatorBar() {
      NProgress.configure({
        parent: '#loading-indicator',
        showSpinner: false,
        trickleSpeed: 75,
        easing: 'ease',
        speed: 275,
      });
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, true);
      NProgress.start();
    },
    navigateToConfiguration() {
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, false);
      this.$router.push({
        name: 'configuration',
        params: {
          lang: this.$i18n?.locale,
          activationStep: 0,
        },
      });
    },
    async storeValidationState() {
      this.$store.dispatch(Validation.Actions.SET_INSURED, await this.$refs.formRef.validate());
    },
    previous() {
      this.$router.push({
        name: 'contract-overview',
        params: {
          contractCode: this.selectedContractCode,
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-min-height {
  min-height: 82vh;
  margin-bottom: 45px;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  & > div:first-child {
    flex: 0 1 450px;
  }
  & > div:last-child {
    flex: 1 1 auto;
  }
}

#contract-advantages-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 24px;
  > div {
    max-width: 410px;
  }
}
</style>

<style lang="scss">
.insured-container > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
