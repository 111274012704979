<template>
  <div class="block-wrapper">
    <H2>{{ $tExistsOrDefault('personal.address') }}</H2>
    <div class="main-form mt-3">
      <div class="row">
        <div class="form-group col-12">
          <label>{{ $tExistsOrDefault('personal.form.country.label') }}</label>
          <FormFieldValidation
            rules="required"
            v-slot="{ handleChange }"
            :field-name="'country'"
            :initial-value="form.country"
            :reserve-validation-space="true"
          >
            <DropdownWithLabel
              v-model="form.country"
              :options="nationalities.map((n) => ({ label: n.value, value: n.key }))"
              :use-translation-keys="false"
              @change="
                (x) => {
                  changeForm();
                  handleChange(x);
                }
              "
            />
          </FormFieldValidation>
        </div>
        <div class="form-group col-6 col-lg-3">
          <label for="zipCodeInput">{{ $tExistsOrDefault('personal.form.zipcode.label') }}</label>
          <FormFieldValidation
            ref="zipCodeRef"
            :rules="
              selectedCountryIsNetherlands
                ? 'required:personal.form.zipcode.required|zipCode'
                : 'required:personal.form.zipcode.required|min:2|max:12'
            "
            v-slot="{ handleBlur, handleChange }"
            :field-name="'zipCode'"
            :initial-value="form.zipcode"
            :reserve-validation-space="true"
          >
            <input
              id="zipCodeInput"
              type="text"
              v-focus="!$screen.xs"
              :placeholder="selectedCountryIsNetherlands ? '1234 AB' : ''"
              class="form-control text-uppercase"
              v-maska
              :data-maska="selectedCountryIsNetherlands ? '#### @@' : ''"
              v-model="form.zipcode"
              @blur="
                {
                  loadAddress(form.zipcode, form.housenumber);
                  handleBlur();
                }
              "
              @change="handleChange"
            />
          </FormFieldValidation>
        </div>
        <div class="col-6 col-lg-3">
          <label for="houseNumberInput">{{ $tExistsOrDefault('personal.form.houseNumber.label') }}</label>
          <FormFieldValidation
            rules="required:personal.form.houseNumber.required|housenumber"
            v-slot="{ handleBlur, handleChange }"
            :field-name="'housenumber'"
            :initial-value="form.housenumber"
            :reserve-validation-space="true"
          >
            <input
              id="houseNumberInput"
              type="text"
              class="form-control"
              v-maska
              data-maska="######"
              v-model="form.housenumber"
              @blur="
                {
                  loadAddress(form.zipcode, form.housenumber);
                  handleBlur();
                }
              "
              @change="handleChange"
            />
          </FormFieldValidation>
        </div>
        <div class="form-group col-12 col-lg-6">
          <label for="houseNumberAdditionInput">{{
            $tExistsOrDefault('personal.form.houseNumber.additionalLabel')
          }}</label>
          <div>
            <FormFieldValidation
              :rules="form.housenumberAddition ? 'housenumberAddition' : ''"
              v-slot="{ handleBlur, handleChange }"
              :field-name="'housenumberAddition'"
              :initial-value="form.housenumberAddition"
              :reserve-validation-space="true"
            >
              <input
                id="houseNumberAdditionInput"
                autocomplete="off"
                type="text"
                class="form-control"
                v-maska
                data-maska="********"
                v-model="form.housenumberAddition"
                @change="
                  {
                    changeForm();
                    handleChange($event);
                  }
                "
                @blur="handleBlur"
                @input="houseNumberInputChanged"
              />
            </FormFieldValidation>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="streetNameInput">{{ $tExistsOrDefault('personal.form.street.label') }}</label>
        <FormFieldValidation
          ref="addressStreetDataObserver"
          rules="streetpobox|street"
          v-slot="{ handleBlur, handleChange }"
          :field-name="'street'"
          :initial-value="form.street"
          :reserve-validation-space="true"
        >
          <input
            id="streetNameInput"
            type="text"
            class="form-control"
            maxlength="50"
            v-model="form.street"
            @change="
              {
                changeForm();
                handleChange($event);
              }
            "
            @blur="handleBlur"
            :disabled="disableAddress || undefined"
          />
        </FormFieldValidation>
      </div>
      <div class="form-group">
        <label for="cityInput">{{ $tExistsOrDefault('personal.form.city.label') }}</label>
        <FormFieldValidation
          ref="cityReference"
          rules="required:personal.form.city.required"
          v-slot="{ handleBlur, handleChange }"
          :field-name="'city'"
          :initial-value="form.city"
          :reserve-validation-space="true"
        >
          <input
            id="cityInput"
            type="text"
            class="form-control"
            maxlength="50"
            v-model="form.city"
            @change="
              {
                changeForm();
                handleChange($event);
              }
            "
            @blur="handleBlur"
            :disabled="disableAddress || undefined"
          />
        </FormFieldValidation>
      </div>
    </div>
  </div>
</template>

<script>
import AddressUtils from '@/utils/address';
import { mapGetters } from 'vuex';
import AddressConst from '@/constants/address';
import H2 from '@/components/shared/typography/H2.vue';
import NationalityService from '@/helpers/nationality-service';
import DropdownWithLabel from '@/components/shared/input/DropdownWithLabel.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

export default {
  emits: ['formChange'],
  name: 'PersonalDataAddress',
  components: {
    H2,
    DropdownWithLabel,
    FormFieldValidation,
  },
  props: {
    initialForm: Object,
  },
  data() {
    return {
      form: { ...this.initialForm },
    };
  },
  computed: {
    ...mapGetters(['disableAddress']),
    nationalities() {
      var allNationalities = NationalityService.getNationalities();
      var allowedCountries = NationalityService.getAllowedCountryCodesForAddress();
      return allNationalities.filter((n) => allowedCountries.includes(n.key));
    },
    selectedCountryIsNetherlands() {
      return this.selectedCountry === 'NL';
    },
    selectedCountry() {
      return this.form.country;
    },
  },
  watch: {
    selectedCountryIsNetherlands() {
      this.changeForm();
      this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false);
    },
    selectedCountry() {
      this.form.zipcode = null;
      this.form.housenumber = null;
      this.form.housenumberAddition = null;
      this.form.street = null;
      this.form.city = null;
    },
  },
  methods: {
    changeForm() {
      this.$emit('formChange', this.form);
    },
    houseNumberInputChanged() {
      this.form.housenumberAddition = this.form.housenumberAddition?.toUpperCase();
    },
    isValidStreet() {
      return this.$refs.addressStreetDataObserver.validate();
    },
    resetStreetAndCityFields() {
      this.$refs.addressStreetDataObserver.resetField(this.form.street);
      this.$refs.cityReference.resetField(this.form.city);
    },
    async loadAddress(zipCode, housenumber) {
      if (!this.selectedCountryIsNetherlands) {
        return;
      }

      if (this.initialForm.zipcode === zipCode && this.initialForm.housenumber === housenumber) {
        return;
      }

      if (!zipCode || !housenumber) {
        this.form.street = null;
        this.form.city = null;
        this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false);
        this.resetStreetAndCityFields();
        return;
      }

      const zipCodeValidationObject = await this.$refs.zipCodeRef.validate();

      if (!zipCodeValidationObject.valid) {
        return;
      }

      AddressUtils.Load(zipCode.toUpperCase(), housenumber)
        .then((response) => {
          if (response.data) {
            this.form.street = response.data.street;
            this.form.city = response.data.city;
            this.resetStreetAndCityFields();
            setTimeout(async () => {
              const validObject = await this.isValidStreet();
              this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, validObject.valid);
            }, 200);
          }
        })
        .catch(() => {
          this.form.street = null;
          this.form.city = null;
          this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false);
          this.resetStreetAndCityFields();
        })

        .finally(() => {
          this.changeForm();
        });

      this.changeForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.5em;
}
</style>
