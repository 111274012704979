<template>
  <div>
    <LinkText @click="$emit('closeHealthcareProvider')" class="back-to-results-link">
      <Chevron direction="left" :size="10" />
      {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.backToResults') }}
    </LinkText>

    <div class="info-header">
      <H2>{{ healthCareProvider.name }}</H2>
      <HealthcareProviderContracted :year="selectedYear" :contracted-type="healthCareProvider.contractType" />
    </div>

    <div class="healthcare-provider-info" :class="{ mobile: $screen.xs }">
      <div>
        <H3>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.youCanGoHere') }}
        </H3>
        <div class="icon-and-text" v-for="kwalification in healthCareProvider.kwalifications" :key="kwalification">
          <Check />
          <PlainText>{{ kwalification }}</PlainText>
        </div>
      </div>
      <div>
        <H3>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.contactDetails') }}
        </H3>
        <PlainText>
          {{ healthCareProvider.street }} {{ healthCareProvider.housenumber }},
          {{ healthCareProvider.zipcode }}
          {{ healthCareProvider.city }}
        </PlainText>
        <PlainText>{{ healthCareProvider.phoneNumber }}</PlainText>
        <LinkText :href="'mailto:' + healthCareProvider.email">
          {{ healthCareProvider.email }}
        </LinkText>
      </div>
    </div>
  </div>
</template>

<script>
import HealthcareProviderContracted from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay//HealthcareProviderContracted.vue';
import Chevron from '@/components/shared/icons/Chevron.vue';
import Check from '@/components/shared/icons/Check.vue';
import H2 from '@/components/shared/typography/H2.vue';
import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';

export default {
  name: 'HealthcareProviderInfo',
  emits: ['closeHealthcareProvider'],
  props: {
    healthCareProvider: null,
    selectedYear: {
      required: true,
      type: Number,
    },
  },
  components: {
    HealthcareProviderContracted,
    Chevron,
    Check,
    H2,
    H3,
    PlainText,
    LinkText,
  },
  methods: {
    chrevonColor() {
      return 'var(--color-primary)';
    },
  },
};
</script>

<style scoped lang="scss">
.back-to-results-link {
  svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }
}
.info-header {
  margin-top: 32px;
  margin-bottom: 32px;
}
.healthcare-provider-info {
  width: auto;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.healthcare-provider-info.mobile {
  flex-direction: column;
}
.icon-and-text {
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child {
    flex-shrink: 0;
    height: 22px;
  }
}
</style>
