<template>
  <div>
    <div class="disclaimer-item">
      <ExclamationMark id="exlamation-mark-icon" />
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.childrenUnder18') }}
      </PlainText>
    </div>
    <div class="disclaimer-item">
      <PlainText type="warning" class="text-right"> * </PlainText>
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.averageContractedRate') }}
      </PlainText>
      <Tooltip>
        <LinkText>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipLink') }}
        </LinkText>
        <template #content>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipTextAverageContractedRate') }}
          </PlainText>
        </template>
      </Tooltip>
    </div>
    <div class="disclaimer-item">
      <PlainText type="warning" class="text-right"> ** </PlainText>
      <PlainText>
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.marketRate') }}
      </PlainText>
      <Tooltip>
        <LinkText>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipLink') }}
        </LinkText>
        <template #content>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipTextMarketRate') }}
          </PlainText>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import ExclamationMark from '@/components/shared/icons/ExclamationMark.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import Tooltip from '@/components/shared/Tooltip.vue';

export default {
  name: 'BasicInsuranceProductDisclaimer',
  components: {
    ExclamationMark,
    PlainText,
    LinkText,
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-item {
  display: flex;
  gap: 6px;
  :last-child {
    align-self: center;
  }
  :first-child {
    flex: 0 0 24px;
  }
}

#exlamation-mark-icon {
  color: var(--color-primary);
  height: 24px;
}
</style>
