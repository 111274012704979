<template>
  <Card :padded="false">
    <LoadingOverlay :show-overlay="isBusinessRulePostLoading">
      <div id="card-content" class="card-width">
        <div id="card-header">
          <CardHeader>
            {{ $tExistsOrDefault('calculatePremium.ownRisk.title') }}
          </CardHeader>
          <CardAmountVue>{{ formatCurrency(ownRiskAmount) }}</CardAmountVue>
        </div>
        <PlainText class="text-right" :class="{ invisible: ownRiskAmount === 385 }">
          {{ $tExistsOrDefault('calculatePremium.ownRisk.premiumDiscount') }}
        </PlainText>

        <SelectProductForPersonCheckbox
          v-for="(person, i) in product.persons"
          class="person-checkbox"
          :key="'select-box-for-' + product.productComponentCode + '-' + i"
          :product="product"
          :person="person"
          :show-tooltip="null"
          is-radio
        />
      </div>
    </LoadingOverlay>
  </Card>
</template>

<script>
import SelectProductForPersonCheckbox from '@/components/viewelements/configuration/selectProductForPersonCheckbox/SelectProductForPersonCheckbox.vue';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import LoadingOverlay from '@/components/shared/overlay/LoadingOverlay.vue';
import { mapGetters } from 'vuex';
import Card from '@/components/shared/card/Card.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import CardHeader from '@/components/shared/typography/CardHeader.vue';
import CardAmountVue from '@/components/shared/typography/CardAmount.vue';

export default {
  name: 'OwnRiskComponent',
  components: {
    SelectProductForPersonCheckbox,
    LoadingOverlay,
    Card,
    PlainText,
    CardHeader,
    CardAmountVue,
  },
  props: {
    product: Object,
  },
  computed: {
    ...mapGetters(['isBusinessRulePostLoading']),
    insuredPersons() {
      return this.$store.getters.insuredPersons;
    },
    agreement() {
      return this.$store.getters.agreement;
    },
    ownRiskAmount() {
      return parseInt(this.$tExistsOrDefault('calculatePremium.ownRisk.ownRiskValue')) + this.product.ownRiskAmount;
    },
    discount() {
      return Math.abs(this.product.ownRiskDiscount);
    },
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
  },
};
</script>

<style lang="scss" scoped>
#card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.card-width {
  width: 267px;
  padding: 24px;
}
</style>
