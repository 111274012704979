<template>
  <PartialOverlayWithCloseButton v-model="value">
    <div class="pt-3 overlay-template d-flex flex-column" ref="medicalSelectionTop">
      <section>
        <div
          v-if="steps === 1 || (!isMobile && steps === 2 && currentPersonId === firstInsuredPersonId)"
          class="title-and-paragraph"
        >
          <H1>{{ $tExistsOrDefault('medicalSelection.title') }}</H1>
          <PlainText>
            {{ $tExistsOrDefault('medicalSelection.intro') }}
          </PlainText>
        </div>
      </section>
      <section>
        <Form ref="medicalSelectionObserver" tag="form" class="form-width">
          <MedicalSelectionForm
            v-if="steps === 2"
            :key="'medical-selection-form' + currentPersonId"
            :person-id="currentPersonId"
          />
        </Form>
      </section>
      <section>
        <AgreementTermsForm v-if="steps === 3" @accepted-terms="hasAcceptedTerms" />
      </section>
      <section>
        <div class="div-width d-flex align-items-end" :class="{ 'flex-grow-1': !isMobile }">
          <div class="w-100 d-flex justify-content-between" :class="!isMobile ? 'margin-desktop' : 'margin-mobile'">
            <ButtonPrevious v-if="previousButtonText" @click="showPrevious()">
              {{ previousButtonText }}
            </ButtonPrevious>
            <ButtonNext v-if="steps !== 3" @click="showNext()">
              {{ nextButtonText }}
            </ButtonNext>
          </div>
        </div>
      </section>
    </div>
  </PartialOverlayWithCloseButton>
</template>

<script>
import { filter, some } from 'lodash-es';
import MedicalSelectionForm from '@/components/viewelements/configuration/medicalSelection/MedicalSelectionForm.vue';
import AgreementTermsForm from '@/components/viewelements/configuration/medicalSelection/AgreementTermsForm.vue';
import { Form } from 'vee-validate';
import businessRule from '@/constants/business-rule';
import PartialOverlayWithCloseButton from '@/components/shared/overlay/PartialOverlayWithCloseButton.vue';
import H1 from '@/components/shared/typography/H1.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';

export default {
  name: 'MedicalSelectionOverlay',
  components: {
    MedicalSelectionForm,
    AgreementTermsForm,
    Form,
    PartialOverlayWithCloseButton,
    H1,
    PlainText,
    ButtonPrevious,
    ButtonNext,
  },
  emits: ['acceptedTerms', 'update:modelValue'],
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      currentPersonId: 0,
      hasAgreedToTerms: false,
      steps: null,
    };
  },
  mounted() {
    this.steps = this.isMobile ? 1 : 2;
  },
  computed: {
    medicalSelectionInsuredPersons() {
      return filter(this.$store.getters.agreement.insuredPersons, (person) =>
        some(person.chosenInsurances, (ci) => ci.hasMedicalSelection),
      );
    },
    insured() {
      return this.$store.getters.insured.find((i) => i.id === this.currentPersonId);
    },
    valid: function () {
      return this.$refs.medicalSelectionObserver.validate();
    },
    isMobile() {
      return this.$screen.xs;
    },
    firstInsuredPersonId() {
      return this.medicalSelectionInsuredPersons[0]?.id;
    },
    lastInsuredPersonId() {
      return this.medicalSelectionInsuredPersons[this.medicalSelectionInsuredPersons.length - 1]?.id;
    },
    nextButtonText() {
      if (this.steps === 1) {
        return this.$tExistsOrDefault('medicalSelection.toQuestionnaire');
      } else if (this.steps === 2) {
        if (this.currentPersonId === this.lastInsuredPersonId) {
          return this.$tExistsOrDefault('medicalSelection.toSigned');
        } else {
          return this.$tExistsOrDefault('medicalSelection.next');
        }
      }
      return '';
    },
    previousButtonText() {
      if (
        (this.steps !== 1 && this.isMobile) ||
        (!this.isMobile && this.steps === 2 && this.currentPersonId !== this.firstInsuredPersonId) ||
        this.steps === 3
      ) {
        return this.$tExistsOrDefault('medicalSelection.previous');
      }
      return null;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
  },
  methods: {
    clearMedicalSelectionForms() {
      this.$store.commit(businessRule.Mutations.RESET_MEDICAL_SELECTION_ANSWERS);
    },
    async showNext() {
      const validObject = await this.$refs.medicalSelectionObserver.validate();

      if (validObject.valid) {
        if (this.steps === 1) {
          this.steps++;
        } else if (this.steps === 2 && this.lastInsuredPersonId === this.currentPersonId) {
          this.steps++;
        } else {
          const currentIndexOfPerson = this.medicalSelectionInsuredPersons
            .map((x) => x.id)
            .indexOf(this.currentPersonId);
          const nextPersonId = this.medicalSelectionInsuredPersons[currentIndexOfPerson + 1].id;
          this.currentPersonId = nextPersonId;
        }

        if (this.currentPersonId < this.medicalSelectionInsuredPersons.length) {
          requestAnimationFrame(() => {
            this.$refs.medicalSelectionObserver.resetForm();
          });
        }
        this.scrollToTop();
      }
    },
    showPrevious() {
      if (this.steps === 3) {
        this.steps = 2;
      } else if (this.steps === 1 || (!this.isMobile && this.currentPersonId === this.firstInsuredPersonId)) {
        this.clearMedicalSelectionForms();
        this.value = false;
      } else {
        if (this.firstInsuredPersonId === this.currentPersonId) {
          this.steps = 1;
        } else {
          const currentIndexOfPerson = this.medicalSelectionInsuredPersons
            .map((x) => x.id)
            .indexOf(this.currentPersonId);
          const nextPersonId = this.medicalSelectionInsuredPersons[currentIndexOfPerson - 1].id;
          this.currentPersonId = nextPersonId;
        }
      }
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.medicalSelectionTop.scrollIntoView(true);
    },
    hasAcceptedTerms() {
      this.hasAgreedToTerms = true;
      this.value = false;
      // Need this timeout to assign data to child component of PartialOverlayWithCloseButton
      setTimeout(() => {
        this.$emit('acceptedTerms');
      }, 5);
    },
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.steps = 1;
      } else {
        this.steps = 2;
      }
    },
    modelValue(value) {
      if (value) {
        this.hasAgreedToTerms = false;
        this.currentPersonId = this.medicalSelectionInsuredPersons[0].id;
        if (this.isMobile) {
          this.steps = 1;
        } else {
          this.steps = 2;
        }
      } else if (!this.hasAgreedToTerms) {
        this.clearMedicalSelectionForms();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-mobile .overlay-template {
  padding: 16px;
}
.overlay-template {
  min-height: 100vh;
  user-select: none;
  div:not(.title-and-paragraph) {
    max-width: 900px;
  }
  & > * {
    &:nth-child(3) {
      margin-top: 0.5rem;
    }
  }
  .title-and-paragraph {
    &:deep() h1 {
      margin-bottom: 0.5rem;
    }
    &:deep() > .content-wrapper {
      max-width: 673px;
    }
  }
  .form-width,
  div.div-width {
    max-width: 673px;
  }
}
.overlay :deep() > .overlay-content {
  max-width: 850px;
}
.margin-desktop {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.margin-mobile {
  margin-top: 1rem;
  margin-bottom: 5rem;
}
</style>
