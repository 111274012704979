<template>
  <hr />
</template>
<script>
export default {
  name: 'HorizontalRule',
};
</script>
<style lang="scss" scoped>
hr {
  background: var(--color-grey-300);
  margin: 20px 0;
}
</style>
