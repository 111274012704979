<template>
  <!-- PremiumTotals per term -->
  <div>
    <div class="total-cost-container" :class="{ 'space-between': $screen.lt.sm }">
      <PlainText>
        {{ $tExistsOrDefault(`calculatePremium.premiumOverview.button.totalPremium`) }}
        <span v-if="agreement.paymentTerm === 1">
          {{ $tExistsOrDefault(`overview.eachMonth`) }}
        </span>
        <span v-else>
          {{ $tExistsOrDefault(`overview.eachYear`) }} {{ '(' }}
          {{ $tExistsOrDefault('totalOverview.discount') }}
          {{ formatCurrency(paymentTermDiscount(12)) }} {{ ')' }}
        </span>
      </PlainText>
      <H3 class="total-cost">
        {{
          formatCurrency(
            paymentTermWithNetDiscount(agreement.paymentTerm).paymentTermNetPremiumWithOwnRiskDiscountTotal,
          )
        }}
      </H3>
    </div>

    <div class="button-container" :class="{ 'space-between': $screen.lt.sm }">
      <ButtonPrevious @click="gotoConfiguration">
        {{ $tExistsOrDefault('overview.button.changePackage') }}
      </ButtonPrevious>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

import formatCurrencyHelper from '@/helpers/formatCurrency.js';
import PaymentTermHelpers from '@/helpers/payment-term';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';

export default {
  name: 'OverviewTotalPremium',
  components: {
    H3,
    PlainText,
    ButtonPrevious,
  },
  data() {
    return {
      paymentTermDiscount: PaymentTermHelpers.paymentTermDiscount,
      paymentTermWithNetDiscount: PaymentTermHelpers.paymentTermWithNetDiscount,
    };
  },
  computed: {
    ...mapGetters(['agreement']),
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
    gotoConfiguration(event) {
      if (event) event.preventDefault();
      this.$router.push({
        name: 'configuration',
        params: {
          lang: this.$i18n.locale,
          contractCode: this.$store.getters.selectedContractCode,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.space-between {
  display: flex;
  justify-content: space-between !important;
  gap: 0px;
}

.total-cost-container {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 17px;
  margin-bottom: 17px;
}

.total-cost {
  font-size: 24px;
}

.button-container {
  display: flex;
  justify-content: right;
}
</style>
