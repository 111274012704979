<template>
  <div class="input-wrapper">
    <label v-if="label" for="dropdown">{{ label }}</label>
    <v-select
      class="dropdown"
      :model-value="selected"
      ref="select"
      name="dropdown"
      :options="options"
      :clearable="false"
      :components="{ OpenIndicator }"
      :filterable="searchable"
      :searchable="searchable"
      :placeholder="placeholder"
      @search="onSearch"
      @update:model-value="setSelected"
    >
      <template #no-options>
        <font-awesome-icon v-if="isSearching" icon="circle-notch" size="2x" spin />
        <div v-else>
          {{ noResultsTextInternal }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { h, resolveComponent } from 'vue';
export default {
  name: 'DropdownWithLabel',
  emits: ['input', 'change', 'search', 'update:modelValue'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    noResultsText: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Date],
    },
  },
  methods: {
    /**
     * @param {String} searchString - the search string
     * @param {Function} toggleLoading - function to toggle loading state, accepts true or false boolean
     */
    onSearch(search, loading) {
      this.$emit('search', search, loading);
    },
    setSelected(selected) {
      if (typeof selected === 'object') {
        selected = selected.value;
      }
      this.$emit('input', selected);
      this.$emit('change', selected);
      this.$emit('update:modelValue', selected);
    },
  },
  computed: {
    selected() {
      if (this.options[0].value && this.modelValue) {
        // The toString is needed because when comparing two date objects, they are not equal even if they have the same value
        return this.options.find((x) => x.value.toString() == this.modelValue.toString());
      }
      return this.modelValue;
    },
    noResultsTextInternal() {
      return this.noResultsText ?? this.$tExistsOrDefault('general.dropdown.noResults');
    },
    OpenIndicator() {
      const FontAwesomeIcon = resolveComponent('font-awesome-icon');
      if (this.searchable) {
        return {
          render: () =>
            h(FontAwesomeIcon, {
              icon: 'magnifying-glass',
              style: {
                color: 'var(--color-grey-900)',
                transform: 'none !important',
              },
            }),
        };
      } else {
        return {
          render: () =>
            h(FontAwesomeIcon, {
              icon: 'chevron-down',
              style: { color: 'var(--color-grey-900)' },
            }),
        };
      }
    },
  },
  /**
   * Development only
   *  Hard to debug opened dropdown (auto closes on click outside)
   */
  // mounted() {
  // this.$refs.select.open = true;
  // this.$refs.select.search = 'no';
  // },
};
</script>
<style lang="scss" scoped>
.dropdown,
label {
  font-size: 1rem;
  color: var(--color-grey-900);
}

.dropdown {
  border-radius: var(--border-radius);
  width: 100%;
  background-color: var(--color-white);
  &:focus {
    outline: none;
  }
}
label {
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>

<style lang="scss">
.vs__dropdown-toggle {
  border: 1px solid var(--color-grey-400) !important;
  transition: all 0.2s ease-in-out;
  padding: 3px 6px 6px 6px !important;
  &:focus-within {
    border: 1px solid var(--color-primary-800) !important;
  }
}

.vs__dropdown-option--highlight {
  background-color: var(--color-primary-800) !important;
  color: var(--color-white) !important;
}

.vs__actions {
  svg {
    color: var(--color-primary-800) !important;
  }
}

span.vs_selected {
  color: var(--color-grey-700) !important;
}
</style>
