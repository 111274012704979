<template>
  <svg
    :width="size"
    viewBox="0 0 20 12"
    xmlns="http://www.w3.org/2000/svg"
    :class="directionClass"
    preserve-aspect-ratio="xMidYMid meet"
  >
    <g fill="none" fill-rule="evenodd">
      <path :style="{ stroke: strokeColor }" stroke-width="3" stroke-linecap="round" d="M2 2 L10 10 L18 2" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Chevron',
  props: {
    direction: {
      type: String,
      default: 'down',
      validator(value) {
        return ['up', 'down', 'left', 'right'].includes(value);
      },
    },
    size: Number,
    color: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    directionClass() {
      if (this.direction == 'left') return 'rotate-90deg';
      if (this.direction == 'up') return 'rotate-180deg';
      if (this.direction == 'right') return 'rotate-270deg';
      return '';
    },
    strokeColor() {
      return this.color;
    },
  },
};
</script>
