<template>
  <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 .442)" stroke="currentColor" fill="none" fill-rule="evenodd">
      <ellipse fill="#FFF" cx="9.5" cy="9.485" rx="9" ry="8.985" />
      <path stroke-width="2" stroke-linecap="round" d="m5 6.989 4.5 5.491M14 6.989 9.5 12.48" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'CircleChevron',
};
</script>
