<template>
  <div class="input-wrapper">
    <label class="w-100">
      <div v-if="label">
        <span>
          {{ label }}
        </span>
      </div>
      <Field
        :name="name"
        :model-value="dateAsString"
        v-slot="{ errors, field }"
        ref="dateInputRef"
        type="date"
        :rules="{
          required: true,
          validDate: true,
          minDate: minDate.toString(),
          maxDate: maxDate.toString(),
        }"
      >
        <input
          v-bind="field"
          v-focus="!$screen.xs"
          v-maska
          data-maska="##-##-####"
          type="text"
          class="form-control"
          placeholder="dd-mm-jjjj"
          autocomplete="on"
          :class="{ invalid: errors.length > 0 }"
          @change="setDate($event.target.value)"
        />
        <span class="error-message">{{ errors[0] }}</span>
      </Field>
    </label>
  </div>
</template>

<script>
import { Field } from 'vee-validate';
import Dates from '@/helpers/dates';

export default {
  name: 'ManualDateInput',
  emits: ['update:modelValue'],
  props: {
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: Date,
      default: null,
    },
    name: {
      type: String,
      default: 'TODO SHOULD BE REQUIRED',
    },
  },
  components: { Field },
  computed: {
    dateAsString() {
      return this.modelValue ? Dates.formatDate(this.modelValue) : '';
    },
  },
  methods: {
    setDate(val) {
      if (val?.length === 10) {
        this.$refs.dateInputRef.validate().then((res) => {
          if (res.valid) {
            this.$emit('update:modelValue', val);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  &.invalid {
    border-color: var(--color-error);
    color: var(--color-error);
  }
}

label {
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-message {
  color: var(--color-error);
  font-size: var(--text-font-size-14);
}

.remove-icon {
  cursor: pointer;
  color: var(--color-primary-400);
  display: inline;
  margin-right: 10px;
}
</style>
