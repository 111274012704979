import { mapGetters } from 'vuex';
import BusinessRule from '@/constants/business-rule';

export const showAllPackageTypesInOverview = {
  computed: {
    ...mapGetters(['productList']),
  },
  watch: {
    productList: {
      immediate: true,
      handler(val) {
        val
          .map((p) => p.packageType)
          .forEach((p) => this.$store.dispatch(BusinessRule.Actions.ADD_VISITED_PACKAGE_TYPE, p));
      },
    },
  },
};
