<template>
  <VDropdown theme="tooltip-2023" :placement="placement" :auto-hide="false" :disabled="disabled" :triggers="['hover']">
    <slot />

    <template #popper>
      <slot name="content" />
    </template>
  </VDropdown>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    placement: {
      value: String,
      type: String,
      default: 'top',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scope>
.v-popper--theme-tooltip-2023 .v-popper__inner {
  background: var(--color-primary-050);
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  max-width: 380px;
}

.v-popper--theme-tooltip-2023 .v-popper__arrow-inner {
  visibility: visible;
  border-color: var(--color-primary-050);
}

.v-popper--theme-tooltip-2023 .v-popper__arrow-outer {
  border-color: #ddd;
}

.v-popper--theme-tooltip-2023 .v-popper__wrapper {
  height: auto;
}

.v-popper--theme-tooltip-2023.v-popper__popper {
  z-index: 10000000000;
}
@media (max-width: 576px) {
  .v-popper--theme-tooltip-2023 .v-popper__wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .v-popper--theme-tooltip-2023 .v-popper__inner {
    max-width: none;
  }
}
</style>
