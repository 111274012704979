<template>
  <PartialOverlayWithCloseButton :model-value="modelValue" @update:model-value="onClose">
    <div>
      <div class="h1-container">
        <H1>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.title') }}
        </H1>
      </div>
      <section>
        <div class="h2-container">
          <H2>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.sectionIncluded.title') }}
          </H2>
        </div>
        <div class="bulletpoint-container">
          <div
            v-for="item in $tExistsOrDefault(
              'calculatePremium.basicInsurance.informationOverlay.sectionIncluded.items',
            )"
            class="bulletpoint-wrapper"
            :key="item"
          >
            <Check />
            <PlainText>{{ item }}</PlainText>
          </div>
        </div>
        <div>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt1') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt2') }}
          </PlainText>
        </div>
      </section>
      <section>
        <div class="h2-container">
          <H2>
            {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.sectionExcluded.title') }}
          </H2>
        </div>
        <div class="bulletpoint-container">
          <div
            v-for="item in $tExistsOrDefault(
              'calculatePremium.basicInsurance.informationOverlay.sectionExcluded.items',
            )"
            class="bulletpoint-wrapper"
            :key="item"
          >
            <Unchecked />
            <PlainText>{{ item }}</PlainText>
          </div>
        </div>
        <PlainText>
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.informationOverlay.sectionExcluded.footer') }}
        </PlainText>
      </section>
    </div>
  </PartialOverlayWithCloseButton>
</template>

<script>
import PartialOverlayWithCloseButton from '@/components/shared/overlay/PartialOverlayWithCloseButton.vue';
import H1 from '@/components/shared/typography/H1.vue';
import H2 from '@/components/shared/typography/H2.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Check from '@/components/shared/icons/Check.vue';
import Unchecked from '@/components/shared/icons/Unchecked.vue';

export default {
  name: 'BasicInsuranceOverlay',
  emits: ['update:modelValue'],
  components: {
    PartialOverlayWithCloseButton,
    H1,
    H2,
    PlainText,
    Check,
    Unchecked,
  },
  props: {
    modelValue: Boolean,
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.h1-container {
  margin-bottom: 32px;
}
.h2-container {
  margin-bottom: 12px;
}

.bulletpoint-wrapper {
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child {
    flex-shrink: 0;
    height: 22px;
  }
}

.bulletpoint-container {
  margin-bottom: 10px;
}
section {
  margin-bottom: 24px;
  margin-right: 15px;
}
</style>
