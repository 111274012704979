<template>
  <div>
    <Checkbox :disabled="true" :model-value="true" :check-box-size="24">
      <PlainText class="label-text">
        {{ $tExistsOrDefault('insured.main') }}
      </PlainText>
    </Checkbox>
    <div class="row mb-3">
      <PlainText class="col-12 mt-2 col-md-3 mb-2">
        {{ $tExistsOrDefault('insured.dateOfBirth') }}
      </PlainText>
      <ManualDateInput
        class="col"
        :model-value="insuredMainDateOfBirth"
        @update:model-value="insuredMainDateOfBirth = $event"
        :min-date="mainValidDateRange.to"
        :max-date="mainValidDateRange.from"
        name="mainInsuredDateOfBirth"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ManualDateInput from '@/components/viewelements/insured/ManualDateInput.vue';

import BusinessRule from '@/constants/business-rule';
import CalculatePremiumHelpers from '@/helpers/calculate-premium';
import Checkbox from '@/components/shared/input/Checkbox.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'CalculatePremiumInsuredMain',
  components: {
    ManualDateInput,
    Checkbox,
    PlainText,
  },
  computed: {
    ...mapGetters(['insured', 'startDate', 'getDateOfBirth']),
    insuredMain() {
      return this.insured.find((i) => i.typeOfInsured === 'H');
    },
    mainValidDateRange() {
      return CalculatePremiumHelpers.mainDisabledDates(this.startDate);
    },
    insuredMainDateOfBirth: {
      get() {
        return this.getDateOfBirth(this.insuredMain.id);
      },
      set(value) {
        this.$store.dispatch(BusinessRule.Actions.SET_DATE_OF_BIRTH_FOR_INSURED, {
          insuredId: this.insuredMain.id,
          dateOfBirth: value,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.label-text {
  margin-left: 13px;
}
.row {
  margin-left: 22px;
}
</style>
