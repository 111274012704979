<template>
  <div class="div-container">
    <section>
      <H3>
        {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.title') }}
      </H3>
      <H1>{{ selectedContract.contractName }}</H1>
      <PlainText type="primary">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.collectivityNumber') }}
        {{ selectedContract.contractCode }}
      </PlainText>
      <PlainText type="primary">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.subcontractLabel') }}
        {{ selectedContract.organisationName }}
      </PlainText>
      <br />
    </section>
    <section>
      <H3 class="mb-3">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.uspTitle') }}
      </H3>
      <div
        v-for="usp in $tExistsOrDefault(`calculatePremium.previewOverview.labelUsps.${labelSettings.label}.items`)"
        :key="usp"
      >
        <div class="row mb-2">
          <div class="col-2">
            <CheckInCircle />
          </div>
          <PlainText class="col">
            {{ usp }}
          </PlainText>
        </div>
      </div>
    </section>
    <section v-if="isLabelAevitae">
      <iframe
        frameborder="0"
        allowtransparency="true"
        src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&allowTransparency=true&button=false&lang=nl&tenantId=99&locationId=1078050"
        width="100%"
      />
    </section>
    <section>
      <RevisitEmailLink class="mb-3" />
    </section>
    <section>
      <Button layout-type="action" class="full-width mb-3" @click="$emit('complete-application')">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.buttonCompleteInsuranceRequest') }}
      </Button>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import H1 from '@/components/shared/typography/H1.vue';
import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import CheckInCircle from '@/components/shared/icons/CheckInCircle.vue';
import RevisitEmailLink from '@/components/shared/modals/revisitEmail/RevisitEmailLink.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  name: 'CollectivityInfo',
  components: {
    H1,
    H3,
    PlainText,
    CheckInCircle,
    RevisitEmailLink,
    Button,
  },
  emits: ['complete-application'],
  props: {
    contract: Object,
  },
  computed: {
    ...mapGetters(['selectedContract', 'labelSettings']),
    selectedContract() {
      return this.contract;
    },
    isLabelAevitae() {
      return this.labelSettings.label === 'aev';
    },
  },
};
</script>

<style lang="scss" scoped>
.div-container {
  background-color: var(--color-primary-050);
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 32px 24px 16px 24px;
}
.full-width {
  width: 100%;
}
</style>
