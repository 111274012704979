<template>
  <div id="select-product-for-person-button-wrapper">
    <CheckButtonSlotPrice
      :disabled="isDisabled"
      :model-value="isChecked"
      :show-tooltip="showTooltip"
      :tooltip-text="disabledText"
      :price="price"
      @click="handleSelect"
    >
      <div class="col-auto px-0">
        <span>{{ label }}</span>
        <span v-if="isChild" class="dateOfBirth">{{ person.dateOfBirth }}</span>
      </div>
    </CheckButtonSlotPrice>
  </div>
</template>

<script>
import BusinessRule from '@/constants/business-rule';
import ConfigurationHelper from '@/helpers/configuration';
import productHelper from '@/helpers/product';
import { mapGetters } from 'vuex';
import CheckButtonSlotPrice from '@/components/viewelements/configuration/selectProductForPersonCheckbox/CheckButtonSlotPrice.vue';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';
import Modal from '@/constants/modal';

export default {
  name: 'SelectProductForPersonCheckbox',
  components: {
    CheckButtonSlotPrice,
  },
  props: {
    product: Object,
    person: Object,
    cardDisabled: Boolean,
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['agreement', 'startDate', 'getDateOfBirth']),
    label() {
      switch (this.person.typeOfInsured) {
        case 'H':
          return this.$tExistsOrDefault('calculatePremium.general.main');
        case 'P':
          return this.$tExistsOrDefault('calculatePremium.general.partner');
        case 'M':
          return this.$tExistsOrDefault('calculatePremium.general.child');
        default:
          console.error('Unknown type of insured');
          return null;
      }
    },
    premiumIsEqualForAllPersons() {
      return productHelper.premiumIsEqualForAllPersons(this.product);
    },
    isChecked() {
      if (this.product.packageTypeForWebApplications === 'OW') {
        let insured = this.insuredPerson;
        return insured.chosenOwnRisk === this.product.ownRiskAmount;
      } else {
        let chosenInsurances = this.agreement.insuredPersons.find(
          (insured) => insured.id === this.person.id,
        ).chosenInsurances;
        let chosenProductComponent = chosenInsurances.find(
          (chosenInsurance) =>
            chosenInsurance.productComponentCode === this.product.productComponentCode &&
            chosenInsurance.packageTypeForWebApplications === this.product.packageTypeForWebApplications,
        );
        return chosenProductComponent !== undefined;
      }
    },
    insuredPerson() {
      return this.agreement.insuredPersons.find((insured) => insured.id === this.person.id);
    },
    isDisabled() {
      return this.product.packageTypeForWebApplications === 'MB' ? false : this.person.isDisabled;
    },
    isAdult() {
      return ConfigurationHelper.isAdult(this.startDate, this.getDateOfBirth(this.insuredPerson.id));
    },
    disabledText() {
      if (this.person.isDisabled && !this.cardDisabled && !this.isAdult)
        return this.$tExistsOrDefault('calculatePremium.basicInsurance.kidNotification');
      if (this.person.isDisabled && !this.cardDisabled && this.isAdult)
        return this.$tExistsOrDefault('calculatePremium.general.cardDisabled');
      return '';
    },
    isChild() {
      return this.person.typeOfInsured === 'M';
    },
    price() {
      if (this.product.packageTypeForWebApplications === 'OW') {
        return this.person.ownRiskDiscount;
      } else if (!this.premiumIsEqualForAllPersons) {
        return this.person.netPremiumWithDiscount;
      }
      return null;
    },
  },
  methods: {
    handleSelect() {
      if (this.person.isDisabled && this.product.packageTypeForWebApplications === 'MB') {
        this.$store.dispatch(Modal.Actions.OPEN, {
          modalData: ModalDataFactory.createRemoveAllPackagesWhenDisabledBaiscInsuranceSelected(
            this.product,
            this.person,
          ),
          isPersistent: true,
        });
      } else if (!this.person.isDisabled) {
        let action =
          this.isChecked === false
            ? BusinessRule.Actions.SELECT_PRODUCT_FOR_PERSON
            : BusinessRule.Actions.REMOVE_PRODUCT_FOR_PERSON;
        this.$store
          .dispatch(action, {
            product: this.product,
            person: this.person,
          })
          .then(() => {
            this.$store.dispatch(BusinessRule.Actions.POST);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 16px;
  font-family: var(--text-font);
  &.dateOfBirth {
    font-size: 14px;
    margin-left: 5px;
  }
}

// If the checkbox is the first one, add some margin to the top (8px)
#select-product-for-person-button-wrapper:has(+ #select-product-for-person-button-wrapper) {
  margin-top: 8px;
}

// If the checkbox is not the first one, add some margin to the top (14px)
#select-product-for-person-button-wrapper + #select-product-for-person-button-wrapper {
  margin-top: 14px;
}
</style>
@/components/shared/modals/ModalDataFactory.js
