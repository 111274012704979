<template>
  <section class="footer">
    <HorizontalRule />
    <div class="footer-items" :class="{ mobile: $screen.xs }">
      <a :href="homepage" target="_blank">
        <WhiteLabelLogo class="whitelabel-logo" :only-show-small-logo="false" v-if="$screen.gt.xs" />
      </a>

      <div class="links">
        <LinkText :href="$tExistsOrDefault('main.footer.disclaimerLink')" target="_blank">
          {{ $tExistsOrDefault('main.footer.disclaimer') }}
        </LinkText>
        <LinkText :href="$tExistsOrDefault('main.footer.privacyLink')" target="_blank">
          {{ $tExistsOrDefault('main.footer.privacy') }}
        </LinkText>
        <LinkText :href="$tExistsOrDefault('main.footer.cookiesLink')" target="_blank">
          {{ $tExistsOrDefault('main.footer.cookies') }}
        </LinkText>
      </div>

      <PlainText>{{ $tExistsOrDefault('main.footer.eucare') }}</PlainText>
    </div>
    <div class="space-for-buttons" v-if="$screen.xs" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import HorizontalRule from '@/components/shared/HorizontalRule.vue';
import WhiteLabelLogo from '@/components/viewelements/app/WhiteLabelLogo.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'Footer',
  components: {
    WhiteLabelLogo,
    HorizontalRule,
    LinkText,
    PlainText,
  },
  computed: {
    ...mapGetters(['labelSettings']),
    isLabelAevitae() {
      return this.labelSettings.label === 'aev';
    },
    homepage() {
      return this.labelSettings.homepage;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-items {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-items.mobile {
  flex-flow: column;
}

.whitelabel-logo {
  height: 38px;
}

.links {
  display: flex;
  gap: 34px;
}

.space-for-buttons {
  height: 40px;
}
</style>
