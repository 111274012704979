import axios from 'axios';

const config = {
  VERSION: '',
  API: {
    BASE_URL: '',
  },
  COLLECTIVITY_LABEL_SETTINGS: [],
  ENVIRONMENT: '',
};

export async function loadConfig() {
  if (!config.initialized) {
    try {
      const response = await axios.get('/config.json', {
        headers: { 'Cache-control': 'no-cache' },
      });
      config.VERSION = response.data.Application.releaseTag;
      config.API.BASE_URL = response.data.api.baseUrl;
      config.COLLECTIVITY_LABEL_SETTINGS = response.data.collectivityLabelSettings;
      config.ENVIRONMENT = response.data.environment;
    } catch (error) {
      console.error('Failed to load config:', error);
    }
  }
}

export default config;
