<template>
  <h1>
    <slot />
  </h1>
</template>
<script>
export default {
  name: 'H1',
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-family: var(--text-font-semibold);
  color: var(--color-primary);
  margin-bottom: 0;
}
</style>
