const Actions = {
  SET: 'a.search.set',
  CLEAR_SEARCH: 'a.search.clear-search',
};

const Mutations = {
  SET: 'm.search.set',
  CLEAR_SEARCH: 'm.search.clear-search',
  SET_LOADING: 'm.contract.set-is-loading',
};

export default {
  Actions,
  Mutations,
};
