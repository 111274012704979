<template>
  <div class="form-wrapper mt-3">
    <H3>{{ $tExistsOrDefault('insured.calculatePremium') }}</H3>

    <CalculatePremiumInsuredMain />
    <CalculatePremiumInsuredPartner />
    <CalculatePremiumInsuredChildren />
  </div>
</template>

<script>
import CalculatePremiumInsuredMain from '@/components/viewelements/insured/CalculatePremiumInsuredMain.vue';
import CalculatePremiumInsuredPartner from '@/components/viewelements/insured/CalculatePremiumInsuredPartner.vue';
import CalculatePremiumInsuredChildren from '@/components/viewelements/insured/CalculatePremiumInsuredChildren.vue';
import H3 from '@/components/shared/typography/H3.vue';

export default {
  name: 'CalculatePremiumForm',
  components: {
    CalculatePremiumInsuredMain,
    CalculatePremiumInsuredPartner,
    CalculatePremiumInsuredChildren,
    H3,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 24px;
}

:deep() .vs__dropdown-toggle {
  height: 60px !important;
  background-color: white !important;
}
</style>
