<template>
  <div class="mt-4 mx-md-5 succes-dialog">
    <img class="mt-4 mb-4 kite" src="/images/kite.png" alt="Masked image" />
    <TitleAndParagraph
      class="mt-4"
      :title="$tExistsOrDefault('overview.revisit-email-popup.succes-title')"
      :paragraph="$tExistsOrDefault('overview.revisit-email-popup.succes-paragraph')"
    />
    <div class="mt-3 mb-3">
      <Button @click="onClose()" :class="{ 'col-auto': $screen.xs }" class="mt-5 mb-5 mr-2">
        {{ $tExistsOrDefault('overview.revisit-email-popup.succes-close') }}
      </Button>
    </div>
  </div>
</template>
<script>
import TitleAndParagraph from '@/components/shared/typography/TitleAndParagraph.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  emits: ['onClose'],
  name: 'RevisitEmailSuccesDialog',
  components: {
    TitleAndParagraph,
    Button,
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="scss" scoped>
.succes-dialog {
  text-align: center;
  max-width: 650px;
}

.img.kite {
  width: 115.6px;
  height: 100.3px;
  object-fit: contain;
}
</style>
