<template>
  <div class="d-flex flex-column">
    <CloseButton class="align-self-end" v-if="modalData.showClose" @click="handleClose()" />

    <div class="modal-header">
      <H1>{{ $tExistsOrDefault(modalData.title) }}</H1>
    </div>

    <div class="modal-body">
      <PlainText v-if="modalData.text" v-html="$tExistsOrDefault(modalData.text)" />
      <PlainText v-if="modalData.text2" v-html="$tExistsOrDefault(modalData.text2)" class="mt-2" />
      <div v-if="modalData.formComponent">
        <component @form-submitted="handleClose" :is="modalData.formComponent" />
      </div>
    </div>

    <div v-if="modalData.buttons?.length > 0" class="modal-buttons pt-3 d-flex">
      <Button
        v-for="(button, index) in modalData.buttons"
        :key="index"
        :layout-type="button.isPrimary ? 'primary' : 'secondary'"
        @click="button.action"
      >
        {{ $tExistsOrDefault(button.text) }}
      </Button>
    </div>
  </div>
</template>

<script>
import Modal from '@/constants/modal';
import RevisitEmailFormModal from '@/components/shared/modals/revisitEmail/RevisitEmailFormModal.vue';
import CloseButton from '@/components/shared/button/CloseButton.vue';
import H1 from '@/components/shared/typography/H1.vue';
import Button from '@/components/shared/button/Button.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'DataModal',
  components: {
    CloseButton,
    RevisitEmailFormModal,
    H1,
    Button,
    PlainText,
  },
  props: {
    modalData: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        showClose: false,
        buttons: [],
        closeAction: null,
        formComponent: null,
      }),
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch(Modal.Actions.CLOSE);
      if (this.modalData.closeAction) {
        this.modalData.closeAction();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  border-radius: 0px;
  border: 0;
}

.modal-buttons {
  padding-left: 1rem;
  flex-flow: wrap;
  gap: 15px;
}
</style>
