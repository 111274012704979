<template>
  <Field
    :rules="rules"
    v-slot="{ handleBlur, handleChange, meta, errors }"
    :name="fieldName"
    :value="initialValue"
    ref="fieldRef"
  >
    <div :class="{ 'is-invalid': errors[0] }">
      <slot :handle-blur="handleBlur" :handle-change="($event) => handleChange($event, meta.touched)" />
    </div>

    <div :class="{ 'validation-message': shouldHaveSpaceBetween }">
      <ValidationMessage
        v-if="reserveValidationSpace && errors.length < 2"
        :class="{ show: errors.length == 1, hidden: errors.length === 0 }"
      >
        {{ $tExistsOrDefault(errors[0]) }}
      </ValidationMessage>
      <ValidationMessage v-else v-for="error in errors" :key="error">
        {{ $tExistsOrDefault(error) }}
      </ValidationMessage>
    </div>
  </Field>
</template>

<script>
import ValidationMessage from '@/components/shared/input/validation/ValidationMessage.vue';
import { Field } from 'vee-validate';

export default {
  name: 'FormFieldValidation',
  components: {
    ValidationMessage,
    Field,
  },
  props: {
    rules: {
      type: [String, Object],
    },
    shouldHaveSpaceBetween: {
      type: Boolean,
      default: true,
    },
    reserveValidationSpace: {
      type: Boolean,
      default: false,
    },
    fieldName: {
      type: String,
      default: '',
    },
    initialValue: {
      type: [String, Boolean, Number, Object, Array],
      required: false,
    },
  },
  methods: {
    validate() {
      return this.$refs.fieldRef.validate();
    },
    resetField(currentValue) {
      this.$refs.fieldRef.reset({ value: currentValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-message {
  margin-top: 0.5rem;
}
.show {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
</style>
