<template>
  <Container :padded="false" v-if="!$screen.xs">
    <Card>
      <slot />
    </Card>
  </Container>
  <Container v-else :background-alt="true">
    <slot />
  </Container>
</template>

<script>
import Card from '@/components/shared/card/Card.vue';
import Container from '@/components/shared/Container.vue';

export default {
  name: 'CardDesktopOnly',
  components: {
    Card,
    Container,
  },
};
</script>
