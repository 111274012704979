<template>
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" stroke="currentColor">
      <circle stroke-width="2.5" cx="10" cy="10" r="8.75" />
      <path stroke-width="4" stroke-linecap="round" d="m18 18 5 5" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Magnifier',
};
</script>
