<template>
  <div class="healtcare-provider-info">
    <div
      v-for="healthCareProvider in healthCareProviders"
      :key="healthCareProvider.uniqueId"
      class="healthcareProviderTriangle"
      @click="$emit('clickHealthCareProvider', healthCareProvider)"
    >
      <div class="healthcare-provider-content">
        <PlainText class="healthcare-provider-name">
          {{ healthCareProvider.name }}
        </PlainText>
        <div class="icon-and-text">
          <HealthcareProviderContracted :year="selectedYear" :contracted-type="healthCareProvider.contractType" />
        </div>

        <div class="icon-and-text">
          <Location />
          <PlainText>
            {{ healthCareProvider.street }}
            {{ healthCareProvider.housenumber }},
            {{ healthCareProvider.zipcode }}
            {{ healthCareProvider.city }}
          </PlainText>
        </div>
      </div>

      <div class="center-chevron">
        <Chevron :size="12" direction="right" :color="chrevonColor()" />
      </div>
    </div>
  </div>
</template>

<script>
import HealthcareProviderContracted from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderContracted.vue';
import Chevron from '@/components/shared/icons/Chevron.vue';
import Location from '@/components/shared/icons/Location.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'HealthcareProviderInfo',
  emits: ['clickHealthCareProvider'],
  props: {
    healthCareProviders: {
      default: () => [],
      type: Array,
    },
    selectedYear: {
      required: true,
      type: Number,
    },
  },
  components: {
    HealthcareProviderContracted,
    Chevron,
    Location,
    PlainText,
  },
  methods: {
    chrevonColor() {
      return 'var(--color-primary)';
    },
  },
};
</script>

<style scoped lang="scss">
.healtcare-provider-info {
  cursor: pointer;
}
.healthcareProviderTriangle {
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 3px;
  border: solid 1px var(--color-grey-300);
  display: flex;
  justify-content: space-between;
}
.healthcare-provider-content {
  > * {
    margin-bottom: 5px;
  }
}
.healthcare-provider-name {
  font-weight: bold;
}
.icon-and-text {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child {
    flex-shrink: 0;
    height: 22px;
  }
}

.center-chevron {
  align-self: center;
}
</style>
