<template>
  <div class="overlay-container">
    <div class="content" :class="{ disabled: showOverlay }">
      <slot name="content" />
    </div>
    <div v-if="showOverlay" class="overlay-background h-100 w-100">
      <slot name="overlay" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    showOverlay: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  display: grid;
}
.content,
.overlay-background {
  grid-area: 1 / 1;
}
.overlay-background {
  background: rgba(109, 109, 109, 0.15);
  z-index: 0;
}
.overlay-background:hover {
  cursor: progress;
}
.disabled {
  pointer-events: none;
}
</style>
