<template>
  <svg viewBox="0 0 24 29" xmlns="http://www.w3.org/2000/svg" preserve-aspect-ratio="xMidYMid meet">
    <g transform="translate(0 .75)" fill="none" fill-rule="evenodd">
      <circle stroke="currentColor" cx="12" cy="14.25" r="11.5" />
      <text font-family="SourceSansPro-Bold, Source Sans Pro" font-size="22" font-weight="bold" fill="currentColor">
        <tspan x="8.52" y="22">!</tspan>
      </text>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ExclamationMark',
};
</script>
