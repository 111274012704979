<template>
  <!-- payment details -->
  <section class="payment-details-container" :class="{ 'payment-details-mobile': $screen.lt.sm }">
    <div>
      <H3 class="mb-3">
        {{ $tExistsOrDefault('overview.paymentDetails') }}
      </H3>
      <PlainText v-if="selectedContract.paymentMethodCode === 'B' || selectedContract.paymentMethodCode === 'I'">
        {{ paymentMethod }}
      </PlainText>
      <div v-else>
        <PlainText class="d-inline" type="secondary">
          {{ $tExistsOrDefault('calculatePremium.paymentTerm.pay') }}
        </PlainText>
        <PlainText class="d-inline">
          {{ $tExistsOrDefault(`calculatePremium.paymentTerm.paymentTerms.${selectedPaymentTermInConfiguration}`) }}
        </PlainText>
        <PlainText class="d-inline" type="secondary"> via </PlainText>
        <PlainText class="d-inline">
          {{ paymentMethod }}
        </PlainText>
      </div>
      <div>
        <PlainText class="d-inline" type="secondary"> {{ $tExistsOrDefault('overview.ibanAndBic.label') }}: </PlainText>
        <PlainText class="d-inline">
          {{ agreement.paymentInformation.primaryBankAccountNumber }}
        </PlainText>
      </div>
    </div>

    <ButtonPrevious @click="gotoPersonalData">
      {{ $tExistsOrDefault('overview.button.changePaymentDetails') }}
    </ButtonPrevious>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';

import H3 from '@/components/shared/typography/H3.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'OverviewTotalPremium',
  components: {
    ButtonPrevious,
    H3,
    PlainText,
  },

  computed: {
    ...mapGetters(['agreement', 'selectedPaymentTermInConfiguration', 'selectedContract']),
    paymentMethod() {
      if (this.agreement.PaymentMethodForCosts === 'AUT' || this.agreement.paymentMethod === 'AUT') {
        return this.$tExistsOrDefault('overview.automaticCollection');
      } else {
        return this.$tExistsOrDefault('overview.ideal');
      }
    },
  },
  methods: {
    gotoPersonalData(event) {
      if (event) event.preventDefault();
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$store.getters.selectedContractCode}/personal_extra_data`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-details-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.payment-details-mobile {
  flex-direction: column;
  gap: 24px;
}
</style>
