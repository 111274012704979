<template>
  <div>
    <CalculatePremiumInsuredChild
      v-for="insuredChild in insuredChildren"
      :key="'insured-child-' + insuredChild.id"
      :insured="insuredChild"
      :id-child="insuredChild.id"
    />
    <CalculatePremiumInsuredChild :insured="null" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BusinessRule from '@/constants/business-rule';
import CalculatePremiumInsuredChild from '@/components/viewelements/insured/CalculatePremiumInsuredChild.vue';

export default {
  name: 'CalculatePremiumInsuredChildren',
  components: {
    CalculatePremiumInsuredChild,
  },
  computed: {
    ...mapGetters(['insured']),
    insuredChildren() {
      return this.insured.filter((i) => i.typeOfInsured === 'M');
    },
  },
  methods: {
    handleAddChild() {
      this.$store.commit(BusinessRule.Mutations.ADD_INSURED, {
        typeOfInsured: 'M',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
