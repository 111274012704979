<template>
  <div v-if="selectedContract">
    <!-- Intermediary pays -->
    <PlainText v-if="selectedContract.paymentMethodCode === 'I'">
      {{ $tExistsOrDefault('calculatePremium.paymentTerm.intermediary') }}
    </PlainText>
    <!-- Borderel pays -->
    <PlainText v-else-if="selectedContract.paymentMethodCode === 'B'">
      {{ $tExistsOrDefault('calculatePremium.paymentTerm.employer') }}
    </PlainText>

    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import PlainText from '@/components/shared/typography/PlainText.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'PaymentTermSlot',
  components: {
    PlainText,
  },
  computed: {
    ...mapGetters(['selectedContract']),
  },
};
</script>

<style lang="scss" scoped></style>
