import Config from '@/constants/config';

const Actions = {
  GetCancelationServiceActive: 'a.settings.cancelation-service-active',
};

const Mutations = {
  SET_CANCELATION_SERVICE_ACTIVE: 'm.settings.set-cancelation-service',
  SET_CANCELATION_LOADING: 'm.settings.set-cancelation-loading',
};

const Endpoints = {
  cancelationService: () => `${Config.API.BASE_URL}/api/settings/CancelationServiceActive`,
};

export default {
  Actions,
  Mutations,
  Endpoints,
};
