<template>
  <PartialOverlayWithCloseButton v-model="modelValue">
    <div>
      <div>
        <H1 class="mt-2">
          {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.title') }}
        </H1>
        <H2 class="mt-2">
          {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.sub-title') }}
        </H2>
      </div>
      <section>
        <PlainText class="mt-2">
          {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.give-permission') }}
        </PlainText>
        <div class="mt-3">
          <div
            v-if="agreement.possiblePaymentMethods.includes('I') || agreement.possiblePaymentMethods.includes('B')"
            v-html="$tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.cost-types-without-premium')"
          />
          <div v-else>
            <div v-html="$tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.cost-types')" />
            <PlainText class="mt-3">
              {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.collect') }}
            </PlainText>
          </div>
        </div>
        <PlainText class="mt-3">
          {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.reverse-collection') }}
        </PlainText>
      </section>
      <section>
        <div class="mt-3 mb-4">
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.creditor') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.name') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.id') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.address') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.zipcode') }}
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.city') }}
          </PlainText>
          <PlainText>
            {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.additionalInfo') }}
          </PlainText>
        </div>
      </section>
      <div class="stick-bottom">
        <Button @click="onConfirm" layout-type="action">
          {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.agreed') }}
        </Button>
      </div>
    </div>
  </PartialOverlayWithCloseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import PartialOverlayWithCloseButton from '@/components/shared/overlay/PartialOverlayWithCloseButton.vue';
import H1 from '@/components/shared/typography/H1.vue';
import H2 from '@/components/shared/typography/H2.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  emits: ['update:modelValue', 'onAccept'],
  name: 'AutomaticCollectionOverlay',
  components: {
    PartialOverlayWithCloseButton,
    H1,
    H2,
    PlainText,
    Button,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters(['agreement']),
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
  },
  methods: {
    closeOverlay() {
      this.modelValue = false;
    },
    onConfirm() {
      this.$emit('onAccept', true);
      this.closeOverlay();
    },
  },
};
</script>

<style lang="scss" scoped>
.stick-bottom {
  position: sticky;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: white;
}
</style>
