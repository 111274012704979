<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    xmlns="http://www.w3.org/2000/svg"
    preserve-aspect-ratio="xMidYMid meet"
  >
    <rect fill="currentColor" x="3" y="3" width="5" height="5" rx="2.5" fill-rule="evenodd" />
  </svg>
</template>
<script>
export default {
  name: 'BulletPoint',
};
</script>
