<template>
  <div class="content-wrapper">
    <H1 :class="{ 'mb-0': titleHasNoMarginBottom }">
      {{ title }}
    </H1>
    <p v-if="paragraph" v-html="paragraph" />
  </div>
</template>

<script>
import H1 from '@/components/shared/typography/H1.vue';
export default {
  name: 'TitleAndParagraph',
  components: {
    H1,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: false,
    },
    titleHasNoMarginBottom: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin: 0 auto;
}
h1 {
  margin-bottom: 1rem;
}
p {
  margin-bottom: 1rem;
}
</style>
