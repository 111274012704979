<template>
  <transition>
    <div
      v-if="value"
      class="overlay-container will-change"
      :class="overlayContainerConditionalStyle"
      @mousedown.stop="onClose()"
    >
      <div
        class="overlay-content px-0"
        :class="{
          disabled: disabled || loading,
          'sized-window': sizedWindow,
          'd-flex justify-content-center align-items-center mobile p-0': sizedWindow && $screen.xs,
        }"
        @mousedown.stop
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Modal from '@/constants/modal';

export default {
  name: 'FullscreenOverlay',
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    disabled: Boolean,
    loading: Boolean,
    sizedWindow: Boolean,
    sizedWindowFlexPosition: { type: String, default: 'center' },
    padded: { type: Boolean, default: false },
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (this.modelValue) {
        this.onClose();
        next(false);
      } else {
        next();
      }
    });
  },
  computed: {
    overlayContainerConditionalStyle() {
      return {
        'loading disabled': this.loading,
        padded: this.padded,
        'd-flex align-items-center': this.sizedWindow && this.$screen.gt.xs,
        [`justify-content-${this.sizedWindow ? this.sizedWindowFlexPosition : 'center'}`]: true,
        'z-index-high': this.$screen.xs,
      };
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          this.$store.commit(Modal.Mutations.SHOW_OVERLAY, v);
          document.documentElement.classList.add('disabled-scroll');
        } else {
          setTimeout(() => {
            this.$store.commit(Modal.Mutations.SHOW_OVERLAY, v);
            document.documentElement.classList.remove('disabled-scroll');
          }, 500);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  overflow: auto;
  z-index: 2147483002; // intercom-lightweight-app has z-index 2147483001
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &.padded {
    background-color: rgba(24, 37, 50, 0.5);
    padding: 2rem;
    .overlay-content {
      border-radius: 10px;
      box-shadow: 0 3px 20px rgba(0, 0, 20, 0.33);
    }
  }

  &.loading:hover {
    pointer-events: auto;
    cursor: progress !important;
  }
}
.overlay-content {
  background: white;
  min-height: 100%;
  &.sized-window {
    width: 50%;
    max-width: 700px;
    min-height: fit-content;
    height: fit-content;
    &:not(.mobile) {
      min-width: 460px;
    }
  }
  &.mobile {
    width: 100%;
    max-width: 100%;
  }
  &.disabled {
    pointer-events: none;
  }
}

.v-enter-active {
  transition: all 0.5s ease-out;
  will-change: transform;
}

.v-leave-active {
  transition: all 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(2000px);
}

.z-index-high {
  z-index: 2147483004;
}
</style>
