<template>
  <div v-if="isHeaderColumn" class="title-section invisible">
    <p class="card-title">hidden</p>
    <div class="card-price d-flex flex-column mx-auto" style="width: fit-content">
      <span class="from-price text-left">hidden</span>
      <div class="mt-n1">
        hidden
        <span class="term">hidden</span>
      </div>
    </div>
    <hr />
  </div>

  <div class="title-section" v-else>
    <CardHeader class="card-title">
      {{ product.title }}
    </CardHeader>
    <ProductPremium :class="{ invisible: isBasicInsurance }" :product="filterProduct" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductPremium from '@/components/viewelements/configuration/insuranceCard/ProductPremium';
import productHelper from '@/helpers/product';
import CardHeader from '@/components/shared/typography/CardHeader.vue';

export default {
  name: 'CompareReimbursementHeaderDesktop',
  props: {
    product: {
      type: Object,
      required: true,
    },
    isHeaderColumn: Boolean,
  },
  components: {
    ProductPremium,
    CardHeader,
  },

  computed: {
    ...mapGetters(['startDate']),
    isBasicInsurance() {
      return this.product.packageTypeForWebApplications === 'MB';
    },
    filterProduct() {
      return {
        ...this.product,
        persons: productHelper.filteredPersonsForProduct(this.product, this.startDate),
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// Not clean, but conditional visibility in child component overwrites the visibility property. Even !important doesn't fix it
.invisible {
  opacity: 0;
}
</style>
