<template>
  <div
    v-tooltip="{
      content: shouldShowInformation && !usePopup ? $tExistsOrDefault(text) : '',
      placement: position,
    }"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
import Modal from '@/constants/modal';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';

export default {
  name: 'ExtraInformationContainer',
  props: {
    position: {
      type: String,
      default: 'top',
      validator(value) {
        return ['top', 'bottom', 'left', 'right'].includes(value);
      },
    },
    text: {
      type: String,
      required: true,
    },
    title: String,
    alwaysUsePopup: Boolean,
    shouldShowInformation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    usePopup() {
      return this.alwaysUsePopup || this.$screen.lt.md;
    },
  },
  methods: {
    onClick() {
      if (this.shouldShowInformation && this.usePopup && this.text) {
        this.$store.dispatch(Modal.Actions.OPEN, {
          modalData: ModalDataFactory.create(
            this.title ? this.title : this.$tExistsOrDefault('modal.general.information'),
            this.text,
          ),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
@/components/shared/modals/ModalDataFactory
