import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';

/* fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleNotch, faMagnifyingGlass, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { i18n } from './i18n';
import tExistsOrDefault from './plugins/tExistsOrDefault';
import screenPlugin from './plugins/screen';

import { loadConfig } from '@/constants/config';
import vueDebounce from 'vue-debounce';
import { vMaska } from 'maska/vue';
import './validation/custom-validation-rules';
import VueTelInput from 'vue-tel-input';
import vSelect from 'vue-select';
import FloatingVue from 'floating-vue';
import Toast from 'vue-toastification';
import { useToast } from 'vue-toastification';

const app = createApp(App).use(i18n).use(tExistsOrDefault, { store, i18n }).use(store);

loadConfig().then(() => {
  //initialize router here so the load config has loaded the api url and can be used to fetch the languages.
  //router first triggers the beforeEach which triggers the language set.
  //initialization happens in the router and also changing the language
  app.use(router);

  router.isReady().then(() => app.mount('#app'));
});

// fontawesome icons
library.add([faCircleNotch, faMagnifyingGlass, faChevronDown]);
app.component('FontAwesomeIcon', FontAwesomeIcon);

app.component('VSelect', vSelect);
app.use(Toast, {
  transition: 'Vue-Toastification__fade',
  containerClassName: 'toast-container-class',
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.some((t) => t.type === toast.type)) {
      return false;
    }
    return toast;
  },
});

// FloatingVue
app.use(FloatingVue, {
  strategy: 'fixed',
  themes: {
    oaz: {
      $extend: 'dropdown',
    },
  },
});
app.directive('maska', vMaska);
app.directive('debounce', vueDebounce({ lock: true }));
app.use(VueTelInput);

app.directive('focus', {
  mounted(el, binding) {
    if (binding.value !== false) {
      el.focus();
    }
  },
});

app.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

app.use(screenPlugin, store);

app.config.globalProperties.$toast = useToast();

export { i18n, app };
