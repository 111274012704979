<template>
  <div class="insurancecard-header">
    <div class="text-center">
      <div id="card-title">
        <CardHeader class="d-inline">
          {{ title }}
        </CardHeader>
        <LevelIndicator v-if="showLevelIndicator()" :level="level.level" :max-level="level.maxLevel" />
      </div>
      <PlainText v-if="subtitle" type="secondary">
        {{ subtitle }}
      </PlainText>
    </div>
    <ProductPremium :product="product" />
  </div>
</template>

<script>
import CardHeader from '@/components/shared/typography/CardHeader.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import LevelIndicator from '@/components/viewelements/configuration/insuranceCard/LevelIndicator.vue';
import ProductPremium from '@/components/viewelements/configuration/insuranceCard/ProductPremium.vue';

export default {
  name: 'InsuranceCardHeader',
  components: {
    CardHeader,
    PlainText,
    LevelIndicator,
    ProductPremium,
  },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    level: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showLevelIndicator() {
      return this.level && this.level.maxLevel > 1 && this.level.maxLevel < 6;
    },
  },
};
</script>

<style lang="scss" scoped></style>
