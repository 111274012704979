<template>
  <Dropdown v-model="modelValue" :options="allDistanceOptions" />
</template>

<script>
import Dropdown from '@/components/shared/input/Dropdown.vue';

export default {
  emits: ['input'],
  name: 'DistanceDropdown',
  components: {
    Dropdown,
  },
  data() {
    return {
      allDistanceOptions: [
        {
          value: 5000,
          text: 'calculatePremium.basicInsurance.hospitalsOverlay.distances.fiveKm',
        },
        {
          value: 10000,
          text: 'calculatePremium.basicInsurance.hospitalsOverlay.distances.tenKm',
        },
        {
          value: 50000,
          text: 'calculatePremium.basicInsurance.hospitalsOverlay.distances.fiftyKm',
        },
        {
          value: 100000,
          text: 'calculatePremium.basicInsurance.hospitalsOverlay.distances.hundredKm',
        },
        {
          value: 0,
          text: 'calculatePremium.basicInsurance.hospitalsOverlay.distances.all',
        },
      ],
    };
  },
  props: {
    value: Number,
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
