export default {
  matchHeightForElement(elementName) {
    const packages = document.querySelectorAll(elementName);

    let maxHeight = 0;

    packages.forEach((el) => {
      el.style.height = 'auto'; // Reset height to get natural height
      maxHeight = Math.max(maxHeight, el.offsetHeight);
    });

    packages.forEach((el) => {
      el.style.height = `${maxHeight}px`; // Apply max height
    });
  },
};
