<template>
  <div
    id="container-component-wrapper"
    :class="{
      padded: padded,
      'background-alt': backgroundAlt,
      'max-width-container': !fullWidth,
    }"
  >
    <div id="slot-wrapper" :class="{ padded: padded, 'max-width-container': !fullWidth }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    backgroundAlt: {
      type: Boolean,
      default: false,
    },
    padded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#container-component-wrapper:not(.background-alt) {
  background-color: var(--color-grey-050); //if changed, also change the background-color of the app-base in App.vue
}
#container-component-wrapper.background-alt {
  background-color: var(--color-white);
}

#container-component-wrapper #slot-wrapper.padded {
  padding: 32px 24px;
}

#container-component-wrapper #slot-wrapper.max-width-container {
  max-width: 1080px;
  margin: 0 auto;
}
</style>
