<template>
  <div class="position-relative">
    <div
      id="dropdown-wrapper"
      class="form-control"
      :class="{ disabled: disabled, 'style-white': color === 'white' }"
      :tabindex="disabled ? -1 : 0"
      @click="openDropdown = !openDropdown"
      @blur="blur()"
    >
      <div class="d-flex align-items-center" :class="color">
        {{ useTranslationKeys ? $tExistsOrDefault(displayedValue) : displayedValue }}
      </div>
      <div class="arrow">
        <Chevron :size="12" :color="chrevonColor" />
      </div>
    </div>
    <DropdownList
      @update:model-value="updateModelValue"
      :model-value="modelValue"
      :open-dropdown="openDropdown"
      :use-translation-keys="useTranslationKeys"
      :options="options"
    />
  </div>
</template>

<script>
import Chevron from '@/components/shared/icons/Chevron.vue';
import DropdownList from '@/components/shared/input/DropdownList.vue';

export default {
  name: 'Dropdown',
  emits: ['update:modelValue', 'blur'],
  components: {
    Chevron,
    DropdownList,
  },
  props: {
    useTranslationKeys: {
      type: Boolean,
      default: true,
    },
    modelValue: null,
    options: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'white'].includes(value);
      },
    },
    disabled: Boolean,
  },
  data() {
    return {
      openDropdown: false,
    };
  },
  computed: {
    displayedValue() {
      const result = this.options.find((x) => x.value === this.modelValue);
      return result ? result.text : null;
    },
    chrevonColor() {
      if (this.color === 'white') {
        return 'var(--color-white)';
      }
      return this.disabled ? 'var(--color-grey-400)' : 'var(--color-primary-800)';
    },
  },
  methods: {
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
    blur() {
      this.openDropdown = false;
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
#dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  &.disabled {
    pointer-events: none;
    border-color: var(--color-grey-400);
    > * {
      color: var(--color-grey-400) !important;
    }
  }
  &.style-white {
    background-color: inherit;
    border-color: var(--color-white);
  }
}

.default {
  color: var(--color-grey-900);
}

.white {
  color: var(--color-white);
}

.option-style {
  font-family: var(--input);
  font-size: 16px;
  letter-spacing: normal;
  color: var(--color-grey-900);
}
</style>
