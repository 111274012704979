<template>
  <div>
    <div
      class="choice-other-collectivity-text"
      :class="{ mobile: $screen.xs }"
      v-if="selectedCollectivityChoice !== collectivityChoiceNee"
    >
      <H3>{{ $tExistsOrDefault('main.yourCollectivity') }}</H3>
      <LinkText @click="clearSelectedContract()">
        {{ $tExistsOrDefault('main.choiceOtherCollectivity') }}
      </LinkText>
    </div>

    <ContractAdvantages class="contract-advantages" :contract-code="contractCode" :contract="selectedContract" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CollectivityChoice from '@/constants/CollectivityChoice';
import BusinessRule from '@/constants/business-rule';
import Contracts from '@/constants/contracts';
import LogoConst from '@/constants/logos';

import H3 from '@/components/shared/typography/H3.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';

import ContractAdvantages from '@/components/viewelements/home/ContractAdvantages.vue';

export default {
  name: 'ContractOverview',
  data() {
    return {
      collectivityChoiceNee: CollectivityChoice.CollectivityChoiceEnum.Nee,
    };
  },
  props: {
    contractCode: {
      type: Number,
      required: true,
    },
  },
  components: {
    H3,
    LinkText,
    ContractAdvantages,
  },
  computed: {
    ...mapGetters(['selectedCollectivityChoice', 'selectedContract']),
    searchOptions() {
      return this.$store.getters.contracts.map((x) => x.contractName);
    },
  },
  methods: {
    clearSelectedContract() {
      this.$router.push({ name: 'home', params: { lang: this.$i18n.locale } });
    },
    clearComponent() {
      this.$store.commit(BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR, null);
      this.$store.commit(LogoConst.Mutations.CLEAR);
      this.$store.commit(Contracts.Mutations.CLEAR);
      this.$store.commit(BusinessRule.Mutations.CLEAR);
    },
    initializeComponent() {
      this.$store.commit(Contracts.Mutations.SET_SELECTED_ID, this.contractCode);
      this.$store.dispatch(Contracts.Actions.LOAD_EJW_INFO);
      this.$store.commit(BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR, this.contractCode);
      this.$store.dispatch(LogoConst.Actions.LOAD_BY_CONTRACT_CODE, this.contractCode);
      this.$store.dispatch(Contracts.Actions.LOAD_CONTRACT, this.$store.getters.businessRule.lastPolicyYear);
    },
  },
  mounted() {
    this.initializeComponent();
  },
  beforeRouteLeave(to, from, next) {
    if (!to.params.contractCode) {
      this.clearComponent();
    }

    next();
  },
  watch: {
    contractCode() {
      if (this.contractCode) {
        this.clearComponent();
        this.initializeComponent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choice-other-collectivity-text {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}

.choice-other-collectivity-text.mobile {
  flex-direction: column;
}

.contract-advantages {
  margin-bottom: 36px;
}
</style>
