const Actions = {
  SET_DISABLED_PACKAGE: 'a.tdp.set-disabled-package',
  RESET_PACKAGE_DATA: 'a.tdp.reset-package-data',
  UPDATE_TOOLTIP_VISIBILITY: 'a.tdp.update-tooltip-visibility',
  LOCK_TOOLTIP: 'a.tdp.lock-tooltip',
};

const Mutations = {
  SET_PACKAGE_DATA: 'm.tdp.set-package-data',
  UPDATE_TOOLTIP_VISIBILITY: 'm.tdp.update-tooltip-visibility',
  LOCK_TOOLTIP: 'm.tdp.lock-tooltip',
};

export default {
  Actions,
  Mutations,
};
