import { createRouter, createWebHistory } from 'vue-router';
import { store } from '@/store';
import Contracts from '@/constants/contracts';
import CollectivityChoice from '@/constants/CollectivityChoice';
import ContractCodes from '@/constants/ContractCodes';

import Home from '@/views/Home.vue';
import ContractOverview from '@/views/ContractOverview.vue';
import Insured from '@/views/Insured.vue';
import PersonalData from '@/views/PersonalData.vue';
import PersonalExtraData from '@/views/PersonalExtraData.vue';
import Configuration from '@/views/Configuration.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import Overview from '@/views/Overview.vue';
import Complete from '@/views/Complete.vue';
import RevisitLoader from '@/views/RevisitLoader.vue';
import { i18n, app } from '@/main.js';
import { nextTick } from 'vue';
import { setI18nLanguage, hasInitialized } from '@/i18n.js';

const routes = [
  {
    path: '/',
    redirect: {
      name: 'home',
      params: {
        lang: 'nl',
      },
    },
  },
  {
    name: 'home',
    path: '/:lang(nl|en)',
    component: Home,
    meta: {
      logo: true,
    },
    children: [
      {
        name: 'contract-overview',
        path: ':contractCode(\\d+)',
        component: ContractOverview,
        meta: {
          logo: true,
        },
        props(route) {
          const props = {
            ...route.params,
          };
          props.contractCode = parseInt(props.contractCode, 10);

          if (props.contractCode === ContractCodes.Laef) {
            store.dispatch(Contracts.Actions.SET_COLLECTIVITY_CHOICE, CollectivityChoice.CollectivityChoiceEnum.Nee);
          }

          return props;
        },
      },
    ],
  },
  {
    path: '/:contractCode(\\d+)',
    redirect: (to) => ({
      name: 'contract-overview',
      params: {
        lang: 'nl',
        contractCode: to.params.contractCode,
      },
    }),
  },
  {
    name: 'insured',
    path: '/:lang(nl|en)/:contractCode(\\d+)/insured',
    component: Insured,
    props(route) {
      const props = {
        ...route.params,
      };
      props.contractCode = parseInt(props.contractCode);
      return props;
    },
    meta: {
      step: 1,
      stepProgress: 1,
      title: 'tabbladTitles.insuredPage',
    },
  },

  {
    path: '/:lang(nl|en)/:contractCode(\\d+)/configuration/:activationStep(\\d+)?/:openMedicalSelectionOverlay?',
    name: 'configuration',
    component: Configuration,
    props(route) {
      const props = {
        ...route.params,
      };
      props.activationStep = parseInt(props.activationStep);
      let boolean = false;
      if (typeof props.openMedicalSelectionOverlay === 'string' && props.openMedicalSelectionOverlay === 'true') {
        boolean = true;
      }
      if (typeof props.openMedicalSelectionOverlay === 'string' && props.openMedicalSelectionOverlay === 'false') {
        boolean = false;
      }

      props.openMedicalSelectionOverlay = boolean;
      return props;
    },
    meta: {
      step: 2,
      title: 'tabbladTitles.configurationPage',
    },
  },
  {
    name: 'personal_data',
    path: '/:lang(nl|en)/:contractCode(\\d+)/personal_data',
    component: PersonalData,
    props: true,
    meta: {
      step: 3,
      stepProgress: 2,
      title: 'tabbladTitles.personalPage',
    },
  },
  {
    name: 'personal_extra_data',
    path: '/:lang(nl|en)/:contractCode(\\d+)/personal_extra_data',
    component: PersonalExtraData,
    props: true,
    meta: {
      step: 3,
      stepProgress: 3,
      title: 'tabbladTitles.personalPage',
    },
  },
  {
    name: 'overview',
    path: '/:lang(nl|en)/:contractCode(\\d+)/overview',
    component: Overview,
    props: true,
    meta: {
      step: 4,
      stepProgress: 1,
      title: 'tabbladTitles.overviewPage',
    },
  },
  {
    name: 'complete',
    path: '/:lang(nl|en)/:contractCode(\\d+)/complete',
    component: Complete,
    props: true,
    meta: {
      logo: true,
      title: 'tabbladTitles.confirmPage',
    },
    beforeEnter(to, from, next) {
      if (!from.name) {
        next({
          path: '/',
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/:lang(nl|en)?/revisit-loader/:id/:type(email|cookie)',
    name: 'RevisitLoader',
    component: RevisitLoader,
    props: true,
    meta: {
      logo: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/nl/notFound',
  },
  {
    name: 'not-found',
    path: '/:lang(nl|en)/:pathMatch(.*)',
    // alias: '/:lang(nl|en)/:pathMatch(.*)',
    component: PageNotFound,
    meta: {
      logo: true,
    },
  },
];

let router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (language && (!hasInitialized || i18n.global.locale != language)) {
    setI18nLanguage(i18n, language);
    next();
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const $tExistsOrDefault = (value, parameters) => app.config.globalProperties.$tExistsOrDefault(value, parameters);

  const title =
    to.meta.title !== undefined && to.meta.title !== null
      ? $tExistsOrDefault('tabbladTitles.default') + ' | ' + $tExistsOrDefault(to.meta.title)
      : $tExistsOrDefault('tabbladTitles.default');

  nextTick(() => {
    // If i18n is not yet loaded, wait for it to be loaded
    if (title | (title == '|')) document.title = title;
    const baseUrl = window.location.origin;

    //update siteImprove pageView
    if (window._sz) {
      window._sz.push([
        'trackdynamic',
        {
          url: baseUrl + to.fullPath,
          ref: baseUrl + from.fullPath,
          title: title,
        },
      ]);
    }
  });
});

export default router;
