<template>
  <div>
    <Container>
      <H1>{{ $tExistsOrDefault('personal.title') }}</H1>
      <PlainText>{{ $tExistsOrDefault('personal.intro') }}</PlainText>
    </Container>
    <Container>
      <div class="main-form">
        <Form class="main-form" ref="personalDataObserver" tag="form">
          <PersonalDataInsured
            v-for="insured in form.insureds"
            :key="'insured-personal-data-' + insured.personId"
            :id="insured.personId"
            :initial-form="insured"
            :all-bsns="allBsns"
            @form-change="insuredFormChange"
          />
        </Form>
        <div class="button-group-previous-next pb-5">
          <ButtonPrevious @click="back()">
            {{ $tExistsOrDefault('personal.prev') }}
          </ButtonPrevious>
          <ButtonNext @click="confirm()">
            {{ $tExistsOrDefault('personal.next') }}
          </ButtonNext>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import { some } from 'lodash-es';
import { mapGetters } from 'vuex';
import PersonalDataInsured from '@/components/viewelements/personalData/PersonalDataInsured.vue';
import BusinessRule from '@/constants/business-rule';
import Revisit from '@/constants/revisit';
import { showAllPackageTypesInOverview } from '@/mixins/showAllPackageTypesInOverview.js';
import { Form } from 'vee-validate';
import Validation from '@/constants/validation';
import CircleXMark from '@/components/shared/icons/CircleXMark.vue';
import 'vue-toastification/dist/index.css';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';
import H1 from '@/components/shared/typography/H1.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Container from '@/components/shared/Container.vue';

export default {
  name: 'PersonalData',
  mixins: [showAllPackageTypesInOverview],
  components: {
    PersonalDataInsured,
    Form,
    ButtonPrevious,
    ButtonNext,
    H1,
    PlainText,
    Container,
  },
  computed: {
    ...mapGetters(['agreement']),
    allBsns() {
      return this.form.insureds.map((p) => p.socialSecurityNumber);
    },
    valid() {
      return this.$refs.personalDataObserver.validate();
    },
  },
  data() {
    return {
      form: {
        insureds: [],
      },
    };
  },
  created() {
    // Set all initial form data.
    // This must happen in the 'created' lifecycle, because 'mounted' happens after the child component
    // creation, which causes that state data is not ready available in de child components.
    let insuredForms = [];
    this.agreement.insuredPersons.forEach((person) => {
      insuredForms.push({
        personId: person.id,
        firstName: person.firstName,
        initials: person.initials,
        lastNamePrefix: person.lastNamePrefix,
        lastName: person.lastName,
        gender: person.gender,
        email: person.email,
        socialSecurityNumber: person.socialSecurityNumber,
        nationality: person.nationality,
      });
    });
    this.form.insureds = insuredForms;
  },
  mounted() {
    let valid = true;

    if (some(this.agreement.insuredPersons, (person) => !person.dateOfBirth)) {
      valid = false;
    }

    if (!valid) {
      this.$router.push({
        name: 'home',
        params: {
          lang: this.$i18n.locale,
        },
      });
      return;
    } else {
      this.$store.dispatch(Revisit.Actions.SAVE, {
        agreementModel: this.agreement,
        step: Revisit.RevisitStepEnum.personalData,
      });
    }
  },
  methods: {
    insuredFormChange(event) {
      const insured = this.form.insureds.find((person) => person.personId === event.personId);
      insured.firstName = event.firstName;
      insured.initials = event.initials ? event.initials.toUpperCase() : event.initials;
      insured.lastNamePrefix = event.lastNamePrefix;
      insured.lastName = event.lastName;
      insured.gender = event.gender;
      insured.email = event.email;
      insured.socialSecurityNumber = event.socialSecurityNumber;
      insured.nationality = event.nationality;
    },
    async confirm() {
      const validObject = await this.valid;
      this.$store.dispatch(Validation.Actions.SET_PERSONAL_DATA, validObject.valid);

      if (validObject.valid) {
        this.$store.commit(BusinessRule.Mutations.SET_PERSONAL_DATA, {
          data: this.form,
        });

        this.$router.push({
          name: 'personal_extra_data',
          params: {
            lang: this.$i18n.locale,
            contractCode: this.$store.getters.selectedContractCode,
          },
        });
      } else {
        if (this.$screen.xs) {
          this.$toast(this.$tExistsOrDefault('personal.form.toasterInfo'), {
            position: 'top-center',
            draggable: false,
            icon: CircleXMark,
            toastClassName: 'body-toast-class mobile',
            closeButtonClassName: 'close-button-toast-class mobile',
          });
        } else {
          this.$toast(this.$tExistsOrDefault('personal.form.toasterInfo'), {
            position: 'top-right',
            draggable: false,
            icon: CircleXMark,
            toastClassName: 'body-toast-class',
            closeButtonClassName: 'close-button-toast-class',
          });
        }

        // dirty way of going to the first error.
        var el = this.$el.getElementsByClassName('is-invalid')[0].parentNode;
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    async back() {
      this.$store.commit(BusinessRule.Mutations.SET_PERSONAL_DATA, {
        data: this.form,
      });

      this.$store.dispatch(Validation.Actions.SET_PERSONAL_DATA, await this.valid);
      this.$router.push({
        name: 'configuration',
        params: {
          activationStep: 4,
          lang: this.$i18n.locale,
          contractCode: this.$store.getters.selectedContractCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
hr {
  display: block;
  height: 1px;
  background-color: solid 1px var(--greyscale-black-20);
}
.main-form {
  max-width: 620px;
}
</style>
