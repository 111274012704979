<template>
  <PaymentTermSlot class="row-style" :class="{ mobile: $screen.xs }">
    <div class="row row-style" :class="{ mobile: $screen.xs }">
      <div class="d-inline-flex custom-flex" :class="{ 'mobile-left': $screen.xs }">
        <div>
          <PlainText class="mr-2" :type="showWhite ? 'white' : null">
            {{ $tExistsOrDefault('calculatePremium.previewOverview.totalPremium') }}
          </PlainText>
        </div>
        <div class="dropdown mr-2">
          <Dropdown :options="allPremiums" v-model="selectedPremiumTerm" :color="showWhite ? 'white' : null" />
        </div>
        <div>
          <PlainText :type="showWhite ? 'white' : null" class="text">
            {{ formatCurrency(getTotalNettoPremium(selectedPremiumTerm)) }}
          </PlainText>
        </div>
      </div>
    </div>
  </PaymentTermSlot>
</template>

<script>
import { mapGetters } from 'vuex';

import formatCurrencyHelper from '@/helpers/formatCurrency';

import PaymentTermSlot from '@/components/viewelements/configuration/PaymentTermSlot.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';
import Dropdown from '@/components/shared/input/Dropdown.vue';

export default {
  name: 'PreviewOverviewPaymentTermSlot',
  components: {
    PaymentTermSlot,
    PlainText,
    Dropdown,
  },
  data() {
    return {
      selectedPremiumTerm: 1,
      allPremiums: [
        { value: 1, text: 'calculatePremium.previewOverview.perMonth' },
        { value: 12, text: 'calculatePremium.previewOverview.perYear' },
      ],
    };
  },
  props: {
    showWhite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['paymentTermNetDiscounts']),
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale ? this.$i18n.locale : 'NL');
    },
    getTotalNettoPremium(premiumTerm) {
      return this.paymentTermNetDiscounts.find((x) => x.paymentTerm === premiumTerm)
        ?.paymentTermNetPremiumWithOwnRiskDiscountTotal;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-style {
  margin: 0 0;
  &.mobile {
    width: 100%;
    padding: 0;
  }
}

.dropdown {
  width: 125px;
}

.custom-flex {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  &.mobile-left {
    justify-content: space-between;
  }
}

.text {
  font-size: 24px;
  font-family: var(--text-font-semibold);
}
</style>
