<template>
  <div>
    <div class="loading" v-if="isLoading">
      <font-awesome-icon icon="circle-notch" size="4x" spin />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isLoading: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
}
</style>
