<template>
  <img :src="logoPath" alt="logo" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WhiteLabelLogo',
  props: {
    onlyShowSmallLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['labelSettings']),
    logoPath() {
      let imageName = this.onlyShowSmallLogo || this.$screen.xs ? this.smallLogo() : this.bigLogo();

      if (!imageName) imageName = this.$screen.xs ? 'aev_mobile.png' : 'aev.png';
      return `/images/whiteLabeling/logo/${imageName}`;
    },
  },
  methods: {
    smallLogo() {
      return this.labelSettings.logoMobile ? this.labelSettings.logoMobile : this.labelSettings.logo;
    },
    bigLogo() {
      return this.labelSettings.logo;
    },
  },
};
</script>
