<template>
  <FormFieldValidation
    :rules="{ validCorrectLocationOrZipcode: cities }"
    v-slot="{ handleBlur, handleChange }"
    field-name="location"
  >
    <InputField
      :placeholder="$tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.zipCodeOrCity')"
      v-maska
      :data-maska="locationMask"
      :options="cities"
      :use-translation-keys="false"
      v-model="selectedValue"
      :minimum-length-show-dropdown="3"
      @blur="handleBlur"
      @input="handleChange"
    />
  </FormFieldValidation>
</template>

<script>
import { mapGetters } from 'vuex';

import InputField from '@/components/shared/input/InputField.vue';
import FormFieldValidation from '@/components/shared/input/validation/FormFieldValidation.vue';

export default {
  name: 'LocationInputField',
  emits: ['zipcode', 'city', 'update:modelValue'],
  components: {
    InputField,
    FormFieldValidation,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['cities']),
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
    isTypingZipCode() {
      return this.selectedValue && this.selectedValue.match(/^\d/);
    },
    locationMask() {
      if (this.isTypingZipCode) {
        return '#### @@';
      } else {
        return null;
      }
    },
    zipcode() {
      if (this.isTypingZipCode && this.selectedValue.length >= 4) {
        return this.selectedValue;
      } else {
        return null;
      }
    },
    city() {
      if (!this.isTypingZipCode) {
        return this.selectedValue;
      } else {
        return null;
      }
    },
  },
  methods: {
    locationChanged() {
      this.$emit('zipcode', this.zipcode);
      this.$emit('city', this.city);
    },
  },
  watch: {
    selectedValue() {
      this.locationChanged();
    },
  },
};
</script>

<style lang="scss" scoped></style>
