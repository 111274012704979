<template>
  <p>
    <slot />
  </p>
</template>
<script>
export default {
  name: 'CardAmount',
};
</script>
<style lang="scss" scoped>
p {
  font-size: 26px;
  font-family: var(--text-font-semibold);
  color: var(--color-primary);
  margin-bottom: 0;
}
</style>
