<template>
  <div :style="styleInsuranceCard">
    <GiftLid class="gift-lid" v-if="productGroupContainsFreeProduct" :style="styleVisibilityIfFree" />
    <Card :padded="false">
      <LoadingOverlay :show-overlay="isBusinessRulePostLoading" class="position-relative">
        <div
          class="insuranceCard ml-1"
          @mouseenter="() => displayTooltipCardDisabled(product)"
          @mouseleave="resetTooltipVisibility()"
          :class="[`step-${step}`, { 'is-free': isFree, 'card-disabled': isComponentCardDisabled }]"
        >
          <InsuranceCardHeader
            :title="product.title ?? $tExistsOrDefault(`packages.${product.packageTypeForWebApplications}.noInsurance`)"
            :level="level"
            :product="product"
          />
          <HorizontalRule />
          <CheckmarkList :items="productUsps" class="usp" />
          <section :class="{ invisible: isNoInsurance || !showCompareLink }">
            <Check class="check-icon" />
            <LinkText class="overlay-link" @click="showCompareOverlay = true">
              {{ $tExistsOrDefault('calculatePremium.general.moreReimbursements') }}
            </LinkText>
          </section>

          <HorizontalRule :class="{ invisible: isNoInsurance }" />
          <div>
            <DisabledPackagesInfoContainer
              :text="$tExistsOrDefault('calculatePremium.general.disabledProductTooltip.cardParagraph')"
              :product-component-code-card="product.productComponentCode"
              @show-overlay="showOverlay"
            />
            <PlainText class="select-package-for" :class="{ invisible: !product.hasPersons }">
              {{ selectPackageLabel }}
            </PlainText>
            <SelectProductForPersonCheckbox
              :class="{ 'pointer-events-none': isComponentCardDisabled }"
              v-for="person in product.persons"
              :key="`select-box-for-${product.productComponentCode}-${person.id}`"
              :product="product"
              :person="person"
              :product-group-contains-free-product="productGroupContainsFreeProduct"
              @mouseenter="() => displayTooltipPersonDisabled(person)"
              :show-tooltip="null"
            />
          </div>
          <HorizontalRule :class="{ invisible: isNoInsurance }" />
          <div class="premium-container">
            <DocumentDownloads size="2x" :product="product" class="document-download" />
          </div>
        </div>
        <CompareOverlay
          v-model="showCompareOverlay"
          :type-of-insurance="product.packageTypeForWebApplications"
          @close-overlay="closeCompareOverlay"
        />
      </LoadingOverlay>
    </Card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentDownloads from '@/components/viewelements/configuration/insuranceCard/documentDownloads/DocumentDownloads';
import InsuranceCardHeader from '@/components/viewelements/configuration/insuranceCard/InsuranceCardHeader.vue';
import SelectProductForPersonCheckbox from '@/components/viewelements/configuration/selectProductForPersonCheckbox/SelectProductForPersonCheckbox.vue';

import LoadingOverlay from '@/components/shared/overlay/LoadingOverlay.vue';
import GiftLid from '@/components/viewelements/configuration/insuranceCard/GiftLid.vue';
import productHelper from '@/helpers/product';
import CheckmarkList from '@/components/viewelements/configuration/insuranceCard/CheckmarkList.vue';
import DisabledPackagesInfoContainer from '@/components/viewelements/configuration/insuranceCard/DisabledPackagesInfoContainer.vue';
import TooltipDisabledPackages from '@/constants/tooltipDisabledPackages';
import ModalDataFactory from '@/components/shared/modals/ModalDataFactory.js';
import Modal from '@/constants/modal';
import CompareOverlay from '@/components/viewelements/configuration/insuranceCard/compareOverlay/CompareOverlay.vue';
import HorizontalRule from '@/components/shared/HorizontalRule.vue';
import LinkText from '@/components/shared/typography/LinkText.vue';
import Check from '@/components/shared/icons/Check.vue';
import Card from '@/components/shared/card/Card.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

const orderToCategoryId = [null, 5, 1, 3, null, null, 10, 9, 8];

export default {
  name: 'InsuranceCard',
  components: {
    SelectProductForPersonCheckbox,
    LoadingOverlay,
    GiftLid,
    InsuranceCardHeader,
    CheckmarkList,
    DocumentDownloads,
    DisabledPackagesInfoContainer,
    CompareOverlay,
    HorizontalRule,
    LinkText,
    Check,
    Card,
    PlainText,
  },
  data() {
    return {
      packageTypesCompareOverlay: ['AE', 'AP', 'XA', 'TA'],
      showCompareOverlay: false,
      productCodeCollective: '1200',
    };
  },
  props: {
    product: Object,
    productGroupContainsFreeProduct: Boolean,
    step: Number,
    level: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['isBusinessRulePostLoading', 'productList', 'reimbursements']),
    isNoInsurance() {
      return !this.product.productComponentCode;
    },
    isFree() {
      return productHelper.isFree(this.product);
    },
    styleVisibilityIfFree() {
      return {
        visibility: this.isFree ? 'visible' : 'hidden',
      };
    },
    styleInsuranceCard() {
      if (this.product.packageTypeForWebApplications === 'MB') {
        return {
          width: '310px',
        };
      } else {
        return {
          width: '250px',
        };
      }
    },
    selectPackageLabel() {
      if (this.product.productComponentCode === null)
        return this.$tExistsOrDefault('calculatePremium.general.selectNoPackageFor');
      else {
        return this.isFree
          ? this.$tExistsOrDefault('calculatePremium.general.selectThisPackageForFree')
          : this.$tExistsOrDefault('calculatePremium.general.selectThisPackageFor');
      }
    },
    showCompareLink() {
      return (
        this.packageTypesCompareOverlay.includes(this.product.packageTypeForWebApplications) &&
        !this.isCollectivePackage
      );
    },
    productUsps() {
      const usps = [];
      if (!this.isNoInsurance) {
        this.allOrderNumbersOfUsps.forEach((order) => {
          var productUsp = this.product.uniqueSellingPoints?.find((x) => x.order === order);

          const categoryName = this.getCategoryNameForOrderNumber(order);

          usps.push({
            categoryName: categoryName,
            html:
              productUsp?.htmlText ??
              (categoryName ? this.$tExistsOrDefault('calculatePremium.additionalInsurance.noCompensation') : null),
            type: productUsp ? 'Check' : 'Uncheck',
          });
        });
      }

      if (this.product.hasMedicalSelection) {
        usps.push({
          categoryName: null,
          html: this.$tExistsOrDefault('calculatePremium.additionalInsurance.medicalSelectionTooltipRequired'),
          type: 'Warning',
        });
      }

      //order everyone who has a category first
      usps.sort((a, b) => {
        if (a.categoryName && !b.categoryName) return -1;
        if (!a.categoryName && b.categoryName) return 1;
        return 0;
      });

      return usps;
    },
    productTitle() {
      return this.product.title || this.productTitleNoInsurance;
    },
    productTitleNoInsurance() {
      return this.$tExistsOrDefault(`packages.${this.product.packageTypeForWebApplications}.noInsurance`);
    },
    isComponentCardDisabled() {
      return this.step === 0 ? false : this.product.persons.every((person) => person.isDisabled);
    },
    isBasicInsurance() {
      return this.product.packageTypeForWebApplications === 'MB';
    },
    allOrderNumbersOfUsps() {
      return this.productList
        ?.filter((group) => group.packageType === this.product.packageTypeForWebApplications)
        ?.flatMap((group) => group.products)
        .flatMap((x) => x.uniqueSellingPoints?.map((x) => x.order))
        .filter((x, index, self) => x && self.indexOf(x) === index)
        .sort();
    },
    isCollectivePackage() {
      return this.product.productComponentCode === this.productCodeCollective;
    },
  },
  methods: {
    showOverlay() {
      this.$store.dispatch(Modal.Actions.OPEN, {
        modalData: ModalDataFactory.create(
          this.$tExistsOrDefault('calculatePremium.general.disabledProductOverlay.title'),
          this.$tExistsOrDefault('calculatePremium.general.disabledProductOverlay.paragraph'),
        ),
      });
    },
    displayTooltipCardDisabled() {
      if (this.product.isDisabled && !this.isBasicInsurance) {
        this.$store.dispatch(TooltipDisabledPackages.Actions.SET_DISABLED_PACKAGE, this.product);
      }
    },
    displayTooltipPersonDisabled(person) {
      if (person.isDisabled && !this.isBasicInsurance && !this.isCollectivePackage) {
        this.$store.dispatch(TooltipDisabledPackages.Actions.SET_DISABLED_PACKAGE, this.product);
      }
    },
    resetTooltipVisibility() {
      this.$store.dispatch(TooltipDisabledPackages.Actions.RESET_PACKAGE_DATA);
    },
    closeCompareOverlay(value) {
      this.showCompareOverlay = value;
    },
    getCategoryNameForOrderNumber(order) {
      if (this.isBasicInsurance) {
        return null;
      }

      return this.reimbursements.find(
        (x) => x.categoryId === orderToCategoryId[order] && x.language === this.$i18n.locale.toUpperCase(),
      )?.categoryName;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-download {
  z-index: 1;
  position: relative;
}
.pointer-events-none {
  pointer-events: none;
}
.insuranceCard {
  padding: 20px;
  user-select: none;
}

.gift-lid {
  z-index: 1;
  position: relative;
  top: 5px;
  left: -4px;
  width: calc(100% + 8px);
}

.select-package-for {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
  margin-left: 6px;
}

.usp {
  padding-bottom: 10px;
}

.check-icon {
  height: 22px;
}

.position-relative {
  position: relative;
}

.card-disabled {
  // pointer-events: none;
  &::after {
    pointer-events: none;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.overlay-link {
  padding-left: 6px;
}
</style>
@/components/shared/modals/ModalDataFactory
