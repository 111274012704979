import Dates from '@/helpers/dates';

export default {
  /**
   * Check if the premiums for all persons is equal.
   * @param {Object} product
   * @returns {Boolean}
   */
  premiumIsEqualForAllPersons(product) {
    if (!product.persons) return false;
    return product.persons.every((p) => p.netPremiumWithDiscount === product.persons[0].netPremiumWithDiscount);
  },

  /**
   * Get the lowest premium for all persons.
   * @param {Object} product
   * @returns {Number}
   */
  minPremium(product) {
    if (!product.persons) return null;
    return Math.min(...product.persons.map((p) => p.netPremiumWithDiscount));
  },

  /**
   * Checks if the premium for every person is 0.
   * @param {Object} product
   * @returns {Boolean}
   **/
  isFree(product) {
    return (
      product.persons?.every((p) => p.netPremiumWithDiscount === 0 || p.netPremiumWithDiscount === null) &&
      // leave out 'geen' products
      product.productComponentCode &&
      // leave out products with no persons
      product.persons.length > 0
    );
  },

  /**
   * Checks if every person is disabled.
   * @param {Object} product
   * @returns {Boolean}
   **/
  isDisabled(product) {
    return product.persons.every((p) => p.isDisabled);
  },

  /**
   * Checks if product has persons.
   * @param {Object} product
   * @returns {Boolean}
   **/
  hasPersons(product) {
    return product.persons.length > 0;
  },

  /**
   * Returns an adapted product object where all persons that shouldn't be displayed in the insurance card, nor should be taken into account for the ProductPremium
   * @param {Object} product
   * @param {Date} startDate
   * @returns {Object}
   */
  filteredPersonsForProduct(product, startDate) {
    return product.persons?.filter((person) =>
      Dates.isOldEnough(Dates.dateStringToDate(person.dateOfBirth), startDate, 18),
    );
    // In the future, partners under 18 should be displayed. Currently out of scope
    // person => !(person.typeOfInsured === 'M' && !Dates.isOldEnough(parseISO(person.dateOfBirth), this.startDate, 18))
  },
};
