<template>
  <PartialOverlayWithCloseButton ref="overlay" :model-value="modelValue !== null" @update:model-value="close">
    <KeepAlive>
      <HealthcareProviderSearch
        v-if="!healthcareProviderSelected"
        @click-health-care-provider="(x) => setHealthCareProvider(x)"
        :model-value="modelValue"
        @update:model-value="updateModelValue"
      />
      <HealthcareProviderDetails
        v-else
        :selected-year="selectedYear"
        :health-care-provider="healthcareProviderSelected"
        @close-healthcare-provider="() => resetHealthCareProvider()"
      />
    </KeepAlive>
  </PartialOverlayWithCloseButton>
</template>

<script>
import HealthcareProviderSearch from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderSearch.vue';
import HealthcareProviderDetails from '@/components/viewelements/configuration/basicInsurance/overlays/HealthcareProviderOverlay/HealthcareProviderDetails.vue';
import PartialOverlayWithCloseButton from '@/components/shared/overlay/PartialOverlayWithCloseButton.vue';

export default {
  name: 'HealthcareProvidersOverlay',
  emits: ['update:modelValue'],
  components: {
    HealthcareProviderSearch,
    HealthcareProviderDetails,
    PartialOverlayWithCloseButton,
  },
  props: {
    modelValue: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      healthcareProviderSelected: null,
      selectedYear: null,
    };
  },
  methods: {
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
    setHealthCareProvider({ healthcareProvider, selectedYear }) {
      this.healthcareProviderSelected = healthcareProvider;
      this.selectedYear = selectedYear;
    },
    resetHealthCareProvider() {
      this.healthcareProviderSelected = null;
      this.selectedYear = null;
    },
    close() {
      this.$emit('update:modelValue', null);
    },
  },
  watch: {
    value() {
      this.resetHealthCareProvider();
    },
  },
};
</script>

<style lang="scss" scoped></style>
