import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import { api } from '@/utils/api';
import Labels from '@/constants/labels';

export let hasInitialized = false;

export const SUPPORT_LOCALES = ['nl', 'en'];

/**
 * @description Create i18n instance and load the locale messages
 *
 * @returns {I18n}
 */
//Local will be set in the router startup when setI18nLanguage is called.
export const i18n = createI18n();

/**
 * @description Set i18n instance locale and load the locale messages if necessary
 *
 * @param {I18n} i18n
 * @param {string} locale
 */
export async function setI18nLanguage(i18n, locale) {
  // if the locale is unchanged, do nothing
  if (i18n.global.locale === locale && hasInitialized) return;

  //When not yet initialized. Start by setting the local for fixing redirect after navigating directly to configuration.
  if (!hasInitialized) {
    i18n.global.locale = locale;
  }

  // check if the locale is supported, if not it will be loaded from the server
  if (Object.keys(i18n.global.getLocaleMessage(locale)).length === 0) {
    await loadLocaleMessages(i18n, locale);
    hasInitialized = true;
  }

  //set locale again for everything after the initialize and when data has been fetched.
  i18n.global.locale = locale;

  document.querySelector('html').setAttribute('lang', locale);
}

async function loadLocaleMessages(i18n, locale) {
  const endpoint = Labels.Endpoints.LoadByLanguage();
  const params = { language: locale };

  const response = await api.get(endpoint, { params });
  const messages = response.data;

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}
