<template>
  <div>
    <LinkText @click="changeLanguage(targetLanguage)">
      {{ $screen.gt.xs ? targetLanguage.webTitle : targetLanguage.mobileTitle }}
    </LinkText>
  </div>
</template>

<script>
import BusinessRule from '@/constants/business-rule';
import LinkText from '@/components/shared/typography/LinkText.vue';
export default {
  name: 'LanguagePicker',
  components: {
    LinkText,
  },
  data() {
    return {
      languages: [
        {
          webTitle: 'In het Nederlands',
          mobileTitle: 'NL',
          lang: 'nl',
        },
        {
          webTitle: 'In English',
          mobileTitle: 'EN',
          lang: 'en',
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    targetLanguage() {
      return this.languages.find((l) => l.lang != this.language);
    },
  },
  methods: {
    changeLanguage(value) {
      this.$store.commit(BusinessRule.Mutations.SET_LANGUAGE, { language: value.lang.toUpperCase() });
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          lang: value.lang,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
</style>
