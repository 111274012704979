<template>
  <VueHorizontal
    ref="horizontal"
    :button-between="false"
    :snap="$screen.lt.lg ? 'center' : 'none'"
    :class="{
      gap: useGap,
      mobile: $screen.lt.md,
      'pad-right': addPaddingRight,
    }"
  >
    <slot />
  </VueHorizontal>
</template>
<script>
import VueHorizontal from '@/components/shared/horizontalScroll/new/VueHorizontal.vue';
/**
 * @deprecated This component is replaced by HorizontalScroll from '@/components/shared/2023/HorizontalScroll.vue';
 */
export default {
  name: 'HorizontalScrollOld',
  components: {
    VueHorizontal,
  },
  props: {
    useGap: { type: Boolean, default: true },
    addPaddingRight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() .v-hl-btn {
  svg {
    background: var(--color-primary-900);
    color: white;
    width: 39px;
    height: 39px;
    border-radius: 50%;
  }
}

.vue-horizontal {
  overflow: hidden !important;

  &.mobile {
    margin-left: calc(var(--page-margin-x-mobile) * -1);
    margin-right: calc(var(--page-margin-x-mobile) * -1);

    :deep().v-hl-container {
      padding-left: var(--page-margin-x-mobile);
      padding-right: var(--page-margin-x-mobile);
    }
  }

  &.gap {
    :deep() .v-hl-container > * {
      margin-right: 20px;
      padding-left: 0;
      margin-bottom: 4px;
      margin-top: 4px;

      &:last-child {
        margin-right: 0.2rem;
      }
    }
  }
}
</style>
