<template>
  <div class="checkbox-container" :class="{ 'disabled-checkbox': disabledOrLocked }">
    <input :id="id" type="checkbox" v-model="value" :disabled="disabledOrLocked || undefined" />
    <div
      class="check-container"
      :style="{
        width: checkBoxSize + 'px !important',
        height: checkBoxSize + 'px !important',
      }"
      @click="clickHandler"
    >
      <Check id="check-icon" :color="checkIconColor" :class="{ hidden: !value }" />
    </div>
    <label :for="id">
      <slot />
    </label>
  </div>
</template>
<script>
import Check from '@/components/shared/icons/Check.vue';

export default {
  name: 'Checkbox',
  emits: ['update:modelValue', 'blur'],
  components: { Check },
  props: {
    modelValue: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    checkBoxSize: {
      type: Number,
      default: 16,
    },
    id: {
      type: String,
      default: 'checkbox',
    },
  },
  computed: {
    checkIconColor() {
      return this.disabled ? 'var(--color-grey-400)' : 'var(--color-primary-900)';
    },
    disabledOrLocked() {
      return this.disabled || this.locked;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
        this.$emit('blur');
      },
    },
  },
  methods: {
    clickHandler() {
      if (!this.disabled && !this.locked) {
        this.$emit('update:modelValue', !this.modelValue);
        this.$emit('blur');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-container {
  border: 1px solid var(--color-primary-900);
  aspect-ratio: 1;
}

.checkbox-container {
  display: flex;
  padding-right: 8px;
  user-select: none;

  &:hover:not(.disabled-checkbox) {
    cursor: pointer;
    .check-container {
      box-shadow: 0 0 0 3px var(--color-primary-200);
    }

    label {
      cursor: pointer;
    }
  }

  &:hover.disabled-checkbox {
    cursor: not-allowed;
  }

  &.disabled-checkbox .check-container {
    border: 1px solid var(--color-grey-400);
  }

  input:focus-visible + .check-container {
    box-shadow: 0 0 0 3px var(--color-primary-200);
  }

  > input:disabled ~ label {
    cursor: not-allowed;
  }
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.check-container {
  border-radius: 3px;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;

  #check-icon {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.hidden {
      opacity: 0;
    }
  }
}

.is-invalid * .check-container {
  border: solid 1px var(--color-error);
  box-shadow: 0 0 5px 1px rgba(191, 43, 45, 0.5);
}
</style>
