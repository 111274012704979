import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      googleTagManagerInjected: false,
    };
  },
  mounted() {
    if (!this.googleTagManagerInjected) {
      if (this.labelSettings.googleTagManagerIds) {
        this.labelSettings.googleTagManagerIds.forEach((googleTagManagerId) => {
          const script = document.createElement('script');
          script.src = '/js/googleTagManagerScript.js?googleTagManagerId=' + googleTagManagerId;
          script.type = 'text/javascript';

          document.head.appendChild(script);

          this.addNoScript(this.labelSettings.googleTagManagerIds);
        });
      }

      this.googleTagManagerInjected = true;
    }
  },
  methods: {
    addNoScript(googleTagManagerId) {
      const noscriptTag = document.createElement('noscript');
      const iframeTag = document.createElement('iframe');

      iframeTag.src = 'https://www.googletagmanager.com/ns.html?id=' + googleTagManagerId;
      iframeTag.height = '0';
      iframeTag.width = '0';
      iframeTag.style.display = 'none';
      iframeTag.style.visibility = 'hidden';

      noscriptTag.appendChild(iframeTag);
      document.body.appendChild(noscriptTag);
    },
  },
  computed: {
    ...mapGetters(['labelSettings']),
  },
};
