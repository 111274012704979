<template>
  <svg
    :class="{ 'xMark-icon': iconInText }"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <circle fill="#BF2B2D" cx="8" cy="8" r="8" />
      <path stroke="#FFF" stroke-width="2" stroke-linecap="round" d="m5 11 5.939-6.053M10.863 11 4.924 4.947" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CircleXMark',
  props: {
    iconInText: String,
  },
};
</script>

<style lang="scss" scoped>
.xMark-icon {
  margin-bottom: 2px;
  margin-right: 3px;
}
</style>
