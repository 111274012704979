<template>
  <div>
    <div
      class="usp-item"
      v-for="(item, i) in items"
      :key="i"
      :class="{
        [`checkmark-item-${i}`]: true,
        invisible: !item.categoryName && !item.html,
      }"
    >
      <ExclamationMark class="warning" v-if="item.type === 'Warning'" />
      <Unchecked v-else-if="item.type === 'Uncheck'" />
      <Check v-else />
      <div class="usp-text">
        <PlainText type="secondary" v-if="item.categoryName"> {{ item.categoryName }}: </PlainText>
        <PlainText v-html="item.html" />
      </div>
    </div>
  </div>
</template>
<script>
import Check from '@/components/shared/icons/Check.vue';
import Unchecked from '@/components/shared/icons/Unchecked.vue';
import ExclamationMark from '@/components/shared/icons/ExclamationMark.vue';
import PlainText from '@/components/shared/typography/PlainText.vue';

export default {
  name: 'CheckmarkList',
  components: {
    Check,
    Unchecked,
    ExclamationMark,
    PlainText,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.usp-item {
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  gap: 6px;
  margin-bottom: 10px;
  > :first-child {
    flex-shrink: 0;
    height: 22px;
  }
}

.usp-text {
  display: flex;
  flex-direction: column;
}

.warning {
  width: 20px;
  height: 20px;
  color: var(--color-warning);
}
</style>
