<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path stroke="currentColor" stroke-linejoin="round" d="M1 3v14h18V3z" />
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m1 3 9 10 9-10" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'Envelope',
};
</script>
