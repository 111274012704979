<template>
  <Teleport to="#app-base" v-show="value">
    <FullscreenOverlay v-model="value" class="mw-100vw mh-100" style="min-height: 100vh">
      <CompareDesktop v-if="$screen.gt.xs" :type-of-insurance="typeOfInsurance" @close-overlay="closeCompareOverlay" />
      <CompareMobile v-else :type-of-insurance="typeOfInsurance" @close-overlay="closeCompareOverlay" />
    </FullscreenOverlay>
  </Teleport>
</template>

<script>
import FullscreenOverlay from '@/components/shared/overlay/FullscreenOverlay.vue';
import CompareMobile from '@/components/viewelements/configuration/insuranceCard/compareOverlay/compareMobile/CompareMobile.vue';
import CompareDesktop from '@/components/viewelements/configuration/insuranceCard/compareOverlay/compareDesktop/CompareDesktop.vue';

export default {
  name: 'CompareOverlay',
  components: {
    FullscreenOverlay,
    CompareMobile,
    CompareDesktop,
  },
  emits: ['closeOverlay', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    typeOfInsurance: {
      type: String,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      },
    },
  },
  methods: {
    closeCompareOverlay(value) {
      this.$emit('closeOverlay', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.mw-100vw {
  max-width: 100vw;
}
</style>
