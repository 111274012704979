<template>
  <div>
    <div class="mb-4">
      <H1>{{ $tExistsOrDefault('calculatePremium.previewOverview.title') }}</H1>
    </div>
    <div class="d-flex flex-gap">
      <div class="flex-first-child">
        <Card>
          <div v-for="(insuredPerson, i) in insured" :key="i">
            <PersonPremiumCardHeader :insured-person="insuredPerson" />
            <PersonPremiumCard :insured-person="insuredPerson" />
          </div>
        </Card>

        <!-- Payment by employer OR total premium and dropdown -->
        <PreviewOverviewPaymentTermSlot v-if="$screen.gt.xs" class="mb-3" />
      </div>

      <div v-if="$screen.gt.xs">
        <CollectivityInfo
          class="collectivity-info"
          @complete-application="$emit('complete-application')"
          :contract="selectedContract"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import H1 from '@/components/shared/typography/H1.vue';
import Card from '@/components/shared/card/Card.vue';

import PersonPremiumCard from '@/components/viewelements/configuration/previewOverview/PersonPremiumCard.vue';
import PersonPremiumCardHeader from '@/components/viewelements/configuration/previewOverview/PersonPremiumCardHeader.vue';
import CollectivityInfo from '@/components/viewelements/configuration/previewOverview/CollectivityInfo.vue';
import PreviewOverviewPaymentTermSlot from '@/components/viewelements/configuration/previewOverview/PreviewOverviewPaymentTermSlot.vue';

export default {
  name: 'PreviewOverview',
  components: {
    H1,
    Card,
    PersonPremiumCard,
    PersonPremiumCardHeader,
    CollectivityInfo,
    PreviewOverviewPaymentTermSlot,
  },
  emits: ['complete-application'],
  computed: {
    ...mapGetters(['insured', 'selectedContract']),
  },
};
</script>

<style lang="scss" scoped>
.flex-first-child {
  flex: 1;
}

.flex-gap {
  gap: 30px;
}

.collectivity-info {
  width: 280px;
}
</style>
