<template>
  <p :class="type">
    <slot />
  </p>
</template>
<script>
export default {
  name: 'PlainText',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary', 'positive', 'warning', 'white'].includes(value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  font-size: 16px;
  font-family: var(--text-font);
  margin-bottom: 0;
  line-height: 22px;
  max-width: 660px;
}
.primary {
  color: var(--color-grey-900);
}
.secondary {
  color: var(--color-grey-500);
}
.positive {
  color: var(--color-positive);
  font-family: var(--text-font-semibold);
}
.warning {
  color: var(--color-warning);
  font-family: var(--text-font-semibold);
}
.primary-bold {
  color: var(--color-grey-900);
}
.white {
  color: var(--color-white);
}
</style>
